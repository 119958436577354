import React from 'react';
import { Client, HostedFieldsTokenizePayload, PayPalCheckout } from 'braintree-web';
import cx from 'classnames';

import styles from './CardInfoBlock.module.scss';
import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';
import Button, { ButtonProps } from '../../atoms/Button';
import BraintreeElements from '../../../modules/braintree/BraintreeElements';
import { CHECKOUT_BRAINTREE_FORM } from '../../../lib/constants';
import Spinner from '../../atoms/Spinner';
import HighlightMessage, { HighlightMessageProps } from '../../molecules/HighlightMessage';
import { DividerProps } from '../../atoms/Divider';
import { BraintreePayPalButtons, PayPalButtons } from '@paypal/react-paypal-js';
import braintreeClientFactory from 'braintree-web/client';
import braintreePayPalCheckoutFactory from 'braintree-web/paypal-checkout';

export type CardInfoState = 'CardInfo' | 'Paypal';

export const defaultProps = {
  state: 'CardInfo' as CardInfoState,
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  errorHighlighMessage: {
    type: 'Error',
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'CP1Red',
    } as DividerProps,
    icon: {
      asset: 'CloseCircleFill',
      style: 'DigitalGrey100',
    } as IconProps,
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    } as TextProps,
  } as HighlightMessageProps,
  cardNumber: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  expiration: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  securityCode: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  cardNumberErrorMessage: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'Negative',
    align: 'Left',
  } as TextProps,
  mastercard: {
    asset: 'MasterCard',
    style: 'DigitalGrey80',
  } as IconProps,
  paypalIcon: {
    asset: 'Paypal',
    style: 'DigitalGrey80',
  } as IconProps,
  visa: {
    asset: 'Visa',
    style: 'DigitalGrey80',
  } as IconProps,
  americanExpress: {
    asset: 'AmericanExpress',
    style: 'DigitalGrey80',
  } as IconProps,
  discover: {
    asset: 'Discover',
    style: 'DigitalGrey80',
  } as IconProps,
  button: {
    type: 'Text',
    style: 'Contained',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
};

export type CardInfoBlockProps = {
  blockTitle?: TextProps;
  errorHighlighMessage?: HighlightMessageProps;
  cardNumber?: TextProps;
  cardNumberErrorMessage?: TextProps;
  expiration?: TextProps;
  expirationErrorMessage?: TextProps;
  securityCode?: TextProps;
  securityCodeErrorMessage?: TextProps;
  onCardsIconClicked?: () => void;
  onPaypalIconClicked?: () => void;
  onPaypalSuccess?: (data) => void;
  paypalMessage?: TextProps;
  mastercard?: IconProps;
  visa?: IconProps;
  paypalIcon?: IconProps;
  americanExpress?: IconProps;
  discover?: IconProps;
  state?: CardInfoState;
  className?: string;
  button?: ButtonProps;
  braintreeStyles?: any;
  handleSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void;
  onCardTokenized?: (payload: HostedFieldsTokenizePayload | null, hasValidationError: boolean) => void;
  hasValidationError?: boolean;
  isCardDisabled?: boolean;
  orderTotal?: number;
  authToken?: string;
  isLoading?: boolean;
  client?: Client;
};

const CardInfoBlock: React.FC<CardInfoBlockProps> = ({
  blockTitle,
  errorHighlighMessage,
  cardNumber,
  cardNumberErrorMessage,
  expiration,
  expirationErrorMessage,
  securityCode,
  securityCodeErrorMessage,
  onCardsIconClicked,
  onPaypalIconClicked,
  onPaypalSuccess,
  paypalMessage,
  mastercard,
  visa,
  americanExpress,
  discover,
  paypalIcon,
  state,
  className,
  button,
  braintreeStyles,
  handleSubmit,
  hasValidationError,
  isCardDisabled,
  orderTotal,
  authToken,
  client,
  isLoading,
}) => {
  if (isLoading) {
    return <div className={cx(styles.cardInfoBlock, className)}>
      <Spinner></Spinner>
    </div>;
  }
  let cardFields;
  let braintreeCheckout;

  switch (state) {
    case ('Paypal'):
      cardFields = (
        <React.Fragment>
          <Text className={styles.paypalMessage} {...paypalMessage} />
          <BraintreePayPalButtons style={{ layout: 'horizontal', height: 45 }} 
            createOrder={(data, actions) => {
              /* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
              return braintreePayPalCheckoutFactory.create({
                client: client,
              }).then((braintreePayPalCheckout) => {
                braintreeCheckout = braintreePayPalCheckout;
                return braintreePayPalCheckout.createPayment({
                  flow: 'checkout',
                  amount: orderTotal,
                  currency: 'USD',
                  intent: 'authorize',
                });
              });
            }}
            onApprove={(data, actions) => {
              return braintreeCheckout.tokenizePayment(data).then((payload) => {
                if (onPaypalSuccess) {
                  onPaypalSuccess(payload);
                }
              });

            /* eslint-enable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
            }}
            />
        </React.Fragment>
        
      );
      break;
    case ('CardInfo'):
    default:
      cardFields = (
        <React.Fragment>
          <BraintreeElements isDisabled={isCardDisabled} styles={braintreeStyles}>
            {(cardNumberRef, expiryRef, cvvRef) => {
              return (
                <>
                  <Text className={styles.label} {...cardNumber} />
                  <div className={cx({ [styles.disable]: isCardDisabled }, { [styles.errorBoundary]: !!cardNumberErrorMessage?.value && !isCardDisabled }, styles.cardNumber)} ref={cardNumberRef} />
                  <Text className={styles.errorMessage} {...cardNumberErrorMessage} />
                  <div className={styles.fieldGroup1}>
                    <div>
                      <Text className={styles.label} {...expiration} />
                      <div className={cx({ [styles.disable]: isCardDisabled }, { [styles.errorBoundary]: !!expirationErrorMessage && !isCardDisabled }, styles.expiration)} ref={expiryRef} />
                      <Text className={styles.errorMessage} {...expirationErrorMessage} />
                    </div>
                    <div>
                      <Text className={styles.label} {...securityCode} />
                      <div className={cx({ [styles.disable]: isCardDisabled }, { [styles.errorBoundary]: !!securityCodeErrorMessage && !isCardDisabled }, styles.securityCode)} ref={cvvRef} />
                      <Text className={styles.errorMessage} {...securityCodeErrorMessage} />
                    </div>
                  </div>
                </>
              );
            }}
          </BraintreeElements>
          <Button form={CHECKOUT_BRAINTREE_FORM} className={styles.button} {...button} />
        </React.Fragment>
        
      );
      break;

  }

  
  let errorHighlightSection;
  if (hasValidationError) {
    errorHighlightSection = (
      <HighlightMessage
        {...errorHighlighMessage}
      />
    );
  }

  return (
    <form id={CHECKOUT_BRAINTREE_FORM}
      className={cx(styles.cardInfoBlock, className)}
      onSubmit={handleSubmit}
    >
      <div className={styles.topContent}>
        <Text className={styles.blockTitle} {...blockTitle} />
        <div className={styles.methodSelection}>
          <div className={state === 'CardInfo' ? styles.iconsSelected : styles.icons} onClick={onCardsIconClicked}> 
            <Icon className={styles.visa} {...visa} />
            <Icon className={styles.mastercard} {...mastercard} />
            <Icon className={styles.visa} {...americanExpress} />
            <Icon className={styles.visa} {...discover} />
          </div>
          <div className={state === 'Paypal' ? styles.iconsSelected : styles.icons} onClick={onPaypalIconClicked}> 
            <Icon className={styles.paypal} {...paypalIcon} />
          </div>
        </div>
      </div>
      {errorHighlightSection}
      {cardFields}
    </form>
  );
};

CardInfoBlock.defaultProps = defaultProps;

export default CardInfoBlock;
