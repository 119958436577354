import React from 'react';
import cx from 'classnames';

import styles from './PriceItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';

export type PriceItemStyleType = 'Dark' | 'Light' | 'Link';

export const defaultProps = {
  style: 'Light' as PriceItemStyleType,
  item: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  price: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  closeButton: {
    type: 'Icon',
    style: 'Text',
    size: 'Small',
    icon: {
      asset: 'Close',
      style: 'DigitalGrey80',
    },
  } as ButtonProps,
};

export type PriceItemProps = {
  style?: PriceItemStyleType;
  item?: TextProps;
  price?: TextProps;
  className?: string;
  closeButton?: ButtonProps;
};

const PriceItem: React.FC<PriceItemProps> = ({
  style,
  item,
  price,
  className,
  closeButton,
}) => {

  const currentStyle = styles[`priceItem${style}`];
  let closeButtonElement;
  if (style === 'Link') {
    closeButtonElement = <Button className={styles.closeButton} {...closeButton} />;
  }

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={styles.item}
        {...item} />
      <Text
        className={styles.price}
        {...price} />
      {closeButtonElement}
    </div>
  );
};

PriceItem.defaultProps = defaultProps;

export default PriceItem;
