import React from 'react';
import cx from 'classnames';

import styles from './Dropdown.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import DropdownSelect, { DropdownSelectProps } from '../../atoms/DropdownSelect';
import { AriaLiveRegions } from '../../../lib/types';

export type DropDownStateType = 'Default' | 'Error';

export const defaultProps = {
  state: 'Default' as DropDownStateType,
  label: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  dropdownSelect: {
    state: 'Empty',
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'DigitalGrey80',
    },
  } as DropdownSelectProps,
  error: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'Negative',
    align: 'Left',
  } as TextProps,
};

export type DropdownProps = {
  label?: TextProps;
  dropdownSelect?: DropdownSelectProps;
  className?: string;
  state?: DropDownStateType;
  error?: TextProps;
  ariaLive?: AriaLiveRegions;
};

const Dropdown: React.FC<DropdownProps> = ({
  label,
  dropdownSelect,
  className,
  state,
  error,
  ariaLive,
}) => {
  const currentStyle = styles[`dropdown${state}`];
  let errorView;
  if (state === 'Error') {
    errorView = (
      <Text
      className={styles.error}
      {...error} />
    );
  }
  return (
    <div className={cx(currentStyle, className)} aria-live={ariaLive}>
      <Text
        className={styles.label}
        {...label} />
      <DropdownSelect
        className={styles.dropdownSelect}
        {...dropdownSelect} />
      {errorView}
    </div>
  );
};

Dropdown.defaultProps = defaultProps;

export default Dropdown;
