import React from 'react';
import cx from 'classnames';

import styles from './CustomerInfoReadOnlyBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextItem, { TextItemProps } from '../../molecules/TextItem';

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  edit: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'ActionBase',
    align: 'Right',
  } as TextProps,
  textItem: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
};

export type CustomerInfoReadOnlyBlockProps = {
  blockTitle?: TextProps;
  edit?: TextProps;
  textItem?: TextItemProps;
  className?: string;
};

const CustomerInfoReadOnlyBlock: React.FC<CustomerInfoReadOnlyBlockProps> = ({
  blockTitle,
  edit,
  textItem,
  className,
}) => {
  return (
    <div className={cx(styles.customerInfoReadOnlyBlock, className)}>
      <div className={styles.topContent}>
        <Text
          className={styles.blockTitle}
          {...blockTitle} />
        <Text
          className={styles.edit}
          {...edit} />
      </div>
      <TextItem
        className={styles.textItem}
        {...textItem} />
    </div>
  );
};

CustomerInfoReadOnlyBlock.defaultProps = defaultProps;

export default CustomerInfoReadOnlyBlock;
