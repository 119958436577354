import React from 'react';
import cx from 'classnames';

import styles from './Divider.module.scss';

export type DividerTypeType = 'Horizontal' | 'Vertical';
export type DividerStyleType = 'Thick' | 'Thin';
export type DividerColourType = 'White' | 'DigitalGrey5' | 'DigitalGrey10' | 'DigitalGrey15' | 'CP1Red' | 'InteractionBlue50' | 'Clear';

export const defaultProps = {
  type: 'Vertical' as DividerTypeType,
  style: 'Thick' as DividerStyleType,
  colour: 'Clear' as DividerColourType,
};

export type DividerProps = {
  type?: DividerTypeType;
  style?: DividerStyleType;
  colour?: DividerColourType;
  className?: string;
};

const Divider: React.FC<DividerProps> = ({
  type,
  style,
  colour,
  className,
}) => {

  const currentStyle = styles[`divider${type}${style}${colour}`];
  return (
    <div className={cx(currentStyle, className)}/>
  );
};

Divider.defaultProps = defaultProps;

export default Divider;
