import React from 'react';
import cx from 'classnames';

import styles from './AdditionalTicketInformationList.module.scss';

import IconTextDescription, { IconTextDescriptionProps } from '../IconTextDescription';

export const defaultProps = {
  iconTextDescriptions: [
  ] as IconTextDescriptionProps[],
};

export type AdditionalTicketInformationListProps = {
  iconTextDescriptions?: IconTextDescriptionProps[];
  className?: string;
};

const AdditionalTicketInformationList: React.FC<AdditionalTicketInformationListProps> = ({
  iconTextDescriptions,
  className,
}) => {
  const iconTextDescriptionArray = iconTextDescriptions?.map((iconTextDescription, index) => (
    <IconTextDescription
      className={styles.iconTextDescription}
      key={index}
      {...iconTextDescription} />
  ));
  
  return (
    <div className={cx(styles.additionalTicketInformationList, className)}>
      {iconTextDescriptionArray}
    </div>
  );
};

AdditionalTicketInformationList.defaultProps = defaultProps;

export default AdditionalTicketInformationList;
