import React from 'react';
import cx from 'classnames';
import styles from './TopNav.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import Logo, { LogoProps } from '../../atoms/Logo';
import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import NavMenuList, { NavMenuListProps } from '../NavMenuList';
import { TopNavTab } from '../../../modules/navigation/types';
import EventDetailsModal, { EventDetailsModalProps } from '../EventDetailsModal';
import { ListingDetails } from '../../../modules/partnership';
import { getClickAccessibilityProps, SEARCH_ICON_ON_TAB_BAR } from './util';
import Image, { ImageProps } from '../../atoms/Image';
import Icon, { IconProps } from '../../atoms/Icon';

export type TopNavTypeType =
  | 'WithoutSearchInNav'
  | 'SearchInNav'
  | 'Checkout'
  | 'SignedOut'
  | 'TicketSeatSelection'
  | 'PrecheckoutMobile';

export const defaultProps = {
  type: 'TicketSeatSelection' as TopNavTypeType,
  logo: {
    asset: 'LogoTicketsOnSale',
  } as LogoProps,
  label: {
    type: 'Body',
    colour: 'BaseLight',
    align: 'Left',
    style: 'Regular',
    size: 'Medium',
  } as TextProps,
  message: {
    type: 'Body',
    colour: 'SubduedLight',
    align: 'Left',
    style: 'Regular',
    size: 'Medium',
  } as TextProps,
  backButton: {
    type: 'Icon',
    style: 'Text',
    size: 'Small',
    icon: {
      asset: 'ChevronLeft',
      style: 'White',
    },
  } as ButtonProps,
  title: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseLight',
    align: 'Left',
  } as TextProps,
  button: {
    type: 'Icon',
    style: 'Text',
    size: 'Small',
    icon: {
      asset: 'Info',
      style: 'White',
    },
  } as ButtonProps,
  description: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'BaseLight',
    align: 'Left',
  } as TextProps,
  navMenuList: {
    tabs: [],
    type: 'Tabs',
  } as NavMenuListProps,
};

export type TopNavProps = {
  type?: TopNavTypeType;
  message?: TextProps;
  mobileMessage?: TextProps;
  phoneNumber?: TextProps;
  phoneIcon?: IconProps;
  label?: TextProps;
  logo?: LogoProps;
  widget?: LogoProps;
  className?: string;
};

const TopNav: React.FC<TopNavProps> = ({
  message,
  label,
  mobileMessage,
  phoneNumber,
  phoneIcon,
  logo,
  widget,
  className,
}) => {
  const currentStyle = styles.topNav;

  const logoContainerView = (
    <div className={styles.logoContainer}>
      <Logo className={styles.logo} {...logo} />
    </div>
  );
  const shopperWidgetView = (
    <div className={styles.widgetContainer}>
       <Logo className={styles.widget} {...widget} />
    </div>
  );

  return (
    <div className={cx(currentStyle, className)}>
      <div className={styles.topContent}>
        <Text 
          className={styles.message}
          {...message}/>
        <Text 
          className={styles.mobileMessage}
          {...mobileMessage}/>
      </div>
      <div className={styles.middleContent}>
        {logoContainerView}
        {shopperWidgetView}
      </div>
      <div className={styles.bottomContent}>
        <Text 
          className={styles.label}
          {...label}/>
          <div className={styles.bottomRight}>
            <Icon className={styles.phoneIcon}
              {...phoneIcon}/>
            <Text 
              className={styles.phoneNumber}
              {...phoneNumber}/>
          </div>
        
      </div>
    </div>
  );
};

TopNav.defaultProps = defaultProps;

export default TopNav;
