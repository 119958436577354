import React from 'react';
import cx from 'classnames';

import styles from './Spinner.module.scss';

export type SpinnerProps = {
  className?: string;
};

const Spinner: React.FC<SpinnerProps> = ({
  className,
}) => {
  return (
    <div className={cx(styles.container, className)} >
      <div className={styles.spinner} />
    </div>
    
  );
};

export default Spinner;
