import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { CheckoutManualPagePresenterProps } from './CheckoutManualPage.presenter';
import { CheckoutManualPageProps } from '.';
import { CheckoutParams, OrderItems } from './utils';
import { createOrder, getListingDetails, marketingOptin } from '../../../modules/partnership/api';

export interface LocationState {
  from: {
    pathname: string;
    search?: string;
  },
  currentOrderItems: OrderItems;
  promoCodeTry?: string;
}

const withInteractor = (
  Presenter: React.FC<CheckoutManualPagePresenterProps>,
): React.FC<CheckoutManualPageProps> => {
  const Interactor: React.FC<CheckoutManualPageProps> = (props) => {
    const location = useLocation<LocationState>();
    const query = new URLSearchParams(location.search);
    const step: number = +(query.get('step') || 1);
    const eventId: string | null = query.get('eventId');
    const quantity: number = +(query.get('quantity') || 1);
    const discountCode: string = query.get('discount_code') || '';
    const utmCode: string = query.get('utm_promo') || '';
    const exclusiveListings: boolean = query.get('exclusive_listings') === 'true' || false;
    const deliveryId: number = +(query.get('delivery_id') || 0);
    const { ticketId } = useParams<CheckoutParams>();

    const [discountCodeTry] = useState<string | undefined>(
      location.state?.promoCodeTry,
    );

    const { error, isLoading, data } = useQuery(
      ['getListing', eventId, ticketId, quantity, discountCode, utmCode],
      () => getListingDetails((eventId || ''), ticketId, {
        quantity,
        exclusive_listings: exclusiveListings,
        delivery_id: deliveryId,
        discount_code: utmCode || discountCode || discountCodeTry,
        is_utm_promo: utmCode ? 1 : 0,
      }),
    );
    const { error: orderError, mutateAsync } = useMutation(createOrder);
    const { error: marketingOptinError, mutateAsync: setMarketingOptin } = useMutation(marketingOptin);

    return <Presenter
      {...props}
      isLoading={isLoading}
      step={step}
      ticketInfo={data}
      quantity={quantity}
      isUtm={!!utmCode}
      discountCode={utmCode || discountCode || discountCodeTry}
      error={error as Error}
      orderApiError={orderError as Error || undefined}
      createOrder={mutateAsync} 
      marketingOptin={setMarketingOptin}
      />;
  };
  return Interactor;
};
export default withInteractor;

