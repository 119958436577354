import React, { useEffect, useRef, useState } from 'react';
import { HostedFields, paypalCheckout, PayPalCheckout } from 'braintree-web';
import { useTranslation } from 'react-i18next';
import {
  CardInfoBlockProps,
  CardInfoState,
  defaultProps as cardInfoDefaultProps,
} from './CardInfoBlock';
// import { Account } from '../../../modules/auth/types';
import { binArray, dummyLastFour } from './creditCardsBin';
import styles from './CardInfoBlock.module.scss';
import { IS_DEV } from '../../../lib/config';
import { AriaRoles } from '../../../lib/types';

type CardValidationError = {
  details: {
    invalidFieldKeys: string[];
  };
};

export type CardInfoBlockPresenterProps = CardInfoBlockProps & {
  instance?: HostedFields;
  // account?: Account;
};
const withPresenter = (
  View: React.FC<CardInfoBlockProps>,
): React.FC<CardInfoBlockPresenterProps> => {
  const Presenter: React.FC<CardInfoBlockPresenterProps> = (props) => {
    const { instance, onCardTokenized, isCardDisabled } = props;
    const [state, setState] = useState<CardInfoState>('CardInfo');
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const { t } = useTranslation();
    useEffect(() => {
      if (isCardDisabled) {
        instance?.clear('number');
        instance?.clear('expirationDate');
        instance?.clear('cvv');
      }
    }, [isCardDisabled, instance]);
    const handleSubmit = async (e?: React.FormEvent<HTMLFormElement>) => {
      try {
        e?.preventDefault();
        if (!isCardDisabled) {
          if (!instance) {
            return;
          }
          // TODO confirm if we need to pass this fields in.
          const tokenPayload = await instance.tokenize({
            // cardholderName: '',
            // billingAddress: {},
          });

          if (tokenPayload) {
            if (((!IS_DEV && !dummyLastFour.includes(tokenPayload.details?.lastFour)))
              || !binArray.includes(tokenPayload.details?.bin)) {
              setValidationErrors(['number']);
              if (onCardTokenized) {
                onCardTokenized(null, true);
              }
            } else if (onCardTokenized) {
              onCardTokenized(tokenPayload, false);
            }
          }
        } else if (onCardTokenized && isCardDisabled) {
          onCardTokenized(null, false);
        }
      } catch (err) {
        if ((err as CardValidationError).details) {
          setValidationErrors((err as CardValidationError).details.invalidFieldKeys);
        } else {
          setValidationErrors(['number', 'expirationDate', 'cvv']);
        }
      }
      // TODO confirm requirements
      // payload.details.bin <-- there's some logic are validating this. not sure if part of the current story
    };

    // braintree styling documentation can be found here
    // https://developer.paypal.com/braintree/docs/guides/hosted-fields/styling
    // https://braintree.github.io/braintree-web/3.82.0/module-braintree-web_hosted-fields.html#~styleOptions
    const braintreeStyles = {
      input: {
        'font-size': '16px',
        color: '#3A3A3A',
        padding: '0.75rem 1rem 0.75rem 1rem',
      },
    };
    let cardInfoProps: CardInfoBlockProps = {
      ...cardInfoDefaultProps,
      blockTitle: {
        ...cardInfoDefaultProps.blockTitle,
        className: styles.cardInfoBlockLabels,
        value: t('cardInfo.cardInfoLabel'),
      },
      cardNumber: {
        ...cardInfoDefaultProps.cardNumber,
        className: styles.cardInfoBlockLabels,
        value: t('cardInfo.cardNumberLabel'),
      },
      expiration: {
        ...cardInfoDefaultProps.expiration,
        className: styles.cardInfoBlockLabels,
        value: t('cardInfo.expirationLabel'),
      },
      securityCode: {
        ...cardInfoDefaultProps.securityCode,
        className: styles.cardInfoBlockLabelsSecurity,
        value: t('cardInfo.securityCodeLabel'),
      },
      button: {
        ...cardInfoDefaultProps.button,
        text: {
          ...cardInfoDefaultProps.button?.text,
          value: t('cardInfo.button'),
        },
      },
      errorHighlighMessage: {
        ...cardInfoDefaultProps.errorHighlighMessage,
        message: {
          ...cardInfoDefaultProps.errorHighlighMessage.message,
          value: t('cardInfo.generalValidationError'),
        },
      },
      paypalMessage: {
        ...cardInfoDefaultProps.cardNumber,
        value: t('cardInfo.paypalMessage'),
      },
      onPaypalIconClicked: () => setState('Paypal'),
      onCardsIconClicked: () => setState('CardInfo'),
      hasValidationError: validationErrors.length > 0,
      state: state,
    };
    if (validationErrors.includes('number') && !isCardDisabled) {
      cardInfoProps = {
        ...cardInfoProps,
        cardNumberErrorMessage: {
          ...cardInfoProps.cardNumberErrorMessage,
          value: t('cardInfo.cardValidationError'),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      };
    }
    if (validationErrors.includes('cvv') && !isCardDisabled) {
      cardInfoProps = {
        ...cardInfoProps,
        securityCodeErrorMessage: {
          ...cardInfoProps.cardNumberErrorMessage,
          value: t('cardInfo.cvvValidationError'),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      };
    }
    if (validationErrors.includes('expirationDate') && !isCardDisabled) {
      cardInfoProps = {
        ...cardInfoProps,
        expirationErrorMessage: {
          ...cardInfoProps.cardNumberErrorMessage,
          value: t('cardInfo.expirationValidationError'),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      };
    }
    return (
      <View
        {...props} {...cardInfoProps}
        braintreeStyles={braintreeStyles}
        handleSubmit={handleSubmit}
      />
    );
  };
  return Presenter;
};

export default withPresenter;