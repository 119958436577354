import React, { useContext } from 'react';
import { ShippingInfoBlockProps } from './ShippingInfoBlock';
import { ShippingInfoBlockPresenterProps } from './ShippingInfoBlock.presenter';
const withInteractor = (
  Presenter: React.FC<ShippingInfoBlockPresenterProps>,
): React.FC<ShippingInfoBlockProps> => {
  const Interactor: React.FC<ShippingInfoBlockProps> = (props) => {
    return <Presenter
      {...props}/>;
  };
  return Interactor;
};
export default withInteractor;