import React, { useEffect, useContext, useMemo, useState, useCallback } from 'react';
import cx from 'classnames';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import styles from './MainLayout.module.scss';

import TopNav, { TopNavProps } from '../../organisms/TopNav';
import ErrorPage from '../../pages/ErrorPage';
import MyTicketsPage from '../../pages/MyTicketsPage';
import CheckoutManualPage from '../../pages/CheckoutManualPage';
import ReceiptPage from '../../pages/ReceiptPage';
import { ErrorBlockProps } from '../../blocks/ErrorBlock';
import TermsAndPrivacyModal from '../../organisms/TermsAndPrivacy';
import termsHtml from '../../../resources/termsAndPrivacy/terms';
import { TopNavContext } from '../../../modules/topNav/TopNavContext';
import { TERMS_MODAL } from '../../../lib/constants';
// import { MaintenanceBlockProps } from '../../blocks/MaintenanceBlock';

export const defaultProps = {
  topNav: {
    type: 'WithoutSearchInNav',
    logo: {
      asset: 'LogoC1Entertainment',
    },
    navMenuList: {
      tabs: [],
    },
    accountMenu: {
      state: 'Collapsed',
      greetingFirstName: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
      },
      cardNumber: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
      },
      divider: {
        type: 'Vertical',
        style: 'Thin',
        colour: 'White',
      },
      loyaltyPoints: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
      },
      expandButton: {
        type: 'Icon',
        style: 'Text',
        size: 'Small',
        icon: {
          asset: 'ChevronDown',
          style: 'White',
        },
      },
    },
  } as TopNavProps,
};

export type MainLayoutProps = {
  topNav?: TopNavProps;
  className?: string;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  topNav,
  className,
}) => {
  const location = useLocation<ErrorBlockProps>();
  // const locationMaintenance = useLocation<MaintenanceBlockProps>();
  const { pathname, search } = useLocation();
  // hide footer on events page
  const { disableTopNav } = useContext(TopNavContext);

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const modalQuery = query.get('modal');
  const history = useHistory();
  let modalContent = '';
  if (modalQuery === TERMS_MODAL)
    modalContent = termsHtml;
  const [openModal, setModalOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    if (modalQuery) {
      query.delete('modal');
      history.replace({
        search: query.toString(),
      });
    }
    setModalOpen(false);
  }, [history, modalQuery, query]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  useEffect(() => {
    if (modalQuery === TERMS_MODAL) {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [modalQuery]);

  let topNavView = (<div className={styles.navContainer}>
    <TopNav className={styles.topNav} {...topNav} />
  </div>);
  if (disableTopNav) {
    topNavView = (<div className={styles.topNavDisable}>
      <TopNav className={styles.topNav} {...topNav} />
    </div>);
  }

  return (
    <>
      <div className={cx(styles.mainLayout, className)}>
        {topNavView}
        <Switch>
          <Route exact path='/myticket/:orderId'>
            <MyTicketsPage className={styles.homepage} />
          </Route>

          <Route exact path='/checkout/:ticketId'>
            <CheckoutManualPage className={styles.homepage} />
          </Route>
          <Route exact path='/confirmation'>
            <ReceiptPage className={styles.homepage} />
          </Route>

          <Route path='/'>
            <ErrorPage errorBlock={location.state} className={styles.homepage} />
          </Route>
        </Switch>
      </div>
      <TermsAndPrivacyModal
        modalBodyContent={modalContent}
        show={openModal}
        handleClose={handleClose}
      />
    </>
  );
};

export default MainLayout;
