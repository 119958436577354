import React from 'react';
import { FooterProps } from '.';
import { FooterPresenterProps } from './Footer.presenter';

const withInteractor = (
  Presenter: React.FC<FooterPresenterProps>,
): React.FC<FooterProps> => {
  const Interactor: React.FC<FooterProps> = (props) => {
    const blockProps = {};
    return <Presenter {...props} {...blockProps} />;
  };
  return Interactor;
};
export default withInteractor;
