/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { RefObject, useRef } from 'react';
import cx from 'classnames';

import styles from './TextInput.module.scss';

import Button, { ButtonProps } from '../Button';

export type TextInputStateType = 'Empty' | 'Filled';
export type TextInputStyleType = 'Clear' | 'Default' | 'DefaultCenter';
export type TextInputSizeType = 'Regular' | 'Small';

export const defaultProps = {
  state: 'Filled' as TextInputStateType,
  style: 'Default' as TextInputStyleType,
  size: 'Small' as TextInputSizeType,
  button: {
    type: 'Icon',
    style: 'Text',
    size: 'Small',
    icon: {
      asset: 'Close',
      style: 'White',
    },
  } as ButtonProps,
};

export type TextInputProps = {
  state?: TextInputStateType;
  style?: TextInputStyleType;
  size?: TextInputSizeType;
  textValue?: string;
  textPlaceholder?: string;
  onTextChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onFocusChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onBlurChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  removeChar?: RegExp;
  maxLength?: number;
  className?: string;
  button?: ButtonProps;
  ariaLabel?: string;
  inputRef?: RefObject<HTMLInputElement>;
};

const TextInput: React.FC<TextInputProps> = ({
  state,
  style,
  size,
  textValue,
  textPlaceholder,
  onTextChanged,
  onFocusChanged,
  onBlurChanged,
  removeChar,
  maxLength,
  className,
  button,
  ariaLabel = '',
  inputRef,
}) => {

  const currentStyle = styles[`textInput${state}${style}${size}`];
  const localRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (removeChar && inputRef && inputRef.current) {
      inputRef.current.value = event.target.value.replace(removeChar, '');
    } else if (removeChar && localRef.current) {
      localRef.current.value = event.target.value.replace(removeChar, '');
    }
    if (onTextChanged) {
      onTextChanged(event);
    }
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (onFocusChanged) {
      onFocusChanged(event);
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (onBlurChanged) {
      onBlurChanged(event);
    }
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    if (event.key === 'Enter') {
      const targetElement = event.target as HTMLInputElement;
      const form = targetElement?.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, event.target);
        (form.elements[index + 1] as HTMLElement).focus();
        (form.elements[index + 1] as HTMLElement).click();
        event.preventDefault();
      }
      // upon enter we must check if there is any blur event waiting to change state
      if (onBlurChanged) {
        onBlurChanged(event as unknown as React.ChangeEvent<HTMLInputElement>);
      }
    }
  };

  const textView = (
    <input
      ref={inputRef || localRef}
      aria-label={ariaLabel}
      maxLength={maxLength}
      placeholder={textPlaceholder}
      value={textValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyPress}
      className={styles.text} />
  );

  let buttonView;

  switch (state) {
    case 'Empty':
      break;
    case 'Filled':
      buttonView = (
        <Button
          className={styles.button}
          {...button} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {textView}
      {buttonView}
    </div>
  );
};

TextInput.defaultProps = defaultProps;

export default TextInput;
