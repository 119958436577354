import React, { useEffect, useState, useRef } from 'react';
import printJS from 'print-js';
import { TabProps, defaultProps as TabDefaultsProps } from '../../atoms/Tab/Tab';
import { useTranslation } from 'react-i18next';
import { addQueryParam, capitalize, DateFormats, formatDate, getFiveDigitsZipCode, getOrderDetailsTranslationKey, getTwoDigitsCountryCode, getUnitDisplaySettings, handleAmountFormatingString, handleDateFormat, handleDecimalValuesForDisplay, handleTimeFormat, isEventGuestList, RewardUnits } from '../../../lib/util';
import { useHistory, useLocation } from 'react-router-dom';
import { TopNavTab } from '../../../modules/navigation/types';
// import { DropdownOption } from '../../molecules/Filter/Filter';
import { UserOrder, UserOrdersResponse } from '../../../modules/partnership';
import { MyTicketsPageProps, defaultProps } from './MyTicketsPage';
import { defaultProps as myTicketsBlockprops } from '../..//blocks/MyTicketsBlock/MyTicketsBlock';
import { OrderDetailsProps, defaultProps as orderDetailsDefaultProps } from '../../organisms/OrderDetails/OrderDetails';
import { formatter, formatTitle } from '../CheckoutManualPage/utils';
import { defaultProps as iconTextItemDefaultProps, IconTextDescriptionProps } from '../../molecules/IconTextDescription/IconTextDescription';
import { status, ticketStatus } from './util';
import { Event, UserOrderTos } from '../../../modules/partnership/types';
import myTicketsStyles from './MyTicketsPage.module.scss';
import { TextColourType } from '../../atoms/Text/Text';
import CardAsset from '../../../resources/icons/CreditCardBlack.svg';
import { IconDescription } from '../ReceiptPage/utils';
import PaypalAsset from '../../../resources/icons/Paypal.svg';
import { RYADD_ID } from '../../../lib/config';

export type MyTicketsPagePresenterProps = MyTicketsPageProps & {
  downloadTickets: (params: { orderId?: string, fileName?: string }) => Promise<any>;
  getEvent?: (id: string) => Promise<Event>;
  data?: UserOrderTos,
};

const withPresenter = (
  View: React.FC<MyTicketsPageProps>,
): React.FC<MyTicketsPagePresenterProps> => {
  const Presenter: React.FC<MyTicketsPagePresenterProps> = (props) => {
    const { data, downloadTickets, getEvent } = props;
    const history = useHistory();
    const { search } = useLocation();
    const { t } = useTranslation();
    const upComingEvents: TopNavTab = {
      id: '', // id no id then use up-coming events
      name: t('myTickets.upComingEvents'),
      children: [],
    };
    const pastEvents: TopNavTab = {
      id: 'past',
      name: t('myTickets.pastEvents'),
      children: [],
    };
    const categories: TopNavTab[] = [upComingEvents, pastEvents];
    const [selectedTab, setSelectedTab] = useState(categories ? categories[0].id : 'all');
    const isUpComingTab = useRef(false); // component wise variable whose value remains consistent on re-renders

    const [pdfurl, setUrl] = useState('');
    const [viewOrderModal, setViewOrderModal] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState<{ order: UserOrder, orderConfirmed: boolean } | undefined>();
    const [isGuestList, setIsGuestList] = useState<boolean>();
    const rewardType = 'account?.loyalty_program?.loyalty_unit_name.toLowerCase()';
    const { rewardSign, useDecimals } = getUnitDisplaySettings(rewardType);
    /* isRewardsOnly is used to check if the event has a tag 'LOYALTY_REWARDS_PAYMENT'
    and if it does then we are hiding PaymentMethod and Amount in $ from MyTicket Modal.*/
    const [isRewardsOnly, setIsRewardsOnly] = useState<boolean>();
    /* isRewardsOnlyPayment check is added if payment is made using only the loyalty unit (for Split Payment Events)
    in this case also need to hide both blocks of payment and amount in $ from MyTicket Modal.*/
    const [isRewardsOnlyPayment, setIsRewardsOnlyPayment] = useState<boolean>();
    const handleAdditonalInfo = (order: UserOrderTos | undefined): IconTextDescriptionProps[] => {
      const confirmationPagePath: string = getOrderDetailsTranslationKey(order?.order?.cartItems[0]?.stockType || '', String(order?.order.delivery.id), 'confirmation_page');
      let iconDescriptionList: IconDescription[] = [];
      const confirmationPageOptions: string[] = t(`${confirmationPagePath}`, {
        customerEmail: order?.order.email,
        customerMobilePhone: order?.order?.billingAddress?.phoneNumber,
        customerShippingAddress: t('deliveryInformation.ticketDelivery.customerAddressTemplate', {
          address: order?.order?.shippingAddress.address1,
          city: order?.order?.shippingAddress.city,
          state: order?.order?.shippingAddress.state,
          postalCode: getFiveDigitsZipCode(order?.order?.shippingAddress?.country || '', order?.order?.shippingAddress.zip || ''),
          country: getTwoDigitsCountryCode(order?.order?.shippingAddress.country || ''),
        }),
        returnObjects: true,
      });
      if (isGuestList) {
        iconDescriptionList = [
          {
            asset: '',
            description: `${t('orderConfirmation.guestListEventOrderNotes.title')}`,
          },
          {
            asset: '',
            description: `• ${t('orderConfirmation.guestListEventOrderNotes.email', {
              email: order?.order?.email,
            })}`,
          },
          {
            asset: '',
            description: `• ${t('orderConfirmation.guestListEventOrderNotes.name')}`,
          },
          {
            asset: '',
            description: `• ${t('orderConfirmation.guestListEventOrderNotes.onSiteEvent')}`,
          },
          {
            asset: '',
            description: `• ${t('orderConfirmation.guestListEventOrderNotes.virtualEvent')}`,
          },
        ];
      } else if (confirmationPageOptions && typeof confirmationPageOptions !== 'string') {  // No stocktype for old orders
        iconDescriptionList = confirmationPageOptions?.map((option) => {
          return {
            asset: '',
            description: `• ${option}`,
          };
        });
        iconDescriptionList.unshift({
          asset: '',
          description: t(getOrderDetailsTranslationKey(order?.order?.cartItems[0]?.stockType || '', String(order?.order?.delivery.id), 'delivery_header')),
        });
      }

      const iconTextDescriptionProps: IconTextDescriptionProps[] = iconDescriptionList?.map((iconDescription) => {
        return {
          ...iconTextItemDefaultProps,
          icon: iconDescription.asset ? {
            ...iconTextItemDefaultProps.icon,
            asset: iconDescription.asset,
          } : { ...iconTextItemDefaultProps.icon, className: myTicketsStyles.hideIcons },
          description: {
            ...iconTextItemDefaultProps.description,
            value: iconDescription.description,
          },
          type: 'Default',
        } as IconTextDescriptionProps;
      });
      return iconTextDescriptionProps;
    };
    // useEffect(() => {
    //   const doFetchEvent = async () => {
    //     try {
    //       if (getEvent && selectedTicket) {
    //         const response: Event = await getEvent(data.event_id.toString());
    //         setIsRewardsOnly(response?.tags?.includes(REWARDS_ONLY_TAG));
    //         setIsGuestList(isEventGuestList(response?.tags || [], data.stock_type, data.delivery.id));
    //       }
    //     } catch {
    //       // TODO
    //     }
    //   };
    //   void doFetchEvent();
    // }, [selectedTicket, getEvent]);


    // const handleTabClick = (id: string) => {
    //   setSelectedTab(id);

    //   const newSearch = addQueryParam(search, {
    //     'category': id,
    //   });
    //   history.push({
    //     search: newSearch,
    //   });

    // };
    // const tabList: TabProps[] = [];
    // // const categoryDropdownOptions: DropdownOption[] = [];
    // if (categories) {
    //   categories.forEach((item) => {
    //     const selectedCategory = item.id === selectedTab;
    //     // categoryDropdownOptions.push({
    //     //   title: item.name,
    //     //   type: 'SelectValue',
    //     //   value: {
    //     //     value: item.id,
    //     //   },
    //     // });

    //     tabList.push({
    //       ...TabDefaultsProps,
    //       style: 'Rounded',
    //       active: selectedCategory ? 'Active' : undefined,
    //       colour: 'Dark',
    //       text: {
    //         ...TabDefaultsProps.text,
    //         value: item.name,
    //         style: 'SemiBold',
    //         colour: 'BaseDark',
    //         size: 'Large',
    //       },
    //       onTabClicked: () => handleTabClick(item.id),
    //     });
    //   });
    // }

    // const handleCardClick = (order: UserOrder) => {
    //   const orderConfirmed = order.ticket_files && order.ticket_files.length || order.delivery.tracking ? true : false;
    //   if (order.loyalty_details?.transactions?.length > 0 && order.total_charge.toFixed(2) === order.loyalty_details.transactions[0].transaction_amount_usd.toFixed(2)) {
    //     setIsRewardsOnlyPayment(true);
    //   } else {
    //     setIsRewardsOnlyPayment(false);
    //   }
    //   setSelectedTicket({ order: order, orderConfirmed: orderConfirmed });
    //   setViewOrderModal(true);
    // };

    // const eventCards = myTickets.map((item): CardProps => {
    //   const [ticketStatusText, ticketStatusType] = ticketStatus(item.order_status.orderStatusId);
    //   return {
    //     ...CardDefaultProps,
    //     handleCardClick: () => handleCardClick(item),
    //     type: 'TicketStatus',
    //     image: {
    //       srcValue: item.event?.image,
    //     },
    //     ticketStatus: {
    //       ...CardDefaultProps.ticketStatus,
    //       state: 'Negative',
    //       date: {
    //         ...CardDefaultProps.ticketStatus.date,
    //         className: myTicketsStyles.dateCreated,
    //         value: `${t('myTickets.orderDateTitle')} ${new Date(item.order_date).toLocaleDateString('en-US')}`,
    //       },
    //       title: {
    //         ...CardDefaultProps.ticketStatus.title,
    //         value: item.event?.name,
    //       },
    //       eventDateStatus: {
    //         ...CardDefaultProps.ticketStatus.eventDateStatus,
    //         date: {
    //           ...CardDefaultProps.ticketStatus.eventDateStatus?.date,
    //           value: handleDateFormat(item.event?.local_date || new Date()),
    //         },
    //         time: {
    //           ...CardDefaultProps.ticketStatus.eventDateStatus?.time,
    //           value: t('exclusiveWidget.atTime', {
    //             time: handleTimeFormat(item.event?.local_date || new Date()),
    //           }),
    //         },
    //       },
    //       location: {
    //         ...CardDefaultProps.ticketStatus.location,
    //         value: `${item.event?.venue.name}, ${item.event?.venue.city}, ${item.event?.venue.state_code}`,
    //       },
    //       quantity: {
    //         ...CardDefaultProps.ticketStatus.quantity,
    //         align: 'Left',
    //         value: t('myTickets.quantity'),
    //       },
    //       amount: {
    //         ...CardDefaultProps.ticketStatus.amount,
    //         value: item.quantity,
    //       },
    //       tag: {
    //         ...CardDefaultProps.ticketStatus.tag,
    //         type: ticketStatusType,
    //         label: {
    //           ...CardDefaultProps.ticketStatus.tag?.label,
    //           value: t(ticketStatusText),
    //           colour: ticketStatusType as TextColourType,
    //         },
    //       },
    //     },
    //     linkPath: `/events/${item.id}`,
    //   };
    // });
    let orderDetailsView: OrderDetailsProps = {};
    if (data){
      orderDetailsView = {
        ...orderDetailsDefaultProps,
        showCloseButton: false,
        closeButton: {
          ...orderDetailsDefaultProps.closeButton,
          onButtonClicked: () => setViewOrderModal(false),
        },
        sellerNotes: {
          ...orderDetailsDefaultProps.sellerNotes,
          label: {
            ...orderDetailsDefaultProps.sellerNotes.label,
            value: data?.order?.cartItems[0]?.description ? t('orderConfirmation.sellerNotes') : '',
          },
          description: {
            ...orderDetailsDefaultProps.sellerNotes.description,
            value: data?.order?.cartItems[0]?.description,
          },
        },
        infoHeader: {
          ...orderDetailsDefaultProps.infoHeader,
          title: {
            ...orderDetailsDefaultProps.infoHeader?.title,
            value: t('orderConfirmation.header'),
          },
          details: {
            ...orderDetailsDefaultProps.infoHeader?.details,
            value: t('orderDetails.detailSubHeader', {
              ticketId: data?.order.orderId,
              orderDate: formatDate(new Date(data?.order?.orderDate || ''), t, DateFormats.CONFIRMATION),
            }),
          },
        },
        ticketInfoTitle: {
          ...orderDetailsDefaultProps.ticketInfoTitle,
          value: t('orderConfirmation.ticketInfoTitle'),
        },
        showHighlight: true,
        highlightMessage: {
          ...orderDetailsDefaultProps.highlightMessage,
          message: {
            ...orderDetailsDefaultProps.highlightMessage.message,
            value: t('orderDetails.highlightMessage'),
          },
        },
        printButton: {
          ...orderDetailsDefaultProps.printButton,
          text: {
            ...orderDetailsDefaultProps.printButton?.text,
            value: t('orderConfirmation.print'),
          },
          onButtonClicked: () => {
            if (pdfurl)
              printJS(pdfurl);
          },
        },
        infoDetails: {
          ...orderDetailsDefaultProps.infoDetails,
          title: {
            ...orderDetailsDefaultProps.infoDetails?.title,
            value: data?.event?.name,
          },
          eventDateStatus: {
            ...orderDetailsDefaultProps.infoDetails?.eventDateStatus,
            date: {
              ...orderDetailsDefaultProps.infoDetails?.eventDateStatus?.date,
              value: data?.event?.localDate ? formatDate(new Date(data.event?.localDate), t, DateFormats.TICKETINFO) : '',
            },
          },
          venue: {
            ...orderDetailsDefaultProps.infoDetails?.venue,
            value: formatTitle(
              data?.event?.venue.name || '',
              data?.event?.venue.address.city || '',
              data?.event?.venue.address.stateCode || '',
            ),
          },
        },
        seatInfo: {
          ...orderDetailsDefaultProps.seatInfo,
          section: {
            ...orderDetailsDefaultProps.seatInfo?.section,
            label: {
              ...orderDetailsDefaultProps.seatInfo?.section?.label,
              value: t('ticketInfo.seatInfo.section'),
            },
            description: {
              ...orderDetailsDefaultProps.seatInfo?.section?.description,
              value: data?.order?.cartItems[0]?.section,
            },
          },
          row: {
            ...orderDetailsDefaultProps.seatInfo?.row,
            label: {
              ...orderDetailsDefaultProps.seatInfo?.row?.label,
              value: t('ticketInfo.seatInfo.row'),
            },
            description: {
              ...orderDetailsDefaultProps.seatInfo?.row?.description,
              value: data?.order?.cartItems[0]?.row,
            },
          },
          quantity: {
            ...orderDetailsDefaultProps.seatInfo?.quantity,
            label: {
              ...orderDetailsDefaultProps.seatInfo?.quantity?.label,
              value: t('ticketInfo.seatInfo.quantity'),
            },
            description: {
              ...orderDetailsDefaultProps.seatInfo?.quantity?.description,
              value: data?.order?.cartItems[0]?.quantity,
            },
          },
        },
        showLogo: false,
        divider: {
          ...orderDetailsDefaultProps.divider,
        },
        deliveryInfoTitle: {
          ...orderDetailsDefaultProps.deliveryInfoTitle,
          value: t('orderConfirmation.deliveryInfoTitle'),
        },
        additionalTicketInformationList: {
          ...orderDetailsDefaultProps.additionalTicketInformationList,
          iconTextDescriptions: handleAdditonalInfo(data),
        },
        deliveryDateInfo: data?.order.delivery.tracking ?
          {
            ...orderDetailsDefaultProps.deliveryDateInfo,
            style: 'SubduedLabel',
            label: {
              ...orderDetailsDefaultProps.deliveryDateInfo.label,
              type: 'Body',
              size: 'Small',
              style: 'Regular',
              colour: 'SubduedLight',
              align: 'Left',
              value: 'Tracking Number',
            },
            description: {
              ...orderDetailsDefaultProps.deliveryDateInfo.description,
              type: 'Body',
              size: 'Medium',
              style: 'Regular',
              colour: 'BaseDark',
              align: 'Left',
              value: data?.order.delivery.tracking,
            },
          } : {},
        showDownloadButton:  (data?.order?.cartItems[0]?.ticketFiles && data?.order?.cartItems[0]?.ticketFiles.length) 
                              || data?.order?.delivery?.tracking ? true : false,
        downloadButton: {
          ...orderDetailsDefaultProps.downloadButton,
          text: {
            ...orderDetailsDefaultProps.downloadButton.text,
            value: (data?.order?.cartItems[0]?.ticketFiles && data?.order?.cartItems[0]?.ticketFiles.length) 
              ? t('orderDetails.download') : t('orderDetails.trackOrder'),
          },
          linkPath: data?.order?.delivery?.tracking ? `https://www.ups.com/track?tracknum=${data?.order?.delivery?.tracking}`
            : `https://secure.ticketsonsale.com/account/etickets.action?token=${data?.order?.cartItems[0]?.ticketFiles}&wsUser=${RYADD_ID}`,
          newTab: true,
        },
        billingInfoTitle: {
          ...orderDetailsDefaultProps.billingInfoTitle,
          value: t('orderConfirmation.billingInfoTitle'),
        },
        infoOnMultipleLine: {
          ...orderDetailsDefaultProps.infoOnMultipleLine,
          label: {
            ...orderDetailsDefaultProps.infoOnMultipleLine?.label,
            value: t('billingInfo.title'),
          },
          address: {
            ...orderDetailsDefaultProps.infoOnMultipleLine?.address,
            value: t('billingInfo.smallVersionTemplate', {
              firstName: data?.order?.billingAddress.firstName,
              lastName: data?.order?.billingAddress.lastName,
              address: data?.order?.billingAddress.address1,
              city: data?.order?.billingAddress.city,
              state: data?.order?.billingAddress.state,
              postalCode: getFiveDigitsZipCode(data?.order?.billingAddress.country || '', data?.order?.billingAddress.zip || ''),
              country: data?.order?.billingAddress.country,
            }),
          },
        },
        phoneNumber: {
          ...orderDetailsDefaultProps.phoneNumber,
          label: {
            ...orderDetailsDefaultProps.phoneNumber?.label,
            value: t('billingInfo.phoneNumber'),
          },
          description: {
            ...orderDetailsDefaultProps.phoneNumber?.description,
            value: data?.order?.billingAddress.phoneNumber,
          },
        },
        paymentMethod: isRewardsOnly || isRewardsOnlyPayment ? undefined : {
          ...orderDetailsDefaultProps.paymentMethod,
          cardImage: {
            ...orderDetailsDefaultProps.paymentMethod.label,
            srcValue: PaypalAsset,
          },
          label: {
            ...orderDetailsDefaultProps.paymentMethod.label,
            value: t('billingInfo.paymentMethod'),
          },
          description: {
            ...orderDetailsDefaultProps.paymentMethod.description,
            value: data?.order?.payment?.paymentType === 'PAYPAL' ? data?.order?.payment?.paymentId : t('billingInfo.paymentValue', {
              cardType: data?.order?.payment?.paymentType,
              lastFour: data?.order?.payment?.paymentId,
            }),
          },
          showCard: data?.order?.payment?.paymentType === 'PAYPAL' ? true : false,
        },
        totalCardPayment: isRewardsOnly || isRewardsOnlyPayment ? undefined : {
          ...orderDetailsDefaultProps.totalCardPayment,
          label: {
            ...orderDetailsDefaultProps.totalCardPayment?.label,
            value: t('billingInfo.totalCardPayment'),
          },
          description: {
            ...orderDetailsDefaultProps.totalCardPayment?.description,
            value: formatter.format((data?.order.totalCharge || 0)),
          },
        },
        // rightContent: data?.loyalty_details?.transactions && data.loyalty_details.transactions.length ? {
        //   ...orderDetailsDefaultProps.rightContent,
        //   label: {
        //     ...orderDetailsDefaultProps.rightContent.label,
        //     value: t('billingInfo.rewardsRedeemed'),
        //   },
        //   description: {
        //     ...orderDetailsDefaultProps.rightContent.description,
        //     value: t('billingInfo.rewardsTemplate', {
        //       value: `${rewardSign}${handleDecimalValuesForDisplay(handleAmountFormatingString(data.loyalty_details.transactions[0].transaction_amount), useDecimals)}`,
        //       unit: 'handleDisplayUnitName(account)',
        //     }),
        //   },
        // } : {},
      };
    }
    const pageProps: MyTicketsPageProps = {
      viewOrderModal: viewOrderModal,
      orderDetailsProps: orderDetailsView,
      setViewOrderModal: () => setViewOrderModal(false),
      setUrl: setUrl,
      isCp1Exclusive: selectedTicket?.order?.event?.tags?.includes('C1_EXCLUSIVE'),
      cardHolderExclusiveLabel: t('orderConfirmation.cardHolderLabel'),
      ticketNotice: t('orderConfirmation.ticketNotice'),
      orderDetailsTitle: t('orderConfirmation.title'),
      isMobileTicket: data?.order.mobileScanEnabled, // Discard once VSCO-433 is implemented      
    };

    return <View
      {...props}
      {...pageProps}
    />;
  };
  return Presenter;
};

export default withPresenter;
