import React from 'react';
import cx from 'classnames';

import styles from './Image.module.scss';

export const defaultProps = {
};

export type ImageProps = {
  srcValue?: string;
  fallbackValue?: string;
  className?: string;
};

const Image: React.FC<ImageProps> = ({
  srcValue,
  fallbackValue,
  className,
}) => {
  return (
    <div className={cx(styles.image, className)} style={{ backgroundImage: `url(${srcValue}), url(${fallbackValue})` }}/>
  );
};

Image.defaultProps = defaultProps;

export default Image;
