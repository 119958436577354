import React from 'react';
import cx from 'classnames';

import styles from './TicketAlertModal.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import Icon, { IconProps } from '../../atoms/Icon';
import Text, { TextProps } from '../../atoms/Text';
import { Modal } from 'react-bootstrap';

export const defaultProps = {
  closeButton: {
    type: 'Icon',
    style: 'Text',
    size: 'Small',
    icon: {
      asset: 'Close',
      style: 'DigitalGrey80',
    },
  } as ButtonProps,
  icon: {
    asset: 'Warning',
    style: 'DigitalGrey80',
  } as IconProps,
  title: {
    type: 'Heading',
    size: 'Small',
    style: 'Light',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  message: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  primaryButton: {
    type: 'Text',
    style: 'ContainedGreen',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
};

export type TicketAlertModalProps = {
  closeButton?: ButtonProps;
  icon?: IconProps;
  title?: TextProps;
  message?: TextProps;
  primaryButton?: ButtonProps;
  className?: string;
  open?: boolean;
};

export type TicketAlertModalTitleMessage = {
  title: string,
  message: string
};

const TicketAlertModal: React.FC<TicketAlertModalProps> = ({
  closeButton,
  icon,
  title,
  message,
  primaryButton,
  className,
  open,
}) => {
  return (
    <Modal
      className={styles.modal}
      dialogClassName={styles.modalDialog}
      show={open}
      onHide={closeButton?.onButtonClicked}
    >
      <div className={cx(styles.alertModal, className)}>
        <div className={styles.topContent}>
          <Button
            className={styles.closeButton}
            {...closeButton} />
          <div className={styles.content}>
            <Icon
              className={styles.icon}
              {...icon} />
            <Text
              className={styles.title}
              {...title} />
          </div>
        </div>
        <div className={styles.bottomContent}>
          <Text
            className={styles.message}
            {...message} />
          <div className={styles.buttonPair}>
            <Button
              className={styles.primaryButton}
              {...primaryButton} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

TicketAlertModal.defaultProps = defaultProps;

export default TicketAlertModal;
