import React from 'react';
import cx from 'classnames';

import styles from './InfoHeader.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  title: {
    type: 'Subheading',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  details: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
};

export type InfoHeaderProps = {
  title?: TextProps;
  details?: TextProps;
  className?: string;
};

const InfoHeader: React.FC<InfoHeaderProps> = ({
  title,
  details,
  className,
}) => {
  return (
    <div className={cx(styles.infoHeader, className)}>
      <Text
        className={styles.title}
        {...title} />
      <Text
        className={styles.details}
        {...details} />
    </div>
  );
};

InfoHeader.defaultProps = defaultProps;

export default InfoHeader;
