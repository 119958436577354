import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ButtonProps,
  defaultProps as defaultButtonProps,
} from '../../atoms/Button/Button';
import { FooterProps } from './';
import defaultProps from './Footer';
import { addQueryParam } from '../../../lib/util';
import { useHistory } from 'react-router-dom';
import { TERMS_MODAL } from '../../../lib/constants';
import { PRIVACY_URL } from '../../../lib/config';

export type FooterPresenterProps = FooterProps & {};

const withPresenter = (
  View: React.FC<FooterProps>,
): React.FC<FooterPresenterProps> => {
  const Presenter: React.FC<FooterPresenterProps> = (props) => {
    const { t } = useTranslation();
    const buttons: ButtonProps[] = [];
    const history = useHistory();

    const renderModal = (modalType: string) => {
      const params = addQueryParam(history.location.search, {
        'modal': modalType,
      });

      if (params) {
        history.push({
          search: params,
        });
      }
    };

    buttons.push({
      ...defaultButtonProps,
      text: { value: t('footer.help') },
      type: 'Text',
      linkPath: '/help',
    });
    buttons.push({
      ...defaultButtonProps,
      text: { value: t('footer.legal') },
      type: 'Text',
      onButtonClicked: () => { renderModal(TERMS_MODAL); },

    });
    buttons.push({
      ...defaultButtonProps,
      text: { value: t('footer.privacy') },
      type: 'Text',
      linkPath: PRIVACY_URL,
      newTab: true,
    });
    const blockProps: FooterProps = {
      ...defaultProps,
      footerMenuList: {
        buttons,
      },
    };
    return <View {...props} {...blockProps} />;
  };
  return Presenter;
};

export default withPresenter;
