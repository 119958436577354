import React from 'react';
import cx from 'classnames';

import styles from './StepperItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export type StepperItemTypeType = 'Selected' | 'Unselected';
export type StepperItemStateType = 'Incomplete' | 'Complete';

export const defaultProps = {
  type: 'Unselected' as StepperItemTypeType,
  state: 'Incomplete' as StepperItemStateType,
  stepNumber: {
    type: 'Body',
    size: 'ExtraSmall',
    style: 'SemiBold',
    colour: 'Disabled',
    align: 'Center',
  } as TextProps,
  stepLabel: {
    type: 'Body',
    size: 'Medium',
    style: 'SemiBold',
    colour: 'SubduedDark',
    align: 'Center',
  } as TextProps,
};

export type StepperItemProps = {
  type?: StepperItemTypeType;
  state?: StepperItemStateType;
  stepNumber?: TextProps;
  stepLabel?: TextProps;
  className?: string;
};

const StepperItem: React.FC<StepperItemProps> = ({
  type,
  state,
  stepNumber,
  stepLabel,
  className,
}) => {

  const currentStyle = styles[`stepperItem${type}${state}`];

  const indicatorView = (
    <div className={styles.indicator} />
  );
  const contentView = (
    <div className={styles.content}>
      <Text
        className={styles.stepNumber}
        {...stepNumber} />
      <Text
        className={styles.stepLabel}
        {...stepLabel} />
    </div>
  );


  switch (`${type}${state}`) {
    case 'SelectedIncomplete':
      break;
    case 'UnselectedIncomplete':
      break;
    case 'UnselectedComplete':
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {indicatorView}
      {contentView}
    </div>
  );
};

StepperItem.defaultProps = defaultProps;

export default StepperItem;
