import React from 'react';
import cx from 'classnames';

import styles from './ShippingInfoBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import { DividerProps } from '../../atoms/Divider';
import { IconProps } from '../../atoms/Icon';
import CheckBoxItem, { CheckBoxItemProps } from '../../atoms/CheckBoxItem';
import TextFields, { TextFieldsProps } from '../../molecules/TextFields';
import Dropdown, { DropdownProps } from '../../molecules/Dropdown';
import Button, { ButtonProps } from '../../atoms/Button';
import { CHECKOUT_SHIPPING_FORM } from '../../../lib/constants';
import { OrderAddress } from '../../../modules/partnership';
import HighlightMessage, { HighlightMessageProps } from '../../molecules/HighlightMessage';

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  errorHighlighMessage: {
    type: 'Error',
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'CP1Red',
    } as DividerProps,
    icon: {
      asset: 'CloseCircleFill',
      style: 'DigitalGrey100',
    } as IconProps,
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    } as TextProps,
  } as HighlightMessageProps,
  
  checkBoxItem: {
    state: 'Unselected',
    icon: {
      asset: 'CheckboxEmpty',
      style: 'DigitalGrey100',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as CheckBoxItemProps,
  firstName: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  lastName: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  address: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  sccondaryAddress: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  city: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  state: {
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    dropdownSelect: {
      state: 'Empty',
      text: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      icon: {
        asset: 'ChevronDown',
        style: 'DigitalGrey80',
      },
    },
    error: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    } as TextProps,
  } as DropdownProps,
  postalCode: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  country: {
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    dropdownSelect: {
      state: 'Empty',
      text: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      icon: {
        asset: 'ChevronDown',
        style: 'DigitalGrey80',
      },
    },
    error: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'Negative',
      align: 'Left',
    } as TextProps,
  } as DropdownProps,
  phoneNumber: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  button: {
    type: 'Text',
    style: 'Contained',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
  isBillingStep: false,
};

export type ShippingInfoBlockProps = {
  blockTitle?: TextProps;
  errorHighlighMessage?: HighlightMessageProps;
  checkBoxItem?: CheckBoxItemProps;
  firstName?: TextFieldsProps;
  lastName?: TextFieldsProps;
  address?: TextFieldsProps;
  sccondaryAddress?: TextFieldsProps;
  city?: TextFieldsProps;
  state?: DropdownProps;
  postalCode?: TextFieldsProps;
  country?: DropdownProps;
  phoneNumber?: TextFieldsProps;
  className?: string;
  button?: ButtonProps;
  showError?: boolean;
  handleSubmit?: (event?: React.FormEvent<HTMLFormElement>) => void;
  formSubmitted?: (event?: OrderAddress) => void;
  isBillingStep?: boolean;
  billingInfo?: OrderAddress;
};

const ShippingInfoBlock: React.FC<ShippingInfoBlockProps> = ({
  blockTitle,
  errorHighlighMessage,
  checkBoxItem,
  firstName,
  lastName,
  address,
  sccondaryAddress,
  city,
  state,
  postalCode,
  country,
  phoneNumber,
  className,
  button,
  showError,
  handleSubmit,
  isBillingStep,
}) => {
  let errorHighlightSection;
  if (showError) {
    errorHighlightSection = (
      <HighlightMessage
        {...errorHighlighMessage}
      />
    );
  }
  return (
    <form id={CHECKOUT_SHIPPING_FORM}
      onSubmit={(event) => {
        event.preventDefault();
        if (handleSubmit) {
          handleSubmit(event);
        } 
      }}
      className={cx(styles.shippingInfoBlock, className)}>
      <Text
        className={styles.blockTitle}
        {...blockTitle} />
        {errorHighlightSection}
      {!isBillingStep && <CheckBoxItem
        className={styles.checkBoxItem}
        {...checkBoxItem} />}
      <div className={styles.content}>
        <div className={styles.fieldGroup1}>
          <TextFields
            className={styles.firstName}
            {...firstName} />
          <TextFields
            className={styles.lastName}
            {...lastName} />
        </div>
        <TextFields
          className={styles.address}
          {...address} />
        <TextFields
          className={styles.sccondaryAddress}
          {...sccondaryAddress} />
        <div className={styles.fieldGroup2}>
          <TextFields
            className={styles.city}
            {...city} />
          <Dropdown
            className={styles.state}
            {...state} />
        </div>
        <div className={styles.fieldGroup3}>
          <TextFields
            className={styles.postalCode}
            {...postalCode} />
          <Dropdown
            className={styles.country}
            {...country} />
        </div>
        <TextFields
          className={styles.phoneNumber}
          {...phoneNumber} />
        <Button
          form={CHECKOUT_SHIPPING_FORM}
          className={styles.button}
          buttonType='submit'
          {...button} />
      </div>
    </form>
  );
};

ShippingInfoBlock.defaultProps = defaultProps;

export default ShippingInfoBlock;
