import React from 'react';
import { OverlayTrigger, Tooltip as BootstrapToolTip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/Overlay';
import './bootstrap.scss';


export type ToolTipProps = {
  className?: string,
  text?: string,
  placement? :Placement,
};

export const defaultProps = {
  text: '',
  placement:'auto' as Placement,
};

const renderToolTip = (props) => (
  <BootstrapToolTip
    {...props}
  >
    {props.text}
  </BootstrapToolTip>
);

const ToolTip: React.FC<ToolTipProps> = ({
  text,
  className,
  placement,
  children,
}) => {
  return (
    <OverlayTrigger
      key="bottom"
      placement={placement}
      transition={false}
      overlay={renderToolTip({ className, text })}
    >
    {  
      ({ ...props }) => (
      <div {...props}>{children}</div>
      )
    }
    </OverlayTrigger>
  );
};

ToolTip.defaultProps = defaultProps;

export default ToolTip;
