import { CANADA_PROVINCE_CODES, US_STATE_CODES } from '../../../lib/constants';
import { handleCountryCodeUS, isCanadaSelected } from '../../../lib/util';
import { OrderAddress } from '../../../modules/partnership';
import { TextFieldsStateType } from '../../molecules/TextFields/TextFields';

/**
 * Gets the code of the country where a province/state is
 * @param stateCode code of the state
 * @returns code of the country
 */
export function getCountryCodeFromState(stateCode: string): string {
  let countryCode = '';
  US_STATE_CODES.forEach((state) => {
    if (state.code === stateCode) {
      countryCode = 'us';
    }
  });
  CANADA_PROVINCE_CODES.forEach((province) => {
    if (province.code === stateCode) {
      countryCode = 'ca';
    }
  });
  return countryCode;
}
type OrderAddressKey = keyof OrderAddress;
export const requiredFields: OrderAddressKey[] = [
  'first_name',
  'last_name',
  'address_line_1',
  'city',
  'postal_code',
  'phone_number',
  'region',
  'country',
];

export type OrderAddressState = {
  first_name?: TextFieldsStateType;
  last_name?: TextFieldsStateType;
  address_line_1?: TextFieldsStateType;
  city?: TextFieldsStateType;
  postal_code?: TextFieldsStateType;
  phone_number?: TextFieldsStateType;
  region?: TextFieldsStateType;
  country?: TextFieldsStateType;
};

/**
 * To validate address fields
 * @param field field name of the address
 * @param value current value of the field
 * @returns true if the value is valid
 */
export function validateOrderAddressField(field: OrderAddressKey, value: string, shippingDetails: OrderAddress): boolean {
  let isFieldValidated = false;

  isFieldValidated = !!value.trim();

  // Special Validation for some fields
  switch (field) {
    case 'postal_code':
      if (handleCountryCodeUS(shippingDetails.country)) {
        isFieldValidated = value.length === 5;
      } else if (isCanadaSelected(shippingDetails.country)) {
        isFieldValidated = value.length === 6;
      }
  }
  return isFieldValidated;
}
