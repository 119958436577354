import React, { useState, useMemo, useEffect, useCallback } from 'react';
import TagManager from 'react-gtm-module';
import BingTracking from 'react-tracking-bing';
import printJS from 'print-js';
import { useTranslation } from 'react-i18next';
import { defaultProps as iconTextItemDefaultProps, IconTextDescriptionProps } from '../../molecules/IconTextDescription/IconTextDescription';
import { formatter, formatTitle, OrderItems } from '../CheckoutManualPage/utils';
import { defaultProps, ReceiptPageProps } from './ReceiptPage';
import { PaymentMethodProps } from '../../molecules/PaymentMethod';
import { TextItemProps } from '../../molecules/TextItem';
import { DateFormats, formatDate, handleAmountFormatingString, getTicketListingTranslationKey, handleDecimalValuesForDisplay } from '../../../lib/util';
import { IconDescription } from './utils';
import {  TRANSACTION_AFFILIATION } from '../../../lib/constants';
import { ListingDetails } from '../../../modules/partnership';
import styles from './ReceiptPage.module.scss';
import { GTM_DATA_LAYER } from '../../../lib/config';
import { useRoktLauncher } from '../../../modules/rokt/RoktContext';
import {
  defaultProps as orderInfoDefaultProps,
} from '../../blocks/OrderInfo/OrderInfo';
import PaypalAsset from '../../../resources/icons/Paypal.svg';

export type ReceiptPagePresenterProps = ReceiptPageProps & {
  orderItems?: OrderItems;
  error?: Error;
};

const withPresenter = (
  View: React.FC<ReceiptPageProps>,
): React.FC<ReceiptPagePresenterProps> => {
  const Presenter: React.FC<ReceiptPagePresenterProps> = (props) => {
    const { orderItems } = props;
    const { t } = useTranslation();
    const [pdfurl, setUrl] = useState('');
    const launcher = useRoktLauncher();
    const tagManagerArgs = useMemo(() => ({
      dataLayer: {
        event:orderItems?.order?.event?.name,  
        transactionId: orderItems?.order?.event?.id,
        transactionAffiliation: TRANSACTION_AFFILIATION,
        transactionTotal: orderItems?.listing?.pricing?.total,
        transactionTax: orderItems?.listing?.pricing?.taxes,
        transactionShipping: orderItems?.listing?.pricing?.delivery_fee,
        transactionProducts: [{
          sku: orderItems?.order?.production_id,
          name: orderItems?.order?.event?.name, 
          category: orderItems?.order?.event?.venue?.state_code,
          price: orderItems?.listing?.pricing?.price_per,
          quantity: orderItems?.listing?.pricing?.quantity,
        }] },
      dataLayerName: GTM_DATA_LAYER,
    }), [orderItems]);
    const giftCardAmount = (orderItems?.listing?.pricing.giftCards || [])[0]?.amount || 0;

    const logRoktEvents = useCallback(async () => {
      try {
        if (launcher) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          await launcher.selectPlacements({
            attributes: {
              email: orderItems?.email,
              amount: orderItems?.listing?.pricing?.total,
              confirmationref: orderItems?.order?.id,  
              firstname: orderItems?.billingAddress?.first_name,
              lastname: orderItems?.billingAddress?.last_name,
              city: orderItems?.billingAddress?.city,
              state: orderItems?.billingAddress?.region,
              eventcity: orderItems?.order?.event?.venue?.city,
              primaryartistname: orderItems?.order?.event?.performers[0].name,
              eventdate: orderItems?.order?.event?.utc_date,
              eventid: orderItems?.order?.event?.id,
              eventname: orderItems?.order?.event?.name,
              eventvenue: orderItems?.order?.event?.venue,
              majorcat: orderItems?.order?.event?.taxonomy?.genre,
              minorcat: orderItems?.order?.event?.taxonomy?.segment,
            },
          });
        }
      } catch (error) {
        // do something for rokt errors
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      void logRoktEvents();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      if (GTM_DATA_LAYER) {
        TagManager.dataLayer(tagManagerArgs);
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      BingTracking.getTracker().push('event', '', { 'revenue_value': orderItems?.listing?.pricing?.total, 'currency': 'USD' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const showCharge = (orderItems?.dollarsUsed || 0) > 0;
    const showRewards = (orderItems?.rewardsUsed || 0) > 0;
    const useDecimals = true;

    const confirmationPagePath: string = getTicketListingTranslationKey(orderItems?.listing as ListingDetails, 'confirmation_page');
    let isGuest = false;
    if (confirmationPagePath === 'precheckoutBlock.guestList') {
      isGuest = true;
    }
    const confirmationPageOptions: string[] = t(`${confirmationPagePath}`, {
      customerEmail: orderItems?.email,
      customerMobilePhone: orderItems?.billingAddress?.phone_number,
      customerShippingAddress: t('deliveryInformation.ticketDelivery.customerAddressTemplate', {
        address: orderItems?.billingAddress?.address_line_1,
        city: orderItems?.billingAddress?.city,
        state: orderItems?.billingAddress?.region,
        postalCode: orderItems?.billingAddress?.postal_code,
        country: orderItems?.billingAddress?.country,
      }),
      returnObjects: true,
    });
    const iconDescriptions: IconDescription[] = !isGuest ?
      confirmationPageOptions.map((option) => {
        return {
          asset: '',
          description: `• ${option}`,
        };
      }) : [
        {
          asset: '',
          description: `${t('orderConfirmation.guestListEventOrderNotes.title')}`,
        },
        {
          asset: '',
          description: `• ${t('orderConfirmation.guestListEventOrderNotes.email', {
            email: 'account?.email',
          })}`,
        },
        {
          asset: '',
          description: `• ${t('orderConfirmation.guestListEventOrderNotes.name')}`,
        },
        {
          asset: '',
          description: `• ${t('orderConfirmation.guestListEventOrderNotes.onSiteEvent')}`,
        },
        {
          asset: '',
          description: `• ${t('orderConfirmation.guestListEventOrderNotes.virtualEvent')}`,
        },
      ];
    if (!isGuest) {
      iconDescriptions.unshift({
        asset: '',
        description: t(getTicketListingTranslationKey(orderItems?.listing as ListingDetails, 'delivery_header')),
      });
    }
    const paymentMethod: PaymentMethodProps = {
      ...defaultProps.receiptBlock.orderDetails?.paymentMethod,
      cardImage: {
        ...defaultProps.receiptBlock.orderDetails?.paymentMethod?.cardImage,
        srcValue: PaypalAsset,
      },
      label: {
        ...defaultProps.receiptBlock.orderDetails?.paymentMethod?.label,
        value: t('billingInfo.paymentMethod'),
      },
      description: {
        ...defaultProps.receiptBlock.orderDetails?.paymentMethod?.description,
        value: orderItems?.paypalEmail ? orderItems?.paypalEmail : t('billingInfo.paymentValue', {
          cardType: orderItems?.cardType,
          lastFour: orderItems?.lastFour,
        }),
      },
      showCard: orderItems?.paypalEmail ? true : false,
    };
    const totalCardPayment: TextItemProps = {
      ...defaultProps.receiptBlock.orderDetails?.totalCardPayment,
      label: {
        ...defaultProps.receiptBlock.orderDetails?.totalCardPayment?.label,
        value: showCharge ? t('billingInfo.totalCardPayment') : '',
      },
      description: {
        ...defaultProps.receiptBlock.orderDetails?.totalCardPayment?.description,
        value: showCharge ? formatter.format(orderItems?.dollarsUsed || 0) : '',
        className: styles.totalCardPaymentFont,
      },
    };
    const rewardsMilesRedeemed: TextItemProps = orderItems?.listing?.pricing.promo?.promoValid || 
      !!orderItems?.listing?.pricing.giftCards ? {
        ...defaultProps.receiptBlock.orderDetails?.rightContent,
        label: {
          ...defaultProps.receiptBlock.orderDetails?.rightContent?.label,
          value: t('ticketInfo.orderTotal.addedDiscount'),
        },
        description: {
          ...defaultProps.receiptBlock.orderDetails?.rightContent?.description,
          value: t('ticketInfo.orderTotal.discountNoCurrencyTemplate', {
            charge: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.promo?.promoDiscount ||
              giftCardAmount), useDecimals),
          }),
        },
      } : {};
    const receiptPageProps: ReceiptPageProps = {
      ...defaultProps,
      receiptBlock: {
        ...defaultProps.receiptBlock,
        title: {
          ...defaultProps.receiptBlock.title,
          value: t('orderConfirmation.title'),
        },
        orderDetails: {
          ...defaultProps.receiptBlock.orderDetails,
          infoHeader: {
            ...defaultProps.receiptBlock.orderDetails?.infoHeader,
            title: {
              ...defaultProps.receiptBlock.orderDetails?.infoHeader?.title,
              value: t('orderConfirmation.header'),
            },
            details: {
              ...defaultProps.receiptBlock.orderDetails?.infoHeader?.details,
              value: t('orderConfirmation.detailsTemplate', {
                ticketId: orderItems?.order?.id,
              }),
            },
          },
          ticketInfoTitle: {
            ...defaultProps.receiptBlock.orderDetails?.ticketInfoTitle,
            value: t('orderConfirmation.ticketInfoTitle'),
          },
          showHighlight: false,
          showCloseButton: false,
          printButton: {
            ...defaultProps.receiptBlock.orderDetails?.printButton,
            text: {
              ...defaultProps.receiptBlock.orderDetails?.printButton?.text,
              value: t('orderConfirmation.print'),
            },
            onButtonClicked: () => {
              if (pdfurl) {
                // for android devices the blob pdf url will not open a print dialog on the same browser tab, in order to make it work
                // we are downloading the pdf file so user can open that and print using native print dialog
                const userAgent = navigator.userAgent.toLowerCase();
                const android = userAgent.indexOf('android') > -1;
                if (android) {
                  const a = document.createElement('a');
                  a.href = pdfurl;
                  a.download = `VividSeats_receipt_${orderItems?.order?.event?.id}`;
                  a.style.position = 'fixed';
                  a.target = '_blank';
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                } else // for rest of the platforms (desktop, ios)
                  printJS(pdfurl);
              }
            },
          },
          infoDetails: {
            ...defaultProps.receiptBlock.orderDetails?.infoDetails,
            title: {
              ...defaultProps.receiptBlock.orderDetails?.infoDetails?.title,
              value: orderItems?.order?.event?.name,
            },
            eventDateStatus: {
              ...defaultProps.receiptBlock.orderDetails?.infoDetails?.eventDateStatus,
              date: {
                ...defaultProps.receiptBlock.orderDetails?.infoDetails?.eventDateStatus?.date,
                value: orderItems?.order?.event?.local_date ? formatDate(new Date(orderItems?.order?.event?.local_date), t, DateFormats.TICKETINFO) : '',
              },
            },
            venue: {
              ...defaultProps.receiptBlock.orderDetails?.infoDetails?.venue,
              value: formatTitle(
                orderItems?.order?.event?.venue.name || '',
                orderItems?.order?.event?.venue.city || '',
                orderItems?.order?.event?.venue.state_code || '',
              ),
            },
          },
          seatInfo: {
            ...defaultProps.receiptBlock.orderDetails?.seatInfo,
            section: {
              ...defaultProps.receiptBlock.orderDetails?.seatInfo?.section,
              label: {
                ...defaultProps.receiptBlock.orderDetails?.seatInfo?.section?.label,
                value: t('ticketInfo.seatInfo.section'),
              },
              description: {
                ...defaultProps.receiptBlock.orderDetails?.seatInfo?.section?.description,
                value: orderItems?.order?.ticket?.section,
              },
            },
            row: {
              ...defaultProps.receiptBlock.orderDetails?.seatInfo?.row,
              label: {
                ...defaultProps.receiptBlock.orderDetails?.seatInfo?.row?.label,
                value: t('ticketInfo.seatInfo.row'),
              },
              description: {
                ...defaultProps.receiptBlock.orderDetails?.seatInfo?.row?.description,
                value: orderItems?.order?.ticket?.row,
              },
            },
            quantity: {
              ...defaultProps.receiptBlock.orderDetails?.seatInfo?.quantity,
              label: {
                ...defaultProps.receiptBlock.orderDetails?.seatInfo?.quantity?.label,
                value: t('ticketInfo.seatInfo.quantity'),
              },
              description: {
                ...defaultProps.receiptBlock.orderDetails?.seatInfo?.quantity?.description,
                value: orderItems?.order?.ticket?.quantity?.toString(),
              },
            },
          },
          sellerNotes: {
            ...defaultProps.receiptBlock.orderDetails?.sellerNotes,
            label: {
              ...defaultProps.receiptBlock.orderDetails?.sellerNotes?.label,
              value: orderItems?.order?.ticket?.notes ? t('ticketInfo.sellerNotes') : '',
            },
            description: {
              ...defaultProps.receiptBlock.orderDetails?.sellerNotes?.description,
              value: orderItems?.order?.ticket?.notes,
            },
          },
          divider: {
            ...defaultProps.receiptBlock.orderDetails?.divider,
          },
          deliveryInfoTitle: {
            ...defaultProps.receiptBlock.orderDetails?.deliveryInfoTitle,
            value: t('orderConfirmation.deliveryInfoTitle'),
          },
          additionalTicketInformationList: {
            ...defaultProps.receiptBlock.orderDetails?.additionalTicketInformationList,
            iconTextDescriptions: iconDescriptions.map((iconDescription) => {
              return {
                ...iconTextItemDefaultProps,
                icon: iconDescription.asset ? {
                  ...iconTextItemDefaultProps.icon,
                  asset: iconDescription.asset,
                } : { ...iconTextItemDefaultProps.icon, className: styles.hideIcons },
                description: {
                  ...iconTextItemDefaultProps.description,
                  value: iconDescription.description,
                },
                type: 'Default',
              } as IconTextDescriptionProps;
            }),
          },
          deliveryDateInfo: {
            ...defaultProps.receiptBlock.orderDetails?.deliveryDateInfo,
            description: {
              ...defaultProps.receiptBlock.orderDetails?.deliveryDateInfo?.description,
              value: orderItems?.order?.ticket?.fulfillment?.estimated_delivery_date ? formatDate(
                new Date(orderItems?.order?.ticket?.fulfillment?.estimated_delivery_date),
                t,
                DateFormats.CONFIRMATION,
              ) : '',
            },
            label: {
              ...defaultProps.receiptBlock.orderDetails?.deliveryDateInfo?.label,
              value: t('orderConfirmation.expectedDelivery'),
            },
          },
          billingInfoTitle: {
            ...defaultProps.receiptBlock.orderDetails?.billingInfoTitle,
            value: t('orderConfirmation.billingInfoTitle'),
          },
          infoOnMultipleLine: {
            ...defaultProps.receiptBlock.orderDetails?.infoOnMultipleLine,
            label: {
              ...defaultProps.receiptBlock.orderDetails?.infoOnMultipleLine?.label,
              value: t('billingInfo.title'),
            },
            address: {
              ...defaultProps.receiptBlock.orderDetails?.infoOnMultipleLine?.address,
              value: t('billingInfo.smallVersionTemplate', {
                firstName: orderItems?.billingAddress?.first_name,
                lastName: orderItems?.billingAddress?.last_name,
                address: orderItems?.billingAddress?.address_line_1,
                city: orderItems?.billingAddress?.city,
                state: orderItems?.billingAddress?.region,
                postalCode: orderItems?.billingAddress?.postal_code,
                country: orderItems?.billingAddress?.country,
              }),
            },
          },
          phoneNumber: {
            ...defaultProps.receiptBlock.orderDetails?.phoneNumber,
            label: {
              ...defaultProps.receiptBlock.orderDetails?.phoneNumber?.label,
              value: t('billingInfo.phoneNumber'),
            },
            description: {
              ...defaultProps.receiptBlock.orderDetails?.phoneNumber?.description,
              value: orderItems?.billingAddress?.phone_number,
            },
          },
          paymentMethod: paymentMethod,
          totalCardPayment: totalCardPayment,
          rewardsMilesRedeemed: rewardsMilesRedeemed,
          rightContent: rewardsMilesRedeemed,
          showCostBreakdown: orderItems?.listing?.event.venue.state_code === 'PA',
          showInsurance: orderItems?.insurancePurchased,
          insuranceTitle: {
            ...defaultProps.receiptBlock.orderDetails?.phoneNumber?.label,
            value: t('ticketInfo.insuranceTitle'),
          },
          insuranceLabel: {
            ...defaultProps.receiptBlock.orderDetails?.phoneNumber?.description,
            value: t('ticketInfo.insuranceLabel'),
          },
          costBreakdown: {
            ordarTotal: {
              ...orderInfoDefaultProps.costBreakdown.ordarTotal,
              item: {
                ...orderInfoDefaultProps.costBreakdown.ordarTotal?.item,
                value: t('ticketInfo.orderTotal.orderTotal'),
              },
              price: {
                ...orderInfoDefaultProps.costBreakdown.ordarTotal?.price,
                value:
                    t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
                      charge: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.total || 0), useDecimals),
                    }),
              },
            },
            tickets: {
              ...orderInfoDefaultProps.costBreakdown.tickets,
              item: {
                ...orderInfoDefaultProps.costBreakdown.tickets?.item,
                value: t('ticketInfo.orderTotal.ticketsTemplate', {
                  quantity: orderItems?.listing?.pricing.quantity,
                  pricePer: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.price_per || 0), useDecimals),
                }),
              },
              price: {
                ...orderInfoDefaultProps.costBreakdown.tickets?.price,
                value:
                  t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
                    charge: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.subtotal || 0), useDecimals),
                  }),
              },
            },
            serviceFee: {
              ...orderInfoDefaultProps.costBreakdown.serviceFee,
              item: {
                ...orderInfoDefaultProps.costBreakdown.serviceFee?.item,
                value: t('ticketInfo.orderTotal.serviceFee'),
              },
              price: {
                ...orderInfoDefaultProps.costBreakdown.serviceFee?.price,
                value: t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
                  charge: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.fulfillment_fee || 0), useDecimals),
                }),
              },
            },
            delivery: {
              ...orderInfoDefaultProps.costBreakdown.delivery,
              item: {
                ...orderInfoDefaultProps.costBreakdown.delivery?.item,
                value: t('ticketInfo.orderTotal.delivery'),
              },
              price: {
                ...orderInfoDefaultProps.costBreakdown.delivery?.price,
                value: t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
                  charge: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.delivery_fee || 0), useDecimals),
                }),
              },
            },
            tax: {
              ...orderInfoDefaultProps.costBreakdown.delivery,
              item: {
                ...orderInfoDefaultProps.costBreakdown.delivery?.item,
                value: t('ticketInfo.orderTotal.tax'),
              },
              price: {
                ...orderInfoDefaultProps.costBreakdown.delivery?.price,
                value: t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
                  charge: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.taxes || 0), useDecimals),
                }),
              },
            },
            showPromoFeature: false,
            addedDiscount: orderItems?.listing?.pricing.promo?.promoValid ||
              !!orderItems?.listing?.pricing.giftCards ? {
                ...orderInfoDefaultProps.costBreakdown?.serviceFee,
                item: {
                  ...orderInfoDefaultProps.costBreakdown?.serviceFee?.item,
                  value: t('ticketInfo.orderTotal.addedDiscount'),
                },
                price: {
                  ...orderInfoDefaultProps.costBreakdown?.serviceFee?.price,
                  value: t('ticketInfo.orderTotal.discountNoCurrencyTemplate', {
                    charge: handleDecimalValuesForDisplay(formatter.format(orderItems?.listing?.pricing.promo?.promoDiscount ||
                      giftCardAmount), useDecimals),
                  }),
                },
              } : {},
          },
        },
        ticketNotice: t('orderConfirmation.ticketNotice'),
        isMobileTicket: true, // Discard once VSCO-433 is implemented
      },
      setUrl: setUrl,
    };
    return <View {...props} {...receiptPageProps} />;
  };
  return Presenter;
};

export default withPresenter;

