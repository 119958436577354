import React, { ChangeEventHandler, SyntheticEvent } from 'react';
import cx from 'classnames';

import styles from './DropdownSelect.module.scss';

import Text, { TextProps } from '../Text';
import Icon, { IconProps } from '../Icon';
import { DropdownItem } from './utils';

export type DropdownSelectStateType = 'Empty' | 'Filled';
export type DropdownSelectDropdownSelectTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  state: 'Filled' as DropdownSelectStateType,
  text: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'Disabled',
    align: 'Left',
  } as TextProps,
  icon: {
    asset: 'ChevronDown',
    style: 'DigitalGrey80',
  } as IconProps,
};

export type DropdownSelectProps = {
  state?: DropdownSelectStateType;
  dropdownSelectType?: DropdownSelectDropdownSelectTypeType;
  onDropdownSelectClicked?: (e?: string) => void;
  text?: TextProps;
  dropdownItems?: DropdownItem[];
  icon?: IconProps;
  selection?: DropdownItem;
  className?: string;
};

const DropdownSelect: React.FC<DropdownSelectProps> = ({
  state,
  dropdownSelectType,
  onDropdownSelectClicked,
  text,
  dropdownItems,
  icon,
  selection,
  className,
}) => {

  const currentStyle = styles[`dropdownSelect${state}`];
  
  switch (state) {
    case 'Empty':
      break;
    case 'Filled':
      break;
  }

  const handleSelection = (option) => {
    if (onDropdownSelectClicked) {
      onDropdownSelectClicked(option.target.value);
    }
  };

  const options: React.ReactNode[] = (dropdownItems || []).map((item) => {
    const selected = item.value.toLocaleLowerCase() === selection?.value.toLocaleLowerCase();
    return (<option className={styles.option}
      selected={selected}
      aria-label={selected ? item.ariaLabel : ''}
      key={item.value}
      value={item.value}>
      {item.label || item.value}
    </option>);
  });

  return (
    <select onChange={handleSelection} className={cx(currentStyle, className)}>
      {options}
    </select>
  );
};

DropdownSelect.defaultProps = defaultProps;

export default DropdownSelect;
