import React, { RefObject, useState } from 'react';
import { HostedFieldsTokenizePayload } from 'braintree-web';
import cx from 'classnames';
import styles from './CheckoutManualPage.module.scss';
import StepperBlock, { StepperBlockProps } from '../../blocks/StepperBlock';
import CustomerInfoBlock, {
  CustomerInfoBlockProps,
} from '../../blocks/CustomerInfoBlock';
import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import BillingInfoBlock, {
  BillingInfoBlockProps,
} from '../../blocks/BillingInfoBlock';
import OrderInfo, { OrderInfoProps } from '../../blocks/OrderInfo';
import LoyaltyBlock, { LoyaltyBlockProps } from '../../blocks/LoyaltyBlock';
import CardInfoNotRequiredBlock, {
  CardInfoNotRequiredBlockProps,
} from '../../blocks/CardInfoNotRequiredBlock';
import CardInfoBlock from '../../blocks/CardInfoBlock';
import CustomerInfoReadOnlyBlock, { CustomerInfoReadOnlyBlockProps } from '../../blocks/CustomerInfoReadOnlyBlock';
import PaymentInformationReadOnlyBlock, { PaymentInformationReadOnlyBlockProps } from '../../blocks/PaymentInformationReadOnlyBlock';
import LegalBlock, { LegalBlockProps } from '../../blocks/LegalBlock';
import { getCodeFromStep, SliderItems } from './utils';
import ErrorBlock, { ErrorBlockProps } from '../../blocks/ErrorBlock/ErrorBlock';
import { CheckoutSteps } from '../../../lib/util';
import ShippingInfoBlock, { ShippingInfoBlockProps } from '../../blocks/ShippingInfoBlock';
import { OrderAddress } from '../../../modules/partnership';
import TicketAlertModal, { TicketAlertModalProps } from '../../organisms/TicketAlertModal';
import { BlackBoxInput } from '../../blocks/CardInfoNotRequiredBlock/util';
import Spinner from '../../atoms/Spinner';
import ErrorHandler from '../../../modules/error/ErrorHandler';
import CustomerReview, { CustomerReviewProps } from '../../blocks/CustomerReview';

export const defaultProps = {
  stepperBlock: {
    stepperList: {
      stepperItems: [],
    },
  } as StepperBlockProps,
  customerInfoBlock: {
    blockTitle: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    } as TextProps,
    emailLabel: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    } as TextProps,
    emailField: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
      size: 'Medium',
    } as TextProps,
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
      text: {
        type: 'Subheading',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
      },
    } as ButtonProps,
  } as CustomerInfoBlockProps,
  button: {
    type: 'Text',
    style: 'Contained',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
  ticketInfo: {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    dateAndTime: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    venue: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    seatInfo: {
      section: {
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
      },
      row: {
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
      },
      quantity: {
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
      },
    },
    sellerNotes: {
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      },
    },
    
    deliveryMethod: {
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      },
    },
    logo: {
      asset: 'VividLogo',
    },
    message: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
      size: 'Medium',
    } as TextProps,
  } as OrderInfoProps,
  insuranceError: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'Negative',
    align: 'Left',
  } as TextProps,
};

type ValidBlocks = {
  customerInfo?: CustomerInfoBlockProps;
  billingInfo?: BillingInfoBlockProps;
  shippingInfo?: ShippingInfoBlockProps;

  cardInfoNotRequired?: CardInfoNotRequiredBlockProps;
  loyalty?: LoyaltyBlockProps;

  customerInfoReadOnly?: CustomerInfoReadOnlyBlockProps;
  paymentInfoReadOnly?: PaymentInformationReadOnlyBlockProps;
  shippingInfoReadOnly?: CustomerInfoReadOnlyBlockProps;
  ticketInfoBlock?: OrderInfoProps;
  legalBlock?: LegalBlockProps;
};

export type ContentSwicherProps = {
  step: number;
  blockProps?: ValidBlocks;
  isRewardsOnly?: boolean;
  showRewards?: boolean;
  rewardsCost?: SliderItems;
  onFormSubmit?: (payload?: OrderAddress) => void;
  onCardTokenized?: (payload: HostedFieldsTokenizePayload | null, hasValidationError: boolean) => void;
  hiddenInputToLoad?: string;
  cardDisabled?: boolean;
  hardTicket?: boolean;
  isUTOrPA?: boolean;
  orderTotal?: number;
  onPaypalSuccess?: (data) => void;
  insuranceWidget?: string;
  onInsuranceSelection?: (eselection: string) => void;
  insuranceError?: TextProps;
};

const ContentSwitchable: React.FC<ContentSwicherProps> = ({
  step,
  blockProps,
  isRewardsOnly,
  showRewards,
  rewardsCost,
  onFormSubmit,
  onCardTokenized,
  cardDisabled,
  hardTicket,
  isUTOrPA,
  orderTotal,
  onPaypalSuccess,
  insuranceWidget,
  onInsuranceSelection,
  insuranceError,
}) => {
  const code: CheckoutSteps = getCodeFromStep(step, hardTicket === true);
  const orderInfoMobile = (
    <OrderInfo className={styles.orderInfoMobile}
      {...blockProps?.ticketInfoBlock} />
  );
  switch (code) {
    case CheckoutSteps.CUSTOMERINFO:
    default:
      return (
        <React.Fragment>
          <CustomerInfoBlock
            className={styles.checkoutFlowBlock}
            {...blockProps?.customerInfo}
          />
          {isUTOrPA ? orderInfoMobile : ''}
        </React.Fragment>
      );
    case CheckoutSteps.BILLINGINFO:
      return (
        <React.Fragment>
          <ShippingInfoBlock
            className={styles.checkoutFlowBlock}
            {...blockProps?.shippingInfo}
            formSubmitted={(event) => {
              if (onFormSubmit) {
                onFormSubmit(event);
              }
            }}
          />
          {isUTOrPA ? orderInfoMobile : ''}
        </React.Fragment>
        
      );
    case CheckoutSteps.SHIPPINGINFO:
      return (
        <React.Fragment>
          <ShippingInfoBlock
            className={styles.checkoutFlowBlock}
            {...blockProps?.shippingInfo}
            formSubmitted={(event) => {
              if (onFormSubmit) {
                onFormSubmit(event);
              }
            }}
          />
           {isUTOrPA ? orderInfoMobile : ''}
        </React.Fragment>
       
      );
    case CheckoutSteps.PAYMENTINFO:
    
      return (
        <React.Fragment>
          <CardInfoBlock onCardTokenized={onCardTokenized}
            className={styles.checkoutFlowBlock}
            isCardDisabled={cardDisabled}
            onPaypalSuccess={onPaypalSuccess}
            orderTotal={orderTotal}
          />
          {orderInfoMobile}
        </React.Fragment>
      );
      
    case CheckoutSteps.CONFIRMATION:
      return (
        <React.Fragment>
          <div className={styles.checkoutFlowBlock} >
            <div 
              onClick={(e) => onInsuranceSelection && onInsuranceSelection((e.target as HTMLDivElement).id)}  
              dangerouslySetInnerHTML={{ __html: insuranceWidget || '' }}>
            </div>
            <Text className={styles.insuranceError} {...insuranceError}/>
          </div>
          
          <CustomerInfoReadOnlyBlock
            className={styles.checkoutFlowBlock}
            {...blockProps?.customerInfoReadOnly} />
          <PaymentInformationReadOnlyBlock
            className={styles.checkoutFlowBlock}
            {...blockProps?.paymentInfoReadOnly} />
          {hardTicket ? <CustomerInfoReadOnlyBlock
            className={styles.checkoutFlowBlock}
            {...blockProps?.shippingInfoReadOnly} /> : ''}
          {orderInfoMobile}
          <LegalBlock
            className={styles.checkoutFlowBlock}
            {...blockProps?.legalBlock} />
        </React.Fragment>
      );
  }

};

export type CheckoutManualPageProps = {
  stepperBlock?: StepperBlockProps;
  customerInfoBlock?: CustomerInfoBlockProps;
  billingInfoBlock?: BillingInfoBlockProps;
  shippingInfoBlock?: ShippingInfoBlockProps;
  cardInfoNotRequiredBlock?: CardInfoNotRequiredBlockProps;
  loyaltyBlock?: LoyaltyBlockProps;
  customerInfoReadOnly?: CustomerInfoReadOnlyBlockProps;
  shippingInfoReadOnly?: CustomerInfoReadOnlyBlockProps;
  paymentInfoReadOnly?: PaymentInformationReadOnlyBlockProps;
  legalBlock?: LegalBlockProps;
  button?: ButtonProps;
  className?: string;
  ticketInfo?: OrderInfoProps;
  customerReview?: CustomerReviewProps;
  step?: number;
  isRewardsOnly?: boolean;
  showRewards?: boolean;
  rewardsCost?: SliderItems;
  error?: Error;
  orderApiError?: Error;
  orderErrorBlock?: ErrorBlockProps,
  currentForm?: string,
  onFormSubmit?: (payload?: OrderAddress) => void;
  onCardTokenized?: (payload: HostedFieldsTokenizePayload | null, hasValidationError: boolean) => void;
  onPaypalSuccess?: (data) => void;
  cardDisabled?: boolean;
  ticketAlertModalProps?: TicketAlertModalProps;
  hardTicket?: boolean;
  ioBlackBoxRef?: RefObject<HTMLInputElement>;
  isLoading?: boolean;
  mobileMessage?: TextProps;
  orderTotal?: number;
  isUTOrPA?: boolean;
  insuranceWidget?: string;
  onInsuranceSelection?: (eselection: string) => void;
  insuranceError?: TextProps;
};

const CheckoutManualPage: React.FC<CheckoutManualPageProps> = ({
  stepperBlock,
  customerInfoBlock,
  billingInfoBlock,
  shippingInfoBlock,
  cardInfoNotRequiredBlock,
  loyaltyBlock,
  customerInfoReadOnly,
  shippingInfoReadOnly,
  paymentInfoReadOnly,
  legalBlock,
  button,
  className,
  ticketInfo,
  customerReview,
  step,
  isRewardsOnly,
  showRewards,
  rewardsCost,
  currentForm,
  onFormSubmit,
  onCardTokenized,
  onPaypalSuccess,
  error,
  orderApiError,
  orderErrorBlock,
  cardDisabled,
  ticketAlertModalProps,
  hardTicket,
  ioBlackBoxRef,
  isLoading,
  mobileMessage,
  orderTotal,
  isUTOrPA,
  insuranceWidget,
  onInsuranceSelection,
  insuranceError,
}) => {
  const switchableProps: ContentSwicherProps = {
    blockProps: {
      customerInfo: customerInfoBlock,
      billingInfo: billingInfoBlock,
      shippingInfo: shippingInfoBlock,
      cardInfoNotRequired: cardInfoNotRequiredBlock,
      loyalty: loyaltyBlock,
      shippingInfoReadOnly,
      customerInfoReadOnly,
      paymentInfoReadOnly,
      ticketInfoBlock: ticketInfo,
      legalBlock: legalBlock,
    },
    isRewardsOnly: isRewardsOnly,
    step: step || 1,
    showRewards: showRewards,
    rewardsCost: rewardsCost,
    onCardTokenized,
    onFormSubmit,
    cardDisabled,
    hardTicket,
    isUTOrPA,
    orderTotal,
    onPaypalSuccess,
    insuranceWidget,
    onInsuranceSelection,
    insuranceError,
  };
  if (error) {
    return <ErrorHandler error={error} />;
  }
  if (orderApiError)
    return <ErrorBlock className={styles.orderErrorContainer} {...orderErrorBlock} />;
  if (isLoading) {
    return (
      <div className={cx(styles.checkoutManualPage, className)}>
        <div className={styles.container}>
          <Spinner/>
        </div>
      </div>
    );
  }
  return (
    <div className={cx(styles.checkoutManualPage, className)}>
      <div className={styles.container}>
        <div className={styles.rightContent}>
          <OrderInfo className={styles.ticketInfo} {...ticketInfo} />
          <CustomerReview { ...customerReview }/>
        </div>
        <div className={styles.leftContent}>
          <StepperBlock
            className={styles.stepperBlock}
            {...stepperBlock}
            activeStep={step}
          />
          <ContentSwitchable {...switchableProps} />
        </div>
        <div className={styles.mobileRightContent}>
          <Text 
            className={styles.mobileMessage}
            {...mobileMessage}/>
          <Button form={currentForm} className={styles.button} {...button} />
        </div>
      </div>
      <TicketAlertModal {...ticketAlertModalProps} />
      <BlackBoxInput ioBlackBoxRef={ioBlackBoxRef} />
    </div>

  );
};

CheckoutManualPage.defaultProps = defaultProps;

export default CheckoutManualPage;

