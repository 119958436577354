import React from 'react';
import cx from 'classnames';

import styles from './LoyaltyBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import SliderInteraction, { SliderInteractionProps } from '../../organisms/SliderInteraction';
import HighlightMessage, { HighlightMessageProps } from '../../molecules/HighlightMessage';

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  description: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  sliderInteraction: {
    initialValue: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    miles: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    slider: {
      trackEmpty: {
        state: 'Empty',
      },
      trackFilled: {
        state: 'Filled',
      },
      toggle: {
        size: 'Default',
      },
    },
    pointsInput: {
      state: 'Empty',
      style: 'DefaultCenter',
      size: 'Regular',
    },
    dollarValue: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    remainingBalance: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
  } as SliderInteractionProps,
  highlightMessage: {
    type: 'Info',
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'InteractionBlue50',
    },
    icon: {
      asset: 'Info',
      style: 'DigitalGrey80',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as HighlightMessageProps,
};

export type LoyaltyBlockProps = {
  blockTitle?: TextProps;
  description?: TextProps;
  sliderInteraction?: SliderInteractionProps;
  canPayFull?: boolean;
  highlightMessage?: HighlightMessageProps;
  className?: string;
};

const LoyaltyBlock: React.FC<LoyaltyBlockProps> = ({
  blockTitle,
  description,
  sliderInteraction,
  canPayFull,
  highlightMessage,
  className,
}) => {
  let hightlight;
  if (!canPayFull) {
    hightlight =
    <HighlightMessage
        className={styles.highlightMessage}
        {...highlightMessage} />;
  }
  return (
    <div className={cx(styles.loyaltyBlock, className)}>
      <div className={styles.topContent}>
        <Text
          className={styles.blockTitle}
          {...blockTitle} />
        <Text
          className={styles.description}
          {...description} />
      </div>
      <SliderInteraction
        className={styles.sliderInteraction}
        {...sliderInteraction} />
      {hightlight}
    </div>
  );
};

LoyaltyBlock.defaultProps = defaultProps;

export default LoyaltyBlock;
