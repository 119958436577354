import React, { RefObject } from 'react';


export const BlackBoxInput: React.FC<{ ioBlackBoxRef?: RefObject<HTMLInputElement> }> = ({
  ioBlackBoxRef,
}) => {
  let input;
  if (ioBlackBoxRef) {
    input = <input type="hidden" name="ioBlackBox" id="ioBlackBox" ref={ioBlackBoxRef} />;
  }
  return (
    <React.Fragment>
      {input}
    </React.Fragment>
  );
};