import React from 'react';
import cx from 'classnames';

import styles from './PaymentMethod.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Image, { ImageProps } from '../../atoms/Image';

export const defaultProps = {
  label: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  description: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
};

export type PaymentMethodProps = {
  label?: TextProps;
  cardImage?: ImageProps;
  description?: TextProps;
  className?: string;
  showCard?: boolean;
};

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  label,
  cardImage,
  description,
  className,
  showCard = true,
}) => {
  let card;
  if (showCard) {
    card = <Image
    className={styles.cardImage}
    {...cardImage} />;
  }
  return (
    <div className={cx(styles.paymentMethod, className)}>
      <Text
        className={styles.label}
        {...label} />
      <div className={styles.capitalOneCard}>
        {card}
        <Text
          className={styles.description}
          {...description} />
      </div>
    </div>
  );
};

PaymentMethod.defaultProps = defaultProps;

export default PaymentMethod;
