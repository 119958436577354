import React from 'react';
import cx from 'classnames';

import styles from './PaymentInformationReadOnlyBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextItem, { TextItemProps } from '../../molecules/TextItem';
import PaymentMethod, { PaymentMethodProps } from '../../molecules/PaymentMethod';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  edit: {
    type: 'Text',
    style: 'Contained',
    size: 'Small',
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Right',
    },
  } as ButtonProps,
  address: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  phoneNumber: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  paymentMethod: {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  } as PaymentMethodProps,
  rewards: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
};

export type PaymentInformationReadOnlyBlockProps = {
  blockTitle?: TextProps;
  edit?: ButtonProps;
  address?: TextItemProps;
  phoneNumber?: TextItemProps;
  paymentMethod?: PaymentMethodProps;
  rewards?: TextItemProps;
  className?: string;
};

const PaymentInformationReadOnlyBlock: React.FC<PaymentInformationReadOnlyBlockProps> = ({
  blockTitle,
  edit,
  address,
  phoneNumber,
  paymentMethod,
  rewards,
  className,
}) => {
  return (
    <div className={cx(styles.paymentInformationReadOnlyBlock, className)}>
      <div className={styles.topContent}>
        <Text
          className={styles.blockTitle}
          {...blockTitle} />
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.leftContent}>
          <TextItem
            className={styles.address}
            {...address} />
          <TextItem
            className={styles.phoneNumber}
            {...phoneNumber} />
        </div>
        <div className={styles.rightContent}>
          <div className={styles.topLine}>
            <PaymentMethod
              className={styles.paymentMethod}
              {...paymentMethod} />
            <Button
              className={styles.edit}
              {...edit} />
          </div>
          <TextItem
            className={styles.rewards}
            {...rewards} />
        </div>
      </div>
    </div>
  );
};

PaymentInformationReadOnlyBlock.defaultProps = defaultProps;

export default PaymentInformationReadOnlyBlock;
