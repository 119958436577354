import React, { useRef, useState, useEffect } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { CustomerInfoBlockProps } from '../../blocks/CustomerInfoBlock';
import { StepperBlockProps } from '../../blocks/StepperBlock';
import { defaultProps as priceItemDefaultProps } from '../../molecules/PriceItem/PriceItem';
import { CheckoutManualPageProps, defaultProps } from './CheckoutManualPage';
import {
  BuildCheckoutProps,
  formatter,
  getCodeFromStep,
  getFormFromCode,
  OrderItems,
  updateStepInQuery,
} from './utils';
import { LocationState } from './CheckoutManualPage.interactor';
import { CardData, ListingDetails, ListingDetailsQueryParams, MarketingOptinPayload, MarketingOptinResponse, Order, OrderAddress, UserOrdersResponse } from '../../../modules/partnership';
import {
  PaymentInformationReadOnlyBlockProps,
  defaultProps as paymentInfoRODefaultProps,
} from '../../blocks/PaymentInformationReadOnlyBlock/PaymentInformationReadOnlyBlock';
import {
  OrderInfoProps,
  defaultProps as orderInfoDefaultProps,
} from '../../blocks/OrderInfo/OrderInfo';
import {
  LoyaltyBlockProps,
} from '../../blocks/LoyaltyBlock/LoyaltyBlock';
import { TicketAlertModalProps, defaultProps as ticketAlertModalDefaultProps } from '../../organisms/TicketAlertModal/TicketAlertModal';
import { CustomerInfoReadOnlyBlockProps } from '../../blocks/CustomerInfoReadOnlyBlock';
import { LegalBlockProps } from '../../blocks/LegalBlock';
import { ButtonProps } from '../../atoms/Button';
import { addQueryParam, CheckoutSteps, getFiveDigitsZipCode, getTicketListingTranslationKey, getTwoDigitsCountryCode, getUnitDisplaySettings, getUpdatedPhoneNumber, handleAmountFormatingString, handleDecimalValuesForDisplay, handleOrderTotalMiles, isValidPhoneNumber, RewardUnits, setUpdatedPhoneNumber, isValidEmail } from '../../../lib/util';
import { C1_BROKER_ID, INSURANCE_REJECTED_OPTION, INSURANCE_SELECTED_OPTION, TERMS_MODAL, US_STATE_CODES } from '../../../lib/constants';
import { CheckBoxItemStateType } from '../../atoms/CheckBoxItem/CheckBoxItem';
import {
  defaultProps as shippingInfoDefaultProps,
} from '../../blocks/ShippingInfoBlock/ShippingInfoBlock';
import { UnitDisplaySettings } from '../../../lib/types';
import { TextFieldsStateType } from '../../molecules/TextFields/TextFields';
import PaypalLogo from '../../../resources/icons/Paypal.svg';
import  { CustomerReviewProps, defaultProps as customerReviewDefaultProps } from '../../blocks/CustomerReview/CustomerReview';
import ExportAsset from '../../../resources/icons/Export.svg';

export type CheckoutManualPagePresenterProps = CheckoutManualPageProps & {
  step?: number;
  ticketInfo?: ListingDetails;
  quantity?: number;
  discountCode?: string;
  isUtm?: boolean;
  error?: Error;
  createOrder?: (order: Order) => Promise<Order>;
  marketingOptin?: (payload: MarketingOptinPayload) => Promise<MarketingOptinResponse>;
};

export const getLinkTranslation = (
  transKey: string,
  renderModal: (event?: React.MouseEvent<HTMLElement>) => void,
): React.ReactNode | undefined => {
  return (
    <Trans
      key={transKey}
      i18nKey={transKey}
      components={{
        img: <img src={ExportAsset} alt="" />,
        hyperlink: <Link onClick={renderModal} to={''} > </Link>,
      }}
    ></Trans>
  );
};

const withPresenter = (
  View: React.FC<CheckoutManualPageProps>,
): React.FC<CheckoutManualPagePresenterProps> => {
  const Presenter: React.FC<CheckoutManualPagePresenterProps> = (props) => {
    const { step, ticketInfo, quantity, discountCode, isUtm, error, createOrder, isLoading, marketingOptin } = props;
    const [currentCodeIsUTM, setCurrentCodeIsUtm] = useState(isUtm);

    const [orderLoading, setOrderLoading] = useState(false);
    const [emailInputState, setEmailInputState] = useState<TextFieldsStateType>('Default');
    const [emailInvalid, setEmailInvalid] = useState<boolean>(false);
    const [emailValue, setEmailValue] = useState<string>(window.sessionStorage.getItem('emailValue') || '');

    const [paypalPayment, setPaypalPayment] = useState<string>();
    const loadBillingAddress = window.sessionStorage.getItem('orderBillingAddress');
    const [orderBillingAddress, setOrderBillingAddress] = useState<OrderAddress>(
      loadBillingAddress ? JSON.parse(loadBillingAddress) : {});
    const loadShippingAddress = window.sessionStorage.getItem('orderShippingAddress');
    const [orderShippingAddress, setOrderShippingAddress] = useState<OrderAddress>(
      loadShippingAddress ? JSON.parse(loadShippingAddress) : {});
    const [placeOrderChecked, setPlaceOrderChecked] = useState(false);
    const loadCardData = window.sessionStorage.getItem('cardData');
    const [cardDetails, setCardDetails] = useState<CardData>(
      loadCardData ? JSON.parse(loadCardData) : {});
    
    const [showPromoError, setShowPromoError] = useState(!!discountCode);
    const [promoCodeTry, setPromoCodeTry] = useState(discountCode || '');
    const [promoCodeSet, setPromoCodeSet] = useState(discountCode || '');
    const [promoCodeFailed, setPromoCodeFailed] = useState(false);
    const [insuranceAccepted, setInsuranceAccepted] = useState(false);
    const [insuranceSelected, setInsuranceSelected] = useState(false);
    const [insuranceError, setInsuranceError] = useState(false);
    const giftCardValue = (ticketInfo?.pricing?.giftCards || [])[0]?.amount;

    useEffect(() => {
      if (ticketInfo?.pricing.promo?.promoValid || 
        ticketInfo && ticketInfo.pricing && 
        ticketInfo?.pricing?.giftCards && ticketInfo?.pricing?.giftCards[0].applied) {
        // success
        setPromoCodeFailed(false);
        setPromoCodeTry(''); 
      } else {
        setPromoCodeFailed(showPromoError);
      }
    }, [ticketInfo, showPromoError]);
    useEffect(() => {
      // load the ioBlackBox library
      const initialScript = document.createElement('script');
      initialScript.innerHTML = "var io_bbout_element_id = 'ioBlackBox'; var io_install_stm = false; var io_install_flash = false; var io_exclude_stm = 12;";
      const accertifyScript = document.createElement('script');
      accertifyScript.setAttribute('src', 'https://mpsnare.iesnare.com/snare.js');
      document.head.appendChild(initialScript);
      document.head.appendChild(accertifyScript); return () => { document.head.removeChild(initialScript); document.head.removeChild(accertifyScript); };
    }, []);
    const { t } = useTranslation();
    let validatedStep = step;
    const state = ticketInfo?.event?.venue?.state_code;
    const isInNewYork: boolean = state === 'NY';
    const isInUtah: boolean = state === 'UT';
    const isInPennsylvania: boolean = state === 'PA';
    const hardTicket: boolean = ticketInfo && ticketInfo.listing
      ? t(getTicketListingTranslationKey(ticketInfo, 'requiresShippingInfo')) === 'true'
      : false;
    const totalSteps = hardTicket ? 5 : 4;
    const confirmationPath = '/confirmation';
    const [cardDisabled, setCardDisabled] = useState(false);
    const location = useLocation<LocationState>();
    const history = useHistory();
    const [isTicketAlertModalOpen, setIsTicketAlertModalOpen] = useState(false);

    const renderModal = (modalType: string): void => {
      const params = addQueryParam(history.location.search, {
        modal: modalType,
      });

      if (params) {
        history.push({
          search: params,
        });
      }
    };

    const openModal = (e?: React.MouseEvent<HTMLElement>) => {
      e?.preventDefault();
      renderModal(TERMS_MODAL);
    };

    const lastCompleted: number = +(
      window.sessionStorage.getItem('lastCompleted') || 0
    );
    const rewardType = 'account?.loyalty_program.loyalty_unit_name.toLowerCase()';
    const { rewardSign, useDecimals }: UnitDisplaySettings = getUnitDisplaySettings(rewardType);
    const hasCp1BrokerId = false;

    // if we are beyond page one, make sure they have submitted the last page
    if (!!step && step > 1 && step > lastCompleted + 1) {
      // if not, then stay in last completed page
      const backStep = lastCompleted + 1;
      validatedStep = backStep;
    }
    if (validatedStep === 1) {
      window.sessionStorage.clear();
    }
    // has to be set after clearing otherwise email never resets
    const [paypalEmail, setPaypalEmail] = useState<string>(window.sessionStorage.getItem('paypalEmail') || '');
    const {
      customerBasicProps,
      loyaltyBasicProps,
      legalBasicProps,
      customerInfoROBascProps,
      paymentInfoROBascProps,
      stepperBasicProps,
      ticketBasicProps,
      orderErrorBlockBasicProps,
    } = BuildCheckoutProps(
      ticketInfo || null,
      hardTicket,
      false,
      false,
      useDecimals || false,
      rewardSign || '',
      emailInvalid,
      isInNewYork,
      !!discountCode,
    );


    // Payment logic
    const ioBlackBoxRef = useRef<HTMLInputElement>(null);
    const [orderItems, setOrderItems] = useState<OrderItems>();

    const validateEmailField = () => {
      // checks if it has a value but is invalid
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const invalid = !!emailValue && !isValidEmail(emailValue);
      setEmailInvalid(invalid);
      // invalid or no value results in error
      const emailError = !emailValue || invalid;
      setEmailInputState(emailError ? 'Error' : 'Default');
      return emailError;
    };

    const defaultButtonAction = async () => {
      if (orderBillingAddress?.region === US_STATE_CODES[0]?.code) {
        setOrderBillingAddress({ ...orderBillingAddress, region : '' });
      }
      if (orderShippingAddress?.region === US_STATE_CODES[0]?.code) {
        setOrderShippingAddress({ ...orderShippingAddress, region : '' });
      }
      if (validatedStep && validatedStep === 1) {
        if (validateEmailField()) {
          setEmailInputState('Error');
          return;
        }
        window.sessionStorage.setItem('emailValue', emailValue);
        void marketingOptin?.({ email: emailValue });
      }
      if (getCodeFromStep(validatedStep || 1, hardTicket) === CheckoutSteps.CONFIRMATION
        && !insuranceSelected) {
        setInsuranceError(true);
        return;
      }
      // clear failed promo codes
      if (!ticketInfo?.pricing.promo?.promoValid && !giftCardValue) {
        history.push(addQueryParam(history.location.search, { discount_code: '' }));
        setShowPromoError(false);
        setPromoCodeTry('');
      }
      if (
        validatedStep &&
        validatedStep >= totalSteps &&
        createOrder && cardDetails &&
        orderBillingAddress && (orderShippingAddress || !hardTicket)
      ) {
        try {
          // make an api call to check the validation
          const requestParams: Order = {
            id: 0,
            billing: {
              payment_nonce: (paypalPayment ? paypalPayment :
                cardDetails?.payload?.nonce) || '',
              is_default: false,
              billing_address: orderBillingAddress,
              payer_email_address: paypalEmail ?? '',
            },
            account: {
              email_address: emailValue,
              first_name: orderBillingAddress.first_name,
              last_name: orderBillingAddress.last_name,
              marketing_optin: false,
              phone_number: orderBillingAddress.phone_number,
            },
            assumed_price_per: ticketInfo?.listing.price_per || 0,
            delivery_id: ticketInfo?.pricing.delivery.id || 0,
            insurance: {
              declined: !insuranceAccepted,
              selected_insurance_option: insuranceAccepted ? ticketInfo?.pricing?.insurance_offer?.options[0].quote_id || '' : '',
              insurance_offer: ticketInfo?.pricing?.insurance_offer,
            },
            loyalty: undefined,
            offer: undefined,
            override_order_email: false,
            payment_method: undefined,
            io_black_box:
              cardDetails?.ioBlackBox || ioBlackBoxRef.current?.value || '',
            production_id: ticketInfo?.listing.event_id || 0,
            quantity: ticketInfo?.pricing.quantity || 0,
            quote_id: ticketInfo?.pricing.id || '',
            redeem: {
              amount: 0, 
              loyalty_unit_name: 'Miles',
            },
            order_total: {
              amount: ticketInfo?.pricing?.total || 0,
              currency: 'USD',
            },
            ticket_id: ticketInfo?.listing.id || '',
            exclusive_listings: hasCp1BrokerId,
            discount_code: promoCodeSet,
            is_utm_promo: currentCodeIsUTM,
          };

          if (Object.keys(orderShippingAddress).length !== 0){
            requestParams.shipping_address = orderShippingAddress;
          }

          setOrderLoading(true);
          const order = await createOrder(requestParams);
          const currentOrderItems: OrderItems = {
            order: order,
            quantity: quantity,
            rewardsUsed: 0,
            dollarsUsed: ticketInfo?.pricing?.total || 0,
            isRewardsOnly: false,
            isCp1Exclusive: false,
            listing: ticketInfo,
            email: emailValue,
            billingAddress: orderBillingAddress,
            cardType: cardDetails?.payload?.details?.cardType,
            lastFour: cardDetails?.payload?.details?.lastFour,
            paypalEmail: paypalEmail,
            insurancePurchased: insuranceAccepted,
          };
          setOrderItems(currentOrderItems);
          setOrderLoading(false);
          history.push(confirmationPath, { currentOrderItems });
        } catch {
          // TODO go to error page
        }
      
      } else {
        window.sessionStorage.setItem(
          'lastCompleted',
          (validatedStep || 0).toString(),
        );
        history.push(
          updateStepInQuery((validatedStep || 0) + 1, history.location.search),
          { location },
        );
      }
    };
    // All prop declarations
    const ticketAlertModalProps: TicketAlertModalProps = {
      ...ticketAlertModalDefaultProps,
      open: isTicketAlertModalOpen,
      closeButton: {
        ...ticketAlertModalDefaultProps.closeButton,
        onButtonClicked: () => setIsTicketAlertModalOpen(false),
      },
      title: {
        ...ticketAlertModalDefaultProps.title,
        value: t('purchaseLimitError.title'),
      },
      message: {
        ...ticketAlertModalDefaultProps.message,
        value: t('purchaseLimitError.message'),
      },
      primaryButton: {
        ...ticketAlertModalDefaultProps.primaryButton,
        text: {
          ...ticketAlertModalDefaultProps.primaryButton?.text,
          value: t('insufficientRewards.button'),
        },
        onButtonClicked: () => setIsTicketAlertModalOpen(false),
      },
    };

    // TODO move definitions into CustomerInfoBlock VIPER
    const customerInfoProps: CustomerInfoBlockProps = {
      ...customerBasicProps,
      emailField: {
        ...customerBasicProps.emailField,
        state: emailInputState,
        textInput: {
          ...customerBasicProps?.emailField?.textInput,
          textPlaceholder: '',
          state: 'Empty',
          onTextChanged: (input) => {
            if (input.target.value) {
              setEmailInputState('Default');
            }
            setEmailValue(input.target.value);
          },
          textValue: emailValue,
        },
      },
      button: {
        ...defaultProps.customerInfoBlock.button,
        text: {
          ...defaultProps.customerInfoBlock.button?.text,
          value: t('customerInfo.button'),
        },
        onButtonClicked: defaultButtonAction,
      },
    };

    // TODO move definitions into BillingInfoBlock VIPER
  
    // TODO move definitions into LoyaltyBlock VIPER
    // by default, set slider to half or as far as possible
    const loyaltyProps: LoyaltyBlockProps = {
      ...loyaltyBasicProps,
    };

    const stepperBlockProps: StepperBlockProps = {
      ...stepperBasicProps,
    };
    // TODO move definitions into OrderInfo VIPER
    const ticketInfoProps: OrderInfoProps = {
      ...ticketBasicProps,
      costBreakdown: {
        ...ticketBasicProps.costBreakdown,
        showPromoFeature: getCodeFromStep(validatedStep || 1, hardTicket) !== CheckoutSteps.CONFIRMATION,
        promoCodeError: promoCodeFailed,
        promoInput: {
          ...ticketBasicProps.costBreakdown?.promoInput,
          textValue: promoCodeTry,
          onTextChanged: (e) => {
            setPromoCodeTry(e?.target?.value);
            setShowPromoError(false);
          },
        },
        promoError: {
          ...ticketBasicProps.costBreakdown?.promoError,
          value: t('ticketInfo.orderTotal.promoError'),
        },
        applyButton: {
          ...ticketBasicProps.costBreakdown?.applyButton,
          onButtonClicked: () => {
            history.push(addQueryParam(history.location.search, currentCodeIsUTM ?
              { utm_promo: '', discount_code: promoCodeTry } : { discount_code: promoCodeTry }));
            setCurrentCodeIsUtm(false);
            setShowPromoError(true);
            setPromoCodeSet(promoCodeTry);
          },
        },
        addedPromoLabel: {
          ...ticketBasicProps.costBreakdown?.serviceFee,
          style: 'Link',
          item: {
            ...ticketBasicProps.costBreakdown?.serviceFee?.item,
            value: !promoCodeFailed && showPromoError ? 
              promoCodeSet : '',
          },
          price: {
            ...ticketBasicProps.costBreakdown?.serviceFee?.price,
            value: t('ticketInfo.orderTotal.discountWithCurrencyTemplate', {
              charge: formatter.format(ticketInfo?.pricing?.promo?.promoDiscount ||
                giftCardValue || 0),
              currency: ticketInfo?.pricing.currency,
            }),
          },
          closeButton: {
            ...priceItemDefaultProps.closeButton,
            onButtonClicked: () => {
              history.push(addQueryParam(history.location.search, currentCodeIsUTM ?
                { discount_code: '', utm_promo: '' } : { discount_code: '' }));
              setPromoCodeTry('');
              setPromoCodeSet('');
              setCurrentCodeIsUtm(false);
              setShowPromoError(false);
            },
          },
        },
        addedDiscount: ticketInfo?.pricing.promo?.promoValid || !!giftCardValue ? {
          ...ticketBasicProps.costBreakdown?.serviceFee,
          item: {
            ...ticketBasicProps.costBreakdown?.serviceFee?.item,
            value: t('ticketInfo.orderTotal.addedDiscount'),
          },
          price: {
            ...ticketBasicProps.costBreakdown?.serviceFee?.price,
            value: t('ticketInfo.orderTotal.discountNoCurrencyTemplate', {
              charge: handleDecimalValuesForDisplay(formatter.format(ticketInfo?.pricing.promo?.promoDiscount ||
                giftCardValue || 0), useDecimals),
            }),
          },
        } : {},
      },
      showOrderDetails: (validatedStep || 1) !== 1 || isInPennsylvania || isInUtah,
      faceValue: getCodeFromStep(validatedStep || 1, hardTicket) !== CheckoutSteps.CUSTOMERINFO ?
        { ...ticketBasicProps.faceValue } : {},
      amount: {
        ...orderInfoDefaultProps.amount,
        value: t('ticketInfo.orderTotal.chargeWithCurrencyTemplate', {
          charge: formatter.format(ticketInfo?.pricing.total || 0),
          currency: ticketInfo?.pricing.currency,
        }),
      },
      seatInfo: {
        ...ticketBasicProps.seatInfo,
        quantity: {
          ...ticketBasicProps.seatInfo?.quantity,
          description: {
            ...ticketBasicProps.seatInfo?.quantity?.description,
            value: quantity,
          },
        },
      },
    };

    const customerReviewProps: CustomerReviewProps = {
      ...customerReviewDefaultProps,
      blockTitle: {
        ...customerReviewDefaultProps.blockTitle,
        value: t('CustomerReview.title'),
      },
      starItems: [ 
        { ...customerReviewDefaultProps.starItem, 
          noOfStar: 5,
          reviewDescription: {
            ...customerReviewDefaultProps?.starItem?.reviewDescription,
            value: t('CustomerReview.reviewDescription1'),
          },
          reviewerName: {
            ...customerReviewDefaultProps?.starItem?.reviewerName,
            value: t('CustomerReview.reviewerName1'),
          },
        },
        { ...customerReviewDefaultProps.starItem, 
          noOfStar: 5,
          reviewDescription: {
            ...customerReviewDefaultProps?.starItem?.reviewDescription,
            value: t('CustomerReview.reviewDescription2'),
          },
          reviewerName: {
            ...customerReviewDefaultProps?.starItem?.reviewerName,
            value: t('CustomerReview.reviewerName2'),
          },
        },
        { ...customerReviewDefaultProps.starItem, 
          noOfStar: 5,
          reviewDescription: {
            ...customerReviewDefaultProps?.starItem?.reviewDescription,
            value: t('CustomerReview.reviewDescription3'),
          },
          reviewerName: {
            ...customerReviewDefaultProps?.starItem?.reviewerName,
            value: t('CustomerReview.reviewerName3'),
          },
        },
      ],
    };

    const customerInfoROProps: CustomerInfoReadOnlyBlockProps = {
      ...customerInfoROBascProps,
      textItem: {
        ...customerInfoROBascProps.textItem,
        description: {
          ...customerInfoROBascProps.textItem?.description,
          value: emailValue,
        },
      },
    };
    const shippingInfoROProps: CustomerInfoReadOnlyBlockProps = {
      ...customerInfoROBascProps,
      blockTitle: {
        ...customerInfoROBascProps.blockTitle,
        value: t('shippingInfo.title'),
      },
      textItem: {
        ...customerInfoROBascProps.textItem,
        label: {
          ...paymentInfoRODefaultProps.address.label,
          value: t('shippingInfo.address'),
        },
        description: {
          ...paymentInfoRODefaultProps.address.description,
          value: t('billingInfo.smallVersionTemplate', {
            firstName: orderShippingAddress?.first_name,
            lastName: orderShippingAddress?.last_name,
            address: orderShippingAddress?.address_line_1,
            city: orderShippingAddress?.city,
            state: orderShippingAddress?.region,
            postalCode: orderShippingAddress.postal_code ? getFiveDigitsZipCode(orderShippingAddress.country, orderShippingAddress.postal_code) : '',
            country: getTwoDigitsCountryCode(orderShippingAddress?.country || ''),
          }),
        },
      },
    };
    const paymentInfoROProps: PaymentInformationReadOnlyBlockProps = {
      ...paymentInfoROBascProps,
      edit: {
        ...paymentInfoROBascProps.edit,
        onButtonClicked: () => {
          history.push(
            updateStepInQuery((validatedStep || 0) - 1, history.location.search),
            { location },
          );
          window.sessionStorage.removeItem('paypalEmail');
          setPaypalPayment('');
          setPaypalEmail('');
        },   
      },
      phoneNumber: {
        ...paymentInfoROBascProps.phoneNumber,
        description: {
          ...paymentInfoROBascProps.phoneNumber?.description,
          value: orderBillingAddress?.phone_number,
        },
      },
      address: {
        ...paymentInfoRODefaultProps.address,
        label: {
          ...paymentInfoRODefaultProps.address.label,
          value: t('billingInfo.title'),
        },
        description: {
          ...paymentInfoRODefaultProps.address.description,
          value: t('billingInfo.smallVersionTemplate', {
            firstName: orderBillingAddress?.first_name,
            lastName: orderBillingAddress?.last_name,
            address: orderBillingAddress?.address_line_1,
            city: orderBillingAddress?.city,
            state: orderBillingAddress?.region,
            postalCode: orderBillingAddress.postal_code ? getFiveDigitsZipCode(orderBillingAddress.country, orderBillingAddress.postal_code) : '',
            country: getTwoDigitsCountryCode(orderBillingAddress?.country || ''),
          }),
        },
      },
      paymentMethod: {
        ...paymentInfoROBascProps.paymentMethod,
        description: {
          ...paymentInfoROBascProps.paymentMethod?.description,
          value: paypalEmail ?  t('billingInfo.paypalPaymentTemplate', {
            email: paypalEmail,
            cost: formatter.format(ticketInfo?.pricing.total || 0),
            currency: ticketInfo?.pricing.currency,
          }) : t('billingInfo.paymentTemplate', {
            cardNumber: cardDetails?.payload?.details.lastFour,
            cost: formatter.format(ticketInfo?.pricing.total || 0),
            currency: ticketInfo?.pricing.currency,
          }),
        },
        showCard: !!paypalEmail,
        cardImage: {
          srcValue: PaypalLogo,
        },
      },
      rewards: ticketInfo?.pricing.promo?.promoValid || !!giftCardValue ? {
        ...paymentInfoROBascProps.paymentMethod,
        label: {
          ...paymentInfoROBascProps.paymentMethod?.label,
          value: t('ticketInfo.orderTotal.addedDiscount'),
        },
        description: {
          ...paymentInfoROBascProps.paymentMethod?.description,
          value: t('ticketInfo.orderTotal.discountNoCurrencyTemplate', {
            charge: handleDecimalValuesForDisplay(formatter.format(ticketInfo?.pricing.promo?.promoDiscount || 
              giftCardValue || 0), useDecimals),
          }),
        },
      } : {},
    };
    const legalProps: LegalBlockProps = {
      ...legalBasicProps,
      showCheckBox: isInNewYork,
      checkBoxItem: {
        ...legalBasicProps.checkBoxItem,
        state: placeOrderChecked ? 'Selected' : 'Unselected',
        onCheckBoxItemClicked: (e: CheckBoxItemStateType | undefined) => {
          setPlaceOrderChecked(e === 'Selected');
        },
      },
      description: {
        ...legalBasicProps.description,
        value: [
          getLinkTranslation('legal.descriptionBase', openModal),
          isInNewYork ? t('legal.newYorkDescription') : '',
        ],
      },
      bottomDescription: {
        ...legalBasicProps.bottomDescription,
        value: 
          t('legal.salesInformation', {
            charge: formatter.format(ticketInfo?.pricing.total || 0),
          }),
      },
      button: {
        ...legalBasicProps.button,
        isDisable: isInNewYork && !placeOrderChecked,
        text: {
          ...defaultProps.customerInfoBlock.button?.text,
          value: t('legal.button'),
        },
        onButtonClicked: defaultButtonAction,
      },
    };
    const mobileButton: ButtonProps = {
      ...defaultProps.customerInfoBlock.button,
      isDisable: isInNewYork && !placeOrderChecked && 
          getCodeFromStep(validatedStep || 1, hardTicket) === CheckoutSteps.CONFIRMATION,
      text: {
        ...defaultProps.customerInfoBlock.button?.text,
        value: t('customerInfo.button'),
      },
      onButtonClicked: (e) => {
        const code = getCodeFromStep(validatedStep || 1, hardTicket);
        if (validatedStep && validatedStep !== totalSteps - 1
          && code !== CheckoutSteps.SHIPPINGINFO 
          && code !== CheckoutSteps.BILLINGINFO) {
          void defaultButtonAction();
        }
        if (code === CheckoutSteps.CUSTOMERINFO) {
          e?.preventDefault();
        }
      },
    };

    if (getCodeFromStep(validatedStep || 1, hardTicket) === CheckoutSteps.PAYMENTINFO && mobileButton.text) {
      mobileButton.text.value = t('cardInfo.button');
      mobileButton.style = 'Contained';
    } else if (getCodeFromStep(validatedStep || 1, hardTicket) === CheckoutSteps.CONFIRMATION && mobileButton.text) {
      mobileButton.text.value = t('legal.button');
      mobileButton.style = 'ContainedGreen';
    }

    const checkoutManualPageProps: CheckoutManualPageProps = {
      ...defaultProps,
      customerInfoBlock: customerInfoProps,
      customerInfoReadOnly: customerInfoROProps,
      shippingInfoReadOnly: shippingInfoROProps,
      paymentInfoReadOnly: paymentInfoROProps,
      loyaltyBlock: loyaltyProps,
      legalBlock: legalProps,
      stepperBlock: stepperBlockProps,
      ticketInfo: ticketInfoProps,
      customerReview: customerReviewProps,
      insuranceWidget: ticketInfo?.pricing?.insurance_offer?.insurance_offer_html || '',
      insuranceError: insuranceError ? {
        ...defaultProps.insuranceError,
        value: t('orderConfirmation.insuranceError'),
      } : {},
      onInsuranceSelection: (option) => {
        setInsuranceAccepted(option === INSURANCE_SELECTED_OPTION);
        const selectionMade = [INSURANCE_REJECTED_OPTION, INSURANCE_SELECTED_OPTION].includes(option);
        setInsuranceSelected(selectionMade);
        setInsuranceError(!selectionMade);
      },
      shippingInfoBlock: {
        ...shippingInfoDefaultProps,
        blockTitle: {
          ...shippingInfoDefaultProps.blockTitle,
          value: getCodeFromStep(validatedStep || 1, hardTicket) === CheckoutSteps.BILLINGINFO ?
            t('billingInfo.title') : t('shippingInfo.address'),
        },
        isBillingStep: getCodeFromStep(validatedStep || 1, hardTicket) === CheckoutSteps.BILLINGINFO,
        billingInfo: orderBillingAddress,
      },
      step: validatedStep,
      isRewardsOnly: false,
      button: mobileButton,
      ticketAlertModalProps: ticketAlertModalProps,
      onFormSubmit: async (e) => {
        if (!e) {
          return;
        }
        const code = getCodeFromStep(validatedStep || 1, hardTicket);
        // save billing and shipping info for later
        switch (code) {
          case CheckoutSteps.BILLINGINFO:
            setOrderBillingAddress(e);
            window.sessionStorage.setItem('orderBillingAddress', JSON.stringify(e));
            break;
          case CheckoutSteps.SHIPPINGINFO:
            setOrderShippingAddress(e);
            window.sessionStorage.setItem('orderShippingAddress', JSON.stringify(e));
            break;
          default:
            break;
        }
        if (ioBlackBoxRef?.current) {
          const details = {
            payload: undefined,
            ioBlackBox: ioBlackBoxRef?.current?.value,
          };
          setCardDetails(details);
          window.sessionStorage.setItem('cardData', JSON.stringify(details));
        }
        await defaultButtonAction();
        return false;
      },
      onCardTokenized: async (payload, hasValidationError) => {
        if (hasValidationError) {
          return;
        }
        const details = { payload, ioBlackBox: ioBlackBoxRef?.current?.value || null };
        setCardDetails(details);
        window.sessionStorage.setItem('cardData', JSON.stringify(details));
        await defaultButtonAction();
      },
      orderErrorBlock: orderErrorBlockBasicProps,
      error: error,
      orderTotal: ticketInfo?.pricing?.total || 0,
      onPaypalSuccess: async (data) => {
        setPaypalEmail(data.details.email);
        setPaypalPayment(data.nonce);
        window.sessionStorage.setItem('paypalEmail', data.details.email_address);
        await defaultButtonAction();
      },
      mobileMessage: {
        ...customerBasicProps.message,
        value: getCodeFromStep(validatedStep || 1, hardTicket) === CheckoutSteps.CUSTOMERINFO ? 
          t('customerInfo.message') : '',
      },
      cardDisabled,
      currentForm: getFormFromCode(getCodeFromStep(validatedStep || 1, hardTicket), false),
      hardTicket: hardTicket,
      isUTOrPA: isInPennsylvania || isInUtah,
      ioBlackBoxRef: ioBlackBoxRef,
      isLoading: isLoading || orderLoading,
    };
    return <View {...props} {...checkoutManualPageProps} />;
  };
  return Presenter;
};

export default withPresenter;
