import React from 'react';
import ReactSlider from 'react-slider';
import cx from 'classnames';

import styles from './Slider.module.scss';

type SliderType = 'Default' | 'Dual' | undefined;

export const defaultProps = {
  maxPoints: 100,
  sliderValue: 50,
  type: 'Default' as SliderType,
};

export const inRange = (x: number, range: number[]) => {
  return (x - range[0]) * (x - range[1]) <= 0;
};

export type SliderProps = {
  maxPoints?: number;
  minPoints?: number;
  onSliderMoved?: (value: number) => void;
  sliderValue?: number;
  className?: string;
  type?: SliderType;
  dualSliderValue?: number[];
  onDualSliderMoved?: (value: number | number[]) => void;
  onAfterChange?: VoidFunction;
  step?: number;
};

const Slider: React.FC<SliderProps> = ({
  maxPoints,
  minPoints,
  onSliderMoved,
  sliderValue,
  className,
  type,
  dualSliderValue,
  onDualSliderMoved,
  onAfterChange,
  step = 1,
}) => {
  let sliderView;
  if (type === 'Dual') {
    sliderView =
      <ReactSlider
        className={styles.dualSlider}
        thumbClassName={styles.thumb}
        trackClassName={cx(styles.dualTrack)}
        value={dualSliderValue}
        min={minPoints}
        max={maxPoints}
        step={step}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        ariaLabel={['Leftmost thumb', 'Rightmost thumb']}
        onAfterChange={onAfterChange}
        onChange={(value) => {
          if (value && onDualSliderMoved) {
            onDualSliderMoved(value);
          }
        }}
      />;
  } else {
    sliderView = <ReactSlider
      className={styles.slider}
      thumbClassName={styles.thumb}
      trackClassName={cx(styles.track)}
      value={(sliderValue ?? (maxPoints || 1) / 2)}
      min={0}
      max={maxPoints}
      step={step}
      ariaLabel={'thumb'}
      ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
      onChange={(value) => {
        if (value != null && onSliderMoved) {
          onSliderMoved(value);
        }
      }}
    />;
  }
  return (
    <div className={cx(styles.slider, className)}>
      {sliderView}
    </div>
  );
};

Slider.defaultProps = defaultProps;

export default Slider;
