import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UserAccount } from '../../../modules/navigation/types';
import { defaultProps as defaultTabProps, TabProps } from '../../atoms/Tab/Tab';
import { defaultProps as defaultEventDetailsModalProps } from '../../organisms/EventDetailsModal/EventDetailsModal';
import { TopNavProps, defaultProps, TopNavTypeType } from './TopNav';
import { useTranslation } from 'react-i18next';
import {
  NavMenuItemProps,
  defaultProps as defaultNavMenuItemProps,
} from '../../molecules/NavMenuItem//NavMenuItem';
import { Event } from '../../../modules/partnership/types';
import { setEventDescription } from '../../../lib/util';
import { CP1_EXCLUSIVE_TAG } from '../../../lib/constants';
import { SEARCH_ICON_ON_TAB_BAR } from './util';

export type TopNavPresenterProps = TopNavProps & {
  user?: UserAccount;
  currentEvent?: Event;
  hasNoEventMap?: boolean;
  hasEventDescription?: string;
  hasEventGuestList?: boolean;
  isSoldOutEvent?:boolean;
};

const getTopNavType = (pathname: string): TopNavTypeType => {
  const pathnameLowerCase = pathname.toLowerCase();
  // Homepage
  if (pathnameLowerCase === '/' || pathnameLowerCase.startsWith('/search')) {
    return 'WithoutSearchInNav';
  }
  if (pathnameLowerCase.startsWith('/events/')) {
    return 'TicketSeatSelection';
  }
  if (pathnameLowerCase.startsWith('/help')) {
    return 'SignedOut';
  }
  return 'SearchInNav';
};

const withPresenter = (
  View: React.FC<TopNavProps>,
): React.FC<TopNavPresenterProps> => {
  const Presenter: React.FC<TopNavPresenterProps> = (props) => {
    const { t } = useTranslation();
 
    const blockProps: TopNavProps = {
      ...defaultProps,
      phoneNumber: {
        ...defaultProps.label,
        value: t('topnav.phoneNumber'),
      },
      label: {
        ...defaultProps.label,
        value: t('topnav.label'),
      },
      message: {
        ...defaultProps.message,
        value: t('topnav.disclaimer'),
      },
      mobileMessage: {
        ...defaultProps.message,
        value: t('topnav.mobileDisclaimer'),
      },
      phoneIcon: {
        asset: 'Phone',
      },
      logo: {
        ...defaultProps.logo,
      },
      widget: {
        asset: 'ShopperApprovedSeal',
        linkPath: 'https://www.shopperapproved.com/reviews/ticketsonsale.com/',
        newTab: true,
      },
    };

    return <View {...props} {...blockProps} />;

  };
  return Presenter;
};

export default withPresenter;
