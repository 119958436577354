import React from 'react';
import cx from 'classnames';

import styles from './Button.module.scss';

import Text, { TextProps } from '../Text';
import Icon, { IconProps } from '../Icon';
import { Link } from 'react-router-dom';
import { isValidUrl, seprateUrlAndQuery } from '../../../lib/util';

export type ButtonTypeType = 'Text' | 'TextIcon' | 'IconText' | 'Icon';
export type ButtonStyleType = 'Contained' | 'ContainedGreen' | 'Outline' | 'OutlinePill' | 'TextGrey' | 'Text' | 'TextWhite' | 'TextDark' | 'TextUnderline' | 'ContainedWhite';
export type ButtonSizeType = 'Large' | 'Medium' | 'Small' | 'ExtraSmall';
export type ButtonButtonTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  type: 'Icon' as ButtonTypeType,
  style: 'Text' as ButtonStyleType,
  size: 'Medium' as ButtonSizeType,
  text: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'BaseLight',
    align: 'Left',
  } as TextProps,
  icon: {
    asset: 'CloseCircle',
    style: 'White',
  } as IconProps,
};

export type ButtonProps = {
  type?: ButtonTypeType;
  style?: ButtonStyleType;
  size?: ButtonSizeType;
  buttonType?: ButtonButtonTypeType;
  onButtonClicked?: (event?: React.MouseEvent<HTMLElement>) => void;
  text?: TextProps;
  className?: string;
  icon?: IconProps;
  linkPath?: string;
  newTab?: boolean;
  form?: string;
  focusable?: boolean;
  isDisable?: boolean;
  ariaLabel?: string;
  ariaLabelledBy?: string;
};

const Button: React.FC<ButtonProps> = ({
  type,
  style,
  size,
  buttonType,
  onButtonClicked,
  text,
  className,
  icon,
  linkPath,
  newTab,
  form = '',
  focusable = true,
  isDisable,
  ariaLabel = '',
  ariaLabelledBy = '',
}) => {

  const currentStyle = styles[`button${type}${style}${size}`];

  let textView;
  let contentView;
  let iconView;

  switch (type) {
    case 'Text':
      textView = (
        <Text
          arialabel={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          className={styles.text}
          {...text} />
      );
      break;
    case 'TextIcon':
      contentView = (
        <div className={styles.content}>
          <Text
            arialabel={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            className={styles.text}
            {...text} />
          <Icon
            className={styles.icon}
            {...icon} />
        </div>
      );
      break;
    case 'IconText':
      contentView = (
        <div className={styles.content}>
          <Icon
            className={styles.icon}
            {...icon} />
          <Text
            arialabel={ariaLabel}
            aria-labelledby={ariaLabelledBy}
            className={styles.text}
            {...text} />
        </div>
      );
      break;
    case 'Icon':
      iconView = (
        <Icon
          className={styles.icon}
          {...icon} />
      );
      break;
  }
  if (linkPath) {
    if (isValidUrl(linkPath)) {
      return (
        <a
          target={newTab ? '_blank' : '_self'}
          rel='noreferrer'
          tabIndex={focusable ? 0 : -1}
          href={linkPath}
          className={cx(currentStyle, styles.linkPath, className)}>
          {textView}
          {contentView}
          {iconView}
        </a>
      );
    }
    const { queryParams, path } = seprateUrlAndQuery(linkPath);

    return (
      <Link
        tabIndex={focusable ? 0 : -1}
        to={{ pathname: path, search: queryParams, state: { canGoBack: true } }}
        onClick={onButtonClicked}
        className={cx(currentStyle, styles.linkPath, className)}>
        {textView}
        {contentView}
        {iconView}
      </Link>
    );
  }
  if (onButtonClicked || form) {
    return (
      <button
        tabIndex={focusable ? 0 : -1}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        type={buttonType}
        form={form}
        disabled={isDisable}
        onClick={onButtonClicked}
        className={cx(currentStyle, className)}>
        {textView}
        {contentView}
        {iconView}
      </button>
    );
  }

  return (
    <div
      className={cx(currentStyle, className)}>
      {textView}
      {contentView}
      {iconView}
    </div>
  );

};

Button.defaultProps = defaultProps;

export default Button;
