import React from 'react';
import cx from 'classnames';

import styles from './ErrorPage.module.scss';

import ErrorBlock, { ErrorBlockProps } from '../../blocks/ErrorBlock';

export const defaultProps = {
  errorBlock: {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    message: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Center',
    },
    button: {
      type: 'Text',
      style: 'Contained',
      size: 'Large',
      text: {
        type: 'Subheading',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseLight',
        align: 'Left',
      },
    },
  } as ErrorBlockProps,
};

export type ErrorPageProps = {
  errorBlock?: ErrorBlockProps;
  className?: string;
};

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorBlock,
  className,
}) => {
  return (
    <div className={cx(styles.errorPage, className)}>
      <div className={styles.container}>
        <ErrorBlock
          className={styles.errorBlock}
          {...errorBlock} />
      </div>
    </div>
  );
};

ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
