import React from 'react';
import cx from 'classnames';
import styles from './CustomerReview.module.scss';
import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';

export type StarItem = {
  icon?: IconProps,
  reviewDescription?: TextProps,
  reviewerName?: TextProps,
  noOfStar?: number,
};

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
  } as TextProps,
  
  starItem: {
    icon: {
      asset: 'Star',
    } as IconProps,
    reviewDescription: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    } as TextProps,
    reviewerName: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    } as TextProps,
  } as StarItem,
};

export type CustomerReviewProps = {
  blockTitle?: TextProps;
  starItems?: StarItem[];
};

const starItem = ({ icon, reviewDescription, reviewerName, noOfStar }: StarItem, index: number) => {
  return (
    <li key={index}>
      <div className={styles.starItem}>
        {[...Array(noOfStar)].map((_, i) =>
            <Icon key={i} {...icon} />,
        )}
       </div>
       <Text
        customHeadingClass={styles.reviewDescription}
        {...reviewDescription} />
         <Text
        customHeadingClass={styles.reviewerName}
        {...reviewerName} />
    </li>
  );
};

const CustomerReview: React.FC<CustomerReviewProps> = ({ blockTitle, starItems }) => {

  return (
    <div className={styles.customerReviewContainer}>
      <Text
        customHeadingClass={styles.blockTitle}
        {...blockTitle} />

      <ul className={styles.starItemContainer}>
        { starItems?.map((startItem, i)=>starItem(startItem, i)) }
      </ul>

    </div>
  );
};

CustomerReview.defaultProps = defaultProps;

export default CustomerReview;
