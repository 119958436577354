import React from 'react';
import cx from 'classnames';

import styles from './InfoDetails.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import EventDateStatus, { EventDateStatusProps } from '../EventDateStatus';

export const defaultProps = {
  title: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  eventDateStatus: {
    type: 'Default',
    date: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    time: {
      type: 'CardBody',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  } as EventDateStatusProps,
  venue: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
};

export type InfoDetailsProps = {
  title?: TextProps;
  eventDateStatus?: EventDateStatusProps;
  venue?: TextProps;
  className?: string;
};

const InfoDetails: React.FC<InfoDetailsProps> = ({
  title,
  eventDateStatus,
  venue,
  className,
}) => {
  return (
    <div className={cx(styles.infoDetails, className)}>
      <Text
        className={styles.title}
        {...title} />
      <div className={styles.eventLogistics}>
        <EventDateStatus
          className={styles.eventDateStatus}
          {...eventDateStatus} />
        <Text
          className={styles.venue}
          {...venue} />
      </div>
    </div>
  );
};

InfoDetails.defaultProps = defaultProps;

export default InfoDetails;
