import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocationState } from '../CheckoutManualPage/CheckoutManualPage.interactor';
import { OrderItems } from '../CheckoutManualPage/utils';
import { ReceiptPageProps } from './ReceiptPage';
import { ReceiptPagePresenterProps } from './ReceiptPage.presenter';

const withInteractor = (
  Presenter: React.FC<ReceiptPagePresenterProps>,
): React.FC<ReceiptPageProps> => {
  const Interactor: React.FC<ReceiptPageProps> = (props) => {
    const location = useLocation<LocationState>();
    const [orderItem] = useState<OrderItems>(
      location.state?.currentOrderItems,
    );
    let error: Error | null = null;
    if (!orderItem) {
      error = new Error('Order not found');
    }

    return <Presenter 
      {...props}
      error={error || undefined}
      orderItems={orderItem} />;
  };
  return Interactor;
};
export default withInteractor;

