import React from 'react';
import cx from 'classnames';
import NotFoundErrorAsset from '../../../resources/icons/NotFoundError.svg';

import styles from './ErrorBlock.module.scss';

import Image, { ImageProps } from '../../atoms/Image';
import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';
import { useTranslation } from 'react-i18next';

export const defaultProps = {
  title: {
    type: 'Subheading',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  message: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Center',
  } as TextProps,
  button: {
    type: 'Text',
    style: 'Contained',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
};

export type ErrorBlockProps = {
  image?: ImageProps;
  title?: TextProps;
  message?: TextProps;
  button?: ButtonProps;
  className?: string;
};

const ErrorBlock: React.FC<ErrorBlockProps> = ({
  image,
  title,
  message,
  button,
  className,
}) => {
  const { t } = useTranslation();
  const titleProps: TextProps = {
    ...defaultProps.title,
    value: title?.value || t('ErrorPage.ErrorBlock.defaultTitle'),
  };

  const content: React.ReactNode[] = [
    t('ErrorPage.ErrorBlock.defaultMessagesPart1'),
    // eslint-disable-next-line react/jsx-key
    <br />,
    t('ErrorPage.ErrorBlock.defaultMessagesPart2'),
  ];

  const messageProps: TextProps = {
    ...defaultProps.message,
    value: message?.value || content,
  };

  const buttonProps: ButtonProps = {
    ...defaultProps.button,
    ...button,
    text: {
      ...defaultProps.button.text,
      value: button?.text?.value ? button?.text?.value : 'Back to Home',
    },
    linkPath: button?.linkPath ? button?.linkPath : '/',
  };


  return (
    <div className={cx(styles.errorBlock, className)}>
      <Image
        className={styles.image}
        srcValue={NotFoundErrorAsset}
        {...image}
      />
      <div className={styles.content}>
        <Text
          className={styles.title}
          {...title}
          {...titleProps} />
        <Text
          className={styles.message}
          {...message}
          {...messageProps} />
      </div>
    </div>
  );
};

ErrorBlock.defaultProps = defaultProps;

export default ErrorBlock;
