
export const binArray: string[] = [ 
  '400344',
  '403065',
  '403200',
  '403201',
  '403202',
  '403299',
  '403444',
  '403694',
  '404614',
  '404615',
  '406096',
  '410608',
  '410673',
  '412128',
  '412174',
  '412179',
  '413028',
  '413113',
  '413140',
  '413160',
  '413165',
  '413170',
  '413716',
  '414709',
  '415557',
  '416841',
  '420042',
  '420043',
  '421759',
  '421794',
  '422983',
  '423311',
  '423340',
  '424735',
  '426932',
  '426933',
  '426934',
  '426944',
  '426993',
  '426994',
  '426996',
  '427501',
  '427502',
  '427546',
  '429137',
  '429184',
  '429185',
  '430200',
  '430572',
  '431047',
  '431467',
  '431468',
  '431663',
  '431689',
  '431734',
  '438826',
  '438845',
  '438856',
  '438864',
  '440560',
  '440561',
  '443508',
  '449882',
  '463099',
  '463814',
  '464444',
  '464445',
  '465602',
  '466303',
  '466304',
  '466306',
  '466307',
  '466309',
  '466800',
  '468405',
  '468406',
  '471206',
  '473068',
  '474672',
  '474673',
  '474743',
  '475086',
  '475698',
  '477767',
  '478827',
  '478828',
  '479365',
  '480385',
  '486236',
  '487333',
  '487334',
  '493422',
  '512025',
  '512026',
  '512027',
  '512300',
  '512731',
  '512734',
  '514953',
  '515592',
  '515593',
  '515596',
  '515597',
  '515598',
  '515599',
  '515600',
  '515676',
  '517669',
  '517805',
  '517993',
  '517997',
  '517998',
  '518374',
  '518726',
  '520094',
  '520101',
  '520103',
  '520104',
  '520105',
  '520106',
  '520118',
  '520611',
  '520612',
  '521331',
  '521506',
  '522233',
  '522955',
  '524324',
  '525999',
  '528942',
  '529107',
  '529115',
  '529123',
  '529149',
  '529156',
  '531480',
  '531651',
  '532830',
  '540159',
  '540419',
  '540420',
  '540421',
  '540422',
  '540423',
  '540424',
  '540426',
  '540633',
  '540704',
  '540791',
  '540801',
  '541058',
  '541336',
  '541444',
  '541448',
  '541474',
  '541536',
  '541549',
  '541578',
  '541586',
  '541627',
  '541829',
  '542442',
  '542583',
  '542775',
  '543328',
  '543339',
  '543368',
  '543857',
  '543961',
  '544045',
  '544316',
  '544516',
  '544910',
  '545800',
  '546281',
  '546283',
  '546608',
  '546630',
  '546720',
  '548885',
  '548892',
  '548897',
  '548950',
  '548951',
  '548955',
  '549062',
  '549066',
  '549107',
  '549110',
  '549111',
  '549112',
  '549120',
  '549121',
  '549506',
  '550300',
  '552436',
  '554356',
  '400229',
  '411507',
  '415417',
  '419310',
  '479124',
  '480213',
  '547855',
  '552851',
  '552869',
  '558958',
  '404527',
  '430023',
  '463000',
  '464444',
  '468837',
  '468839',
  '480306',
  '515678',
  '516075',
  '517148',
  '520602',
  '522303',
  '522944',
  '524337',
  '525478',
  '539662',
  '540707',
  '540789',
  '540790',
  '540792',
  '543235',
  '543700',
  '543701',
  '543702',
  '543703',
  '546325',
  '546641',
  '546702',
  '547094',
  '547363',
  '547770',
  '548042',
  '548043',
  '548506',
  '549943',
  '549944',
  '549945',
  '552030',
  '552234',
  '552318',
  '552319',
  '552320',
  '555675',
  '558650',
  '515307',
  '517177',
  '519231',
  '522567',
  '523508',
  '523914',
  '524755',
  '529628',
  '540425',
  '541402',
  '541458',
  '544515',
  '546323',
  '552342',
  '603220',
  '511293',
  '514686',
  '514745',
  '514759',
  '515241',
  '515321',
  '520318',
  '522307',
  '528724',
  '545534',
  '546063',
  '546064',
  '548012',
  '548013',
  '548798',
  '548799',
  '549171',
  '549621',
  '551298',
  '551980',
  '551981',
  '554336',
  '558086',
  '558224',
  '501169',
  '504882',
  '560657',
  '567564',
  '571760',
  '572890',
  '575776',
  '580919',
  '588686',
  '588695',
  '5881280',
  '5881285',
  '510805',
  '521105',
  '522132',
  '465300',
  '400958',
  '424810',
  '481121',
  '481122',
  '519512',
  '519519',
  '541995',
  '512348',
  '230035',
  '553258',
  '556306',
  '552802',
  '540907',
  '553327',
  '553328',
  '513909',
  '545749',
  '545756',
  '545923',
  '546840',
  '547365',
  '548496',
  '549139',
  '552380',
  '552496',
  '552497',
  '415373',
  '457239',
  '457243',
  '477596',
  '477597',
  '516397',
  '518142',
  '518581',
  '530418',
  '531200',
  '546096',
  '546097',
  '552895',
  '553485',
  '553489',
  '411358',
  '539858',
  '518725',
  '526444',
  '529172',
  '532839',
  '541449',
  '541513',
  '544317',
  '544347',
  '449867',
  '424642',
  '425418',
  '493109',
  '528620',
  '552341',
  '558660',
  '559136',
  '555422',
  '443122',
  '426930',
  '473344',
  '511912',
  '558663',
  '528211',
  '557867',
  '424642',
  '493109',
  '423322',
  '475551',
  '517177',
  '522567',
  '529628',
  '540425',
  '541402',
  '541458',
  '544515',
  '403065',
  '403299',
  '403611',
  '404615',
  '405616',
  '411358',
  '518274',
  '532830',
  '541444',
  '542583',
  '543857',
  '544316',
  '544910',
  '501169',
  '504882',
  '560657',
  '567564',
  '568128',
  '571760',
  '572890',
  '575776',
  '580919',
  '588686',
  '588695',
  '600430',
  '603220',
  '609025',
  '609026',
  // dummy bin info added
  '555555',
  '222300',
  '411111',
  '400551',
  '400934',
  '401200',
  '401288',
  '421765',
  '450060',
  '403000',
];


export const dummyLastFour:string[] = [
  '4444',
  '0011',
  '1111',
  '0004',
  '1881',
  '7777',
  '1881',
  '1119',
  '0061',
  '0026',
];