import React from 'react';
import cx from 'classnames';

import styles from './HighlightMessage.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import Icon, { IconProps } from '../../atoms/Icon';
import Text, { TextProps } from '../../atoms/Text';

export type HighlightMessageTypeType = 'Info' | 'Error';

export const defaultProps = {
  type: 'Error' as HighlightMessageTypeType,
  divider: {
    type: 'Vertical',
    style: 'Thick',
    colour: 'CP1Red',
  } as DividerProps,
  icon: {
    asset: 'CloseCircleFill',
    style: 'DigitalGrey100',
  } as IconProps,
  message: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
};

export type HighlightMessageProps = {
  type?: HighlightMessageTypeType;
  divider?: DividerProps;
  icon?: IconProps;
  message?: TextProps;
  className?: string;
};

const HighlightMessage: React.FC<HighlightMessageProps> = ({
  type,
  divider,
  icon,
  message,
  className,
}) => {

  const currentStyle = styles[`highlightMessage${type}`];

  const dividerView = (
    <Divider
      className={styles.divider}
      {...divider} />
  );
  const contentView = (
    <div className={styles.content}>
      <Icon
        className={styles.icon}
        {...icon} />
      <Text
        className={styles.message}
        {...message} />
    </div>
  );
  
  
  switch (type) {
    case 'Info':
      break;
    case 'Error':
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {dividerView}
      {contentView}
    </div>
  );
};

HighlightMessage.defaultProps = defaultProps;

export default HighlightMessage;
