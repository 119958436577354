import React from 'react';
import cx from 'classnames';

import styles from './InfoOnMultipleLine.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  label: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  buyerName: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  address: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  cityProvinceCountry: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  postalCode: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
};

export type InfoOnMultipleLineProps = {
  label?: TextProps;
  buyerName?: TextProps;
  address?: TextProps;
  cityProvinceCountry?: TextProps;
  postalCode?: TextProps;
  className?: string;
};

const InfoOnMultipleLine: React.FC<InfoOnMultipleLineProps> = ({
  label,
  buyerName,
  address,
  cityProvinceCountry,
  postalCode,
  className,
}) => {
  return (
    <div className={cx(styles.infoOnMultipleLine, className)}>
      <Text
        className={styles.label}
        {...label} />
      <Text
        className={styles.buyerName}
        {...buyerName} />
      <Text
        className={styles.address}
        {...address} />
      <Text
        className={styles.cityProvinceCountry}
        {...cityProvinceCountry} />
      <Text
        className={styles.postalCode}
        {...postalCode} />
    </div>
  );
};

InfoOnMultipleLine.defaultProps = defaultProps;

export default InfoOnMultipleLine;
