import React from 'react';
import cx from 'classnames';

import styles from './OrderDetails.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';
import InfoHeader, { InfoHeaderProps } from '../../molecules/InfoHeader';
import Divider, { DividerProps } from '../../atoms/Divider';
import Text, { TextProps } from '../../atoms/Text';
import AdditionalTicketInformationList, { AdditionalTicketInformationListProps } from '../../molecules/AdditionalTicketInformationList';
import TextItem, { TextItemProps } from '../../molecules/TextItem';
import HighlightMessage, { HighlightMessageProps } from '../../molecules/HighlightMessage';
import InfoDetails, { InfoDetailsProps } from '../../molecules/InfoDetails';
import SeatInfo, { SeatInfoProps } from '../../molecules/SeatInfo';
import Logo, { LogoProps } from '../../atoms/Logo';
import InfoOnMultipleLine, { InfoOnMultipleLineProps } from '../../molecules/InfoOnMultipleLine';
import PaymentMethod, { PaymentMethodProps } from '../../molecules/PaymentMethod';
import Spinner from '../../atoms/Spinner';
import CostBreakdown, { CostBreakdownProps } from '../../molecules/CostBreakdown';

export const defaultProps = {
  closeButton: {
    type: 'Icon',
    style: 'Text',
    size: 'Small',
    icon: {
      asset: 'Close',
      style: 'DigitalGrey80',
    },
  } as ButtonProps,
  infoHeader: {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    details: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  } as InfoHeaderProps,
  printButton: {
    type: 'IconText',
    style: 'Text',
    size: 'Small',
    icon: {
      asset: 'Printer',
      style: 'ActionBase',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'ActionActive',
      align: 'Left',
    },
  } as ButtonProps,
  divider: {
    type: 'Horizontal',
    style: 'Thin',
    colour: 'DigitalGrey15',
  } as DividerProps,
  deliveryInfoTitle: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  additionalTicketInformationList: {
    iconTextDescriptions: [
    ],
  } as AdditionalTicketInformationListProps,
  deliveryDateInfo: {
    style: 'SubduedLabel',
    expectedDelivery: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    date: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  downloadButton: {
    type: 'Text',
    style: 'ContainedGreen',
    size: 'Medium',
    text: {
      type: 'Body',
      size: 'Large',
      style: 'SemiBold',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
  ticketInfoTitle: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  highlightMessage: {
    type: 'Info',
    divider: {
      type: 'Vertical',
      style: 'Thick',
      colour: 'InteractionBlue50',
    },
    icon: {
      asset: 'Mail',
      style: 'DigitalGrey80',
    },
    message: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as HighlightMessageProps,
  infoDetails: {
    title: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    eventDateStatus: {
      type: 'Default',
      date: {
        type: 'CardBody',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      },
      time: {
        type: 'CardBody',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
    },
    venue: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  } as InfoDetailsProps,
  seatInfo: {
    section: {
      style: 'SubduedLabel',
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'BaseDark',
        align: 'Left',
      },
    },
    row: {
      style: 'SubduedLabel',
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'BaseDark',
        align: 'Left',
      },
    },
    quantity: {
      style: 'SubduedLabel',
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'BaseDark',
        align: 'Left',
      },
    },
  } as SeatInfoProps,
  sellerNotes: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  label: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  logo: {
    asset: 'VividLogo',
  } as LogoProps,
  billingInfoTitle: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  infoOnMultipleLine: {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    buyerName: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    address: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    cityProvinceCountry: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    postalCode: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  } as InfoOnMultipleLineProps,
  phoneNumber: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  paymentMethod: {
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as PaymentMethodProps,
  totalCardPayment: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  rewardsMilesRedeemed: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  rightContent: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
};

export type OrderDetailsProps = {
  closeButton?: ButtonProps;
  infoHeader?: InfoHeaderProps;
  printButton?: ButtonProps;
  divider?: DividerProps;
  deliveryInfoTitle?: TextProps;
  additionalTicketInformationList?: AdditionalTicketInformationListProps;
  deliveryDateInfo?: TextItemProps;
  downloadButton?: ButtonProps;
  ticketInfoTitle?: TextProps;
  highlightMessage?: HighlightMessageProps;
  infoDetails?: InfoDetailsProps;
  seatInfo?: SeatInfoProps;
  sellerNotes?: TextItemProps;
  label?: TextProps;
  logo?: LogoProps;
  billingInfoTitle?: TextProps;
  infoOnMultipleLine?: InfoOnMultipleLineProps;
  phoneNumber?: TextItemProps;
  paymentMethod?: PaymentMethodProps;
  totalCardPayment?: TextItemProps;
  rewardsMilesRedeemed?: TextItemProps;
  className?: string;
  rightContent?: TextItemProps;
  showHighlight?: boolean;
  showDownloadButton?: boolean;
  showCloseButton?: boolean;
  showLogo?: boolean;
  costBreakdown?: CostBreakdownProps;
  showCostBreakdown?: boolean;
  insuranceTitle?: TextProps;
  insuranceLabel?: TextProps;
  showInsurance?: boolean;
  isLoading?: boolean;
};

const OrderDetails: React.FC<OrderDetailsProps> = ({
  closeButton,
  infoHeader,
  divider,
  deliveryInfoTitle,
  additionalTicketInformationList,
  deliveryDateInfo,
  downloadButton,
  ticketInfoTitle,
  highlightMessage,
  infoDetails,
  seatInfo,
  sellerNotes,
  label,
  logo,
  billingInfoTitle,
  infoOnMultipleLine,
  phoneNumber,
  paymentMethod,
  totalCardPayment,
  rewardsMilesRedeemed,
  className,
  rightContent,
  showHighlight,
  showDownloadButton,
  showCloseButton,
  showLogo,
  costBreakdown,
  showCostBreakdown,
  insuranceTitle,
  insuranceLabel,
  showInsurance,
  isLoading,
}) => {
  let hightLight;
  let download;
  let close;
  let viewLogo;
  let insuranceSection;
  if (isLoading) {
    return <Spinner></Spinner>;
  }
  if (showHighlight) {
    hightLight = (
      <HighlightMessage
        className={styles.highlightMessage}
        {...highlightMessage} />
    );
  }
  if (showDownloadButton) {
    download = <Button
      className={styles.downloadButton}
      {...downloadButton} />;
  }
  if (showCloseButton) {
    close = (
      <>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.closeButton}
            {...closeButton} />
        </div>
        <div className={styles.buttonContainerMobile}>
          <Button
            className={styles.closeButton}
            {...closeButton} />
        </div>
      </>
    );
  }
  if (showInsurance) {
    insuranceSection = (
      <>
        <Divider
          className={styles.divider}
          {...divider} />
        <Text
          className={styles.billingInfoTitle}
          {...insuranceTitle} />
        <Text
          className={styles.insuranceLabel}
          {...insuranceLabel} />
      </>
    );
  }
  return (
    <div className={cx(styles.orderDetails, className)}>
      {close}
      <div className={styles.topContent}>
        <InfoHeader
          className={styles.infoHeader}
          {...infoHeader} />
      </div>
      <Divider
        className={styles.divider}
        {...divider} />
      <div className={styles.content}>
        <div className={styles.ticketContent}>
          <Text
            className={styles.ticketInfoTitle}
            {...ticketInfoTitle} />
          {hightLight}
          <InfoDetails
            className={styles.infoDetails}
            {...infoDetails} />
          <SeatInfo
            className={styles.seatInfo}
            {...seatInfo} />
          <TextItem
            className={styles.sellerNotes}
            {...sellerNotes} />
          <div className={styles.logoContent}>
            <Text
              className={styles.label}
              {...label} />
            {viewLogo}
          </div>
        </div>
        <Divider
          className={styles.dividerVertical}
          {...divider}
          type='Vertical' />
        <div className={styles.deliveryContent}>
          <Text
            className={styles.deliveryInfoTitle}
            {...deliveryInfoTitle} />
          <AdditionalTicketInformationList
            className={styles.additionalTicketInformationList}
            {...additionalTicketInformationList} />
          <TextItem
            className={styles.deliveryDateInfo}
            {...deliveryDateInfo} />
          {download}
        </div>
      </div>
      <div className={styles.deliveryContentMobile}>
        <Text
          className={styles.deliveryInfoTitle}
          {...deliveryInfoTitle} />
        <AdditionalTicketInformationList
          className={styles.additionalTicketInformationList}
          {...additionalTicketInformationList} />
        <TextItem
          className={styles.deliveryDateInfo}
          {...deliveryDateInfo} />
        {download}
      </div>
      <Divider
        className={styles.divider}
        {...divider} />
      <div className={styles.billingContent}>
        <Text
          className={styles.billingInfoTitle}
          {...billingInfoTitle} />
        <div className={styles.content}>
          <div className={styles.leftContent}>
            <InfoOnMultipleLine
              className={styles.infoOnMultipleLine}
              {...infoOnMultipleLine} />
            <TextItem
              className={styles.phoneNumber}
              {...phoneNumber} />
          </div>
          <div className={styles.middleContent}>
            <PaymentMethod
              className={styles.paymentMethod}
              {...paymentMethod} />
            <TextItem
              className={styles.totalCardPayment}
              {...totalCardPayment} />
            <TextItem
              className={styles.rewardsMilesRedeemed}
              {...rewardsMilesRedeemed} />
          </div>
          {showCostBreakdown ? <CostBreakdown
            className={styles.costBreakdown}
            {...costBreakdown} /> : ''}
        </div>
      </div>
      <div className={styles.ticketContentMobile}>
        <Text
          className={styles.ticketInfoTitle}
          {...ticketInfoTitle} />
        {hightLight}
        <InfoDetails
          className={styles.infoDetails}
          {...infoDetails} />
        <SeatInfo
          className={styles.seatInfo}
          {...seatInfo} />
        <TextItem
          className={styles.sellerNotes}
          {...sellerNotes} />
        <div className={styles.logoContent}>
          <Text
            className={styles.label}
            {...label} />
          {viewLogo}
        </div>
      </div>
      <div className={styles.billingContentMobile}>
        <Divider
          className={styles.divider}
          {...divider} />
        <Text
          className={styles.billingInfoTitle}
          {...billingInfoTitle} />
        <InfoOnMultipleLine
          className={styles.infoOnMultipleLine}
          {...infoOnMultipleLine} />
        <TextItem
          className={styles.phoneNumber}
          {...phoneNumber} />
        <PaymentMethod
          className={styles.paymentMethod}
          {...paymentMethod} />
        <TextItem
          className={styles.totalCardPayment}
          {...totalCardPayment} />
        <TextItem
          className={styles.rewardsMilesRedeemed}
          {...rewardsMilesRedeemed} />
        {showCostBreakdown ? <CostBreakdown
            className={styles.costBreakdown}
            {...costBreakdown} /> : ''}
      </div>
      {insuranceSection}
    </div>
  );
};

OrderDetails.defaultProps = defaultProps;

export default OrderDetails;
