import React from 'react';
import cx from 'classnames';

import styles from './SliderInteraction.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Slider, { SliderProps } from '../../molecules/Slider';
import TextInput, { TextInputProps } from '../../atoms/TextInput';

export const defaultProps = {
  initialValue: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  miles: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  text: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  slider: {
    trackEmpty: {
      state: 'Empty',
    },
    trackFilled: {
      state: 'Filled',
    },
    toggle: {
      size: 'Default',
    },
  } as SliderProps,
  pointsInput: {
    state: 'Empty',
    style: 'DefaultCenter',
    size: 'Regular',
  } as TextInputProps,
  dollarValue: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  remainingBalance: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Center',
  } as TextProps,
};

export type SliderInteractionProps = {
  initialValue?: TextProps;
  miles?: TextProps;
  text?: TextProps;
  slider?: SliderProps;
  pointsInput?: TextInputProps;
  dollarValue?: TextProps;
  remainingBalance?: TextProps;
  prefix?: string;
  suffix?: string;
  className?: string;
};

const SliderInteraction: React.FC<SliderInteractionProps> = ({
  initialValue,
  miles,
  text,
  slider,
  pointsInput,
  dollarValue,
  remainingBalance,
  prefix,
  suffix,
  className,
}) => {
  return (
    <div className={cx(styles.sliderInteraction, className)}>
      <div className={styles.topContent}>
        <div className={styles.leftContent}>
          <Text
            className={styles.miles}
            {...initialValue} />
          <Text
            className={styles.text}
            {...text} />
        </div>
        <Slider
          className={styles.slider}
          {...slider} />

        <div className={styles.rightContent}>
          <Text
            className={styles.miles}
            {...miles} />
          <Text
            className={styles.text}
            {...text} />
        </div>
      </div>
      <Slider
        className={styles.mobileSlider}
        {...slider} />
      <div className={styles.manualInput}>
        <div className={styles.inputLine}>
          <span>{prefix}</span>
          <TextInput
            className={styles.pointsInput}
            {...pointsInput} />
          <span>{suffix}</span>
        </div>
        <div className={styles.convertedValue}>
          <Text
            className={styles.text}
            {...text} />
          <Text
            className={styles.dollarValue}
            {...dollarValue} />
        </div>
      </div>
      <Text
        className={styles.remainingBalance}
        {...remainingBalance} />
      <div className={styles.liveRegionArea} role="alert">
        <p>{`${pointsInput?.textValue} ${text?.value}`} {remainingBalance?.value}</p>
      </div>
    </div>
  );
};

SliderInteraction.defaultProps = defaultProps;

export default SliderInteraction;
