import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SENTRY_DSN_VALUE } from '../lib/config';
export const initSentry = (): void => {
  Sentry.init({
    dsn: SENTRY_DSN_VALUE,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
};