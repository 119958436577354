import React, { Dispatch } from 'react';
import cx from 'classnames';
import styles from './MyTicketsPage.module.scss';


import OrderDetails, { OrderDetailsProps } from '../../organisms/OrderDetails';

export const defaultProps = {

};

export type MyTicketsPageProps = {
  className?: string;
  error?: Error;
  viewOrderModal?: boolean;
  setViewOrderModal?: VoidFunction;
  orderDetailsProps?: OrderDetailsProps;
  setUrl?: Dispatch<string>;
  isCp1Exclusive?: boolean;
  cardHolderExclusiveLabel?: string;
  ticketNotice?: string;
  orderDetailsTitle?: string;
  isMobileTicket?: boolean; // Discard once VSCO-433 is implemented
  pageLoading?: boolean;
  eventLoading?: boolean;
};

const MyTicketsPage: React.FC<MyTicketsPageProps> = ({
  className,
  error,
  viewOrderModal,
  setViewOrderModal,
  orderDetailsProps,
  setUrl,
  isCp1Exclusive,
  cardHolderExclusiveLabel,
  ticketNotice,
  orderDetailsTitle,
  isMobileTicket,
  pageLoading,
  eventLoading,
}) => {


  return (
    <>
      <div className={cx(styles.myTicketsPage, className)}>
        <div className={styles.container}>
          <OrderDetails className={styles.orderDetailsContainer} {...orderDetailsProps} isLoading={eventLoading} />
        </div>
      </div>
    </>
  );
};

MyTicketsPage.defaultProps = defaultProps;

export default MyTicketsPage;
