import React, { Dispatch } from 'react';
import cx from 'classnames';

import styles from './ReceiptPage.module.scss';

import ReceiptBlock, { ReceiptBlockProps } from '../../blocks/ReceiptBlock';
import ErrorHandler from '../../../modules/error/ErrorHandler';

export const defaultProps = {
  receiptBlock: {
    title: {
      type: 'Subheading',
      size: 'Large',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    orderDetails: {
      closeButton: {
        type: 'Icon',
        style: 'Text',
        size: 'Small',
        icon: {
          asset: 'Close',
          style: 'DigitalGrey80',
        },
      },
      infoHeader: {
        title: {
          type: 'Subheading',
          size: 'Large',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
        details: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
      },
      printButton: {
        type: 'IconText',
        style: 'Text',
        size: 'Small',
        icon: {
          asset: 'Printer',
          style: 'ActionBase',
        },
        text: {
          type: 'Body',
          size: 'Large',
          style: 'Regular',
          colour: 'ActionActive',
          align: 'Left',
        },
      },
      divider: {
        type: 'Horizontal',
        style: 'Thin',
        colour: 'DigitalGrey15',
      },
      ticketInfoTitle: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      highlightMessage: {
        type: 'Info',
        divider: {
          type: 'Vertical',
          style: 'Thick',
          colour: 'InteractionBlue50',
        },
        icon: {
          asset: 'Mail',
          style: 'DigitalGrey80',
        },
        message: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'BaseDark',
          align: 'Left',
        },
      },
      infoDetails: {
        title: {
          type: 'Body',
          size: 'Large',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
        eventDateStatus: {
          type: 'Default',
          date: {
            type: 'CardBody',
            size: 'Medium',
            style: 'Regular',
            colour: 'SubduedDark',
            align: 'Left',
          },
          time: {
            type: 'CardBody',
            size: 'Medium',
            style: 'Regular',
            colour: 'SubduedLight',
            align: 'Left',
          },
        },
        venue: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
      },
      seatInfo: {
        section: {
          style: 'SubduedLabel',
          label: {
            type: 'Body',
            size: 'Small',
            style: 'Regular',
            colour: 'SubduedLight',
            align: 'Left',
          },
          description: {
            type: 'Body',
            size: 'Large',
            style: 'Regular',
            colour: 'BaseDark',
            align: 'Left',
          },
        },
        row: {
          style: 'SubduedLabel',
          label: {
            type: 'Body',
            size: 'Small',
            style: 'Regular',
            colour: 'SubduedLight',
            align: 'Left',
          },
          description: {
            type: 'Body',
            size: 'Large',
            style: 'Regular',
            colour: 'BaseDark',
            align: 'Left',
          },
        },
        quantity: {
          style: 'SubduedLabel',
          label: {
            type: 'Body',
            size: 'Small',
            style: 'Regular',
            colour: 'SubduedLight',
            align: 'Left',
          },
          description: {
            type: 'Body',
            size: 'Large',
            style: 'Regular',
            colour: 'BaseDark',
            align: 'Left',
          },
        },
      },
      sellerNotes: {
        style: 'SubduedLabel',
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'BaseDark',
          align: 'Left',
        },
      },
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      logo: {
        asset: 'VividLogo',
      },
      deliveryInfoTitle: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      additionalTicketInformationList: {
        iconTextDescriptions: [],
      },
      deliveryDateInfo: {
        style: 'SubduedLabel',
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'BaseDark',
          align: 'Left',
        },
      },
      downloadButton: {
        type: 'Text',
        style: 'ContainedGreen',
        size: 'Medium',
        text: {
          type: 'Body',
          size: 'Large',
          style: 'SemiBold',
          colour: 'BaseLight',
          align: 'Left',
        },
      },
      billingInfoTitle: {
        type: 'Body',
        size: 'Large',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      infoOnMultipleLine: {
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        buyerName: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
        address: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
        cityProvinceCountry: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
        postalCode: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'SubduedDark',
          align: 'Left',
        },
      },
      phoneNumber: {
        style: 'SubduedLabel',
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'BaseDark',
          align: 'Left',
        },
      },
      paymentMethod: {
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'BaseDark',
          align: 'Left',
        },
      },
      totalCardPayment: {
        style: 'SubduedLabel',
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'BaseDark',
          align: 'Left',
        },
      },
      rightContent: {
        style: 'SubduedLabel',
        label: {
          type: 'Body',
          size: 'Small',
          style: 'Regular',
          colour: 'SubduedLight',
          align: 'Left',
        },
        description: {
          type: 'Body',
          size: 'Medium',
          style: 'Regular',
          colour: 'BaseDark',
          align: 'Left',
        },
      },
    },
  } as ReceiptBlockProps,
};

export type ReceiptPageProps = {
  receiptBlock?: ReceiptBlockProps;
  className?: string;
  error?: Error;
  setUrl?: Dispatch<string>;
};

const ReceiptPage: React.FC<ReceiptPageProps> = ({
  receiptBlock,
  className,
  error,
}) => {
  if (error) return <ErrorHandler error={error} />;

  return (
    <div className={cx(styles.receiptPage, className)}>
      <div className={styles.container}>
        <ReceiptBlock
          className={styles.receiptBlock}
          {...receiptBlock} />
      </div>
    </div>
  );
};

ReceiptPage.defaultProps = defaultProps;

export default ReceiptPage;
