import React from 'react';
import cx from 'classnames';

import styles from './CustomerInfoBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextFields, { TextFieldsProps } from '../../molecules/TextFields';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  emailField: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Filled',
      style: 'Default',
      size: 'Regular',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  message: {
    type: 'Body',
    colour: 'SubduedLight',
    align: 'Left',
    style: 'Regular',
    size: 'Medium',
  } as TextProps,
  button: {
    type: 'Text',
    style: 'Contained',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
};

export type CustomerInfoBlockProps = {
  blockTitle?: TextProps;
  emailLabel?: TextProps;
  emailField?: TextFieldsProps;
  message?: TextProps;
  className?: string;
  button?: ButtonProps;
};

const CustomerInfoBlock: React.FC<CustomerInfoBlockProps> = ({
  blockTitle,
  emailLabel,
  emailField,
  message,
  className,
  button,
}) => {
  return (
    <div className={cx(styles.customerInfoBlock, className)}>
      <Text
        className={styles.blockTitle}
        {...blockTitle} />
      <TextFields
          className={styles.emailField}
          {...emailField} />
      <Text
        className={styles.message}
        {...message} />
      <Button
        className={styles.button}
        {...button} />
    </div>
  );
};

CustomerInfoBlock.defaultProps = defaultProps;

export default CustomerInfoBlock;
