export const QUERY_DATE_FROM = 'date_start';
export const QUERY_DATE_TO = 'date_end';
export const CHECKOUT_BRAINTREE_FORM = 'checkout-brainTree-form';
export const CHECKOUT_NONE_BRAINTREE_FORM = 'checkout-none-brainTree-form';
export const CHECKOUT_SHIPPING_FORM = 'checkout-shipping-form';
export const TERMS_MODAL = 'terms-modal';

export const CP1_EXCLUSIVE_TAG = 'C1_EXCLUSIVE';
export const CP1_NOT_EXCLUSIVE_TAG = 'NOT C1_EXCLUSIVE';
export const SOLD_OUT_TAG = 'SOLD_OUT';
export const CASH_ONLY_TAG = 'CREDIT_CARD_PAYMENT';
export const SPLIT_PAY_TAG = 'SPLIT_PAYMENT';
export const REWARDS_ONLY_TAG = 'LOYALTY_REWARDS_PAYMENT';
export const PURCHASE_LIMIT_TAG = 'PURCHASE_LIMIT';
export const C1_EXCLUSIVE_LISTING = 'C1_EXCLUSIVE_LISTING';
export const GUEST_LIST_EVENT = 'GUEST_LIST';
export const ALWAYS_SUPPRESS_VSINVENTORY_TAG = 'ALWAYS_SUPPRESS_VSINVENTORY';
export const C1_BROKER_ID = '29014';
export const DELIVERY_REQUIRING_SHIPPING = ['Shipped via UPS', 'Local Pickup'];

export const INSURANCE_SELECTED_OPTION = 'insuranceOption0';
export const INSURANCE_REJECTED_OPTION = 'insuranceOption1';

type LocationItem = {
  name: string,
  code: string,
};

export const COUNTRIES: LocationItem[] = [
  { code: 'US', name: 'United States' },
  { code: 'CA', name: 'Canada' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AU', name: 'Australia' },
  { code: 'AT', name: 'Austria' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BE', name: 'Belgium' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BO', name: 'Bolivia' },
  { code: 'BR', name: 'Brazil' },
  { code: 'CL', name: 'Chile' },
  { code: 'CN', name: 'China' },
  { code: 'CO', name: 'Colombia' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CZ', name: 'Czech Republic' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'EE', name: 'Estonia' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'DE', name: 'Germany' },
  { code: 'GR', name: 'Greece' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italy' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JP', name: 'Japan' },
  { code: 'LV', name: 'Latvia' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MT', name: 'Malta' },
  { code: 'MX', name: 'Mexico' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua' },
  { code: 'NO', name: 'Norway' },
  { code: 'PA', name: 'Panama' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'RO', name: 'Romania' },
  { code: 'RU', name: 'Russian Federation' },
  { code: 'ZA', name: 'South Africa' },
  { code: 'KR', name: 'South Korea' },
  { code: 'ES', name: 'Spain' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'TW', name: 'Taiwan' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'VI', name: 'Virgin Islands (U.S.)' },
  { code: 'VE', name: 'Venezuela' },
];
export const CANADA_PROVINCE_CODES: LocationItem[] = [
  { name: 'Canada - Alberta', code: 'AB' },
  { name: 'Canada - British Columbia', code: 'BC' },
  { name: 'Canada - Manitoba', code: 'MB' },
  { name: 'Canada - New Brunswick', code: 'NB' },
  { name: 'Canada - Newfoundland and Labrador', code: 'NL' },
  { name: 'Canada - Northwest Territories', code: 'NT' },
  { name: 'Canada - Nova Scotia', code: 'NS' },
  { name: 'Canada - Nunavut', code: 'NU' },
  { name: 'Canada - Ontario', code: 'ON' },
  { name: 'Canada - Prince Edward Island', code: 'PE' },
  { name: 'Canada - Quebec', code: 'QC' },
  { name: 'Canada - Saskatchewan', code: 'SK' },
  { name: 'Canada - Yukon', code: 'YT' },
];
export const US_STATE_CODES: LocationItem[] = [
  { name: 'No State', code: 'NOSTATE' },
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Washington D.C.', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Virgin Islands', code: 'VI' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
];

export const TRANSACTION_AFFILIATION = 'ticketsonsale.com';