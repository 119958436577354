import React from 'react';
import cx from 'classnames';

import styles from './Tag.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export type TagTypeType = 'Negative' | 'Information' | 'Neutral' | 'Positive' | 'Warning';
export type TagSizeType = 'Small' | 'Regular';

export const defaultProps = {
  type: 'Warning' as TagTypeType,
  size: 'Regular' as TagSizeType,
  label: {
    type: 'Body',
    size: 'Small',
    style: 'SemiBold',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
};

export type TagProps = {
  type?: TagTypeType;
  size?: TagSizeType;
  label?: TextProps;
  className?: string;
};

const Tag: React.FC<TagProps> = ({
  type,
  size,
  label,
  className,
}) => {

  const currentStyle = styles[`tag${type}${size}`];

  const labelView = (
    <Text
      className={styles.label}
      {...label} />
  );
  
  
  switch (type) {
    case 'Negative':
      break;
    case 'Information':
      break;
    case 'Neutral':
      break;
    case 'Positive':
      break;
    case 'Warning':
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
    </div>
  );
};

Tag.defaultProps = defaultProps;

export default Tag;
