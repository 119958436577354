import React from 'react';
import cx from 'classnames';

import styles from './BillingInfoBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';
import TextItem, { TextItemProps } from '../../molecules/TextItem';
import ToolTip, { ToolTipProps } from '../../atoms/ToolTip';
import CheckBoxItem, { CheckBoxItemProps } from '../../atoms/CheckBoxItem';
import TextFields, { TextFieldsProps } from '../../molecules/TextFields';
import { AriaLiveRegions } from '../../../lib/types';

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  firstName: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
      size: 'Medium',
    },
  } as TextItemProps,
  lastName: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
      size: 'Medium',
    },
  } as TextItemProps,
  address: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
      size: 'Medium',
    },
  } as TextItemProps,
  secondaryAddress: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
      size: 'Medium',
    },
  } as TextItemProps,
  city: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
    },
  } as TextItemProps,
  state: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
    },
  } as TextItemProps,
  postalCode: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
    },
  } as TextItemProps,
  country: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
    },
  } as TextItemProps,
  phoneNumber: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    description: {
      type: 'Body',
      colour: 'SubduedLight',
      align: 'Left',
      style: 'Regular',
    },
  } as TextItemProps,
  phoneNumberEditButton: {
    type: 'Text',
    style: 'Contained',
    size: 'ExtraSmall',
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'Action',
      align: 'Left',
    },
  } as ButtonProps,
  phoneNumberSaveButton: {
    type: 'Text',
    style: 'Contained',
    size: 'ExtraSmall',
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'Action',
      align: 'Left',
    },
  } as ButtonProps,
  phoneNumberField: {
    state: 'Default',
    label: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
    textInput: {
      state: 'Empty',
      style: 'Default',
      size: 'Small',
    },
    error: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
  } as TextFieldsProps,
  checkBoxItem: {
    state: 'Unselected',
    icon: {
      asset: 'CheckboxEmpty',
      style: 'DigitalGrey100',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as CheckBoxItemProps,
  button: {
    type: 'Text',
    style: 'Contained',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
};

export type BillingInfoBlockProps = {
  blockTitle?: TextProps;
  firstName?: TextItemProps;
  lastName?: TextItemProps;
  address?: TextItemProps;
  secondaryAddress?: TextItemProps;
  city?: TextItemProps;
  state?: TextItemProps;
  postalCode?: TextItemProps;
  country?: TextItemProps;
  phoneNumber?: TextItemProps;
  phoneNumberToolTip?: ToolTipProps;
  toolTipIcon?: ButtonProps;
  phoneNumberEditButton?: ButtonProps;
  phoneNumberSaveButton?: ButtonProps
  checkBoxItem?: CheckBoxItemProps;
  checkBoxItemError?: TextProps;
  phoneNumberField?: TextFieldsProps;
  shouldEnableTextField?: boolean;
  className?: string;
  button?: ButtonProps;
  ariaLive?: AriaLiveRegions;
};

const BillingInfoBlock: React.FC<BillingInfoBlockProps> = ({
  blockTitle,
  firstName,
  lastName,
  address,
  secondaryAddress,
  city,
  state,
  postalCode,
  country,
  phoneNumber,
  phoneNumberToolTip,
  toolTipIcon,
  phoneNumberEditButton,
  phoneNumberSaveButton,
  checkBoxItem,
  checkBoxItemError,
  shouldEnableTextField,
  phoneNumberField,
  className,
  button,
  ariaLive,
}) => {
  let secondaryAddressField;
  if (secondaryAddress?.description?.value) {
    secondaryAddressField = <TextItem
      className={styles.sccondaryAddress}
      {...secondaryAddress} />;
  }
  return (
    <div className={cx(styles.billingInfoBlock, className)} aria-live={ariaLive}>
      <Text
        className={styles.blockTitle}
        {...blockTitle} />
      <div className={styles.fieldGroup1}>
        <TextItem
          className={styles.firstName}
          {...firstName} />
        <TextItem
          className={styles.lastName}
          {...lastName} />
      </div>
      <TextItem
        className={styles.address}
        {...address} />
      {secondaryAddressField}
      <div className={styles.fieldGroup2}>
        <TextItem
          className={styles.city}
          {...city} />
        <TextItem
          className={styles.state}
          {...state} />
      </div>
      <div className={styles.fieldGroup3}>
        <TextItem
          className={styles.postalCode}
          {...postalCode} />
        <TextItem
          className={styles.country}
          {...country} />
      </div>
      <div className={styles.phoneNumberGroup}>

        {!shouldEnableTextField &&
          <TextItem
            className={styles.phoneNumber}
            {...phoneNumber} />}
        {shouldEnableTextField &&
          <TextFields
            className={styles.phoneNumberField}
            {...phoneNumberField}
          />}
        <div className={cx({ [styles.phoneNumberSave]: shouldEnableTextField, [styles.phoneNumberEdit]: !shouldEnableTextField })}>
          <ToolTip
            {...phoneNumberToolTip} >
            <Button {...toolTipIcon} />
          </ ToolTip>
          {shouldEnableTextField && <Button {...phoneNumberSaveButton} />}
          {!shouldEnableTextField && <Button {...phoneNumberEditButton} />}
        </div>
      </div>
      <CheckBoxItem
        className={styles.checkBoxItem}
        {...checkBoxItem} />
      <Text
        {...checkBoxItemError} />
      <Button
        {...button}
        className={cx(button?.className, styles.button)} />
    </div>
  );
};

BillingInfoBlock.defaultProps = defaultProps;

export default BillingInfoBlock;
