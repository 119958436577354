import React from 'react';
import cx from 'classnames';

import styles from './CostBreakdown.module.scss';

import PriceItem, { PriceItemProps } from '../PriceItem';
import Button, { ButtonProps } from '../../atoms/Button';
import TextInput, { TextInputProps } from '../../atoms/TextInput';
import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  ordarTotal: {
    style: 'Dark',
    item: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
    price: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  } as PriceItemProps,
  tickets: {
    style: 'Light',
    item: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    price: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  } as PriceItemProps,
  serviceFee: {
    style: 'Light',
    item: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    price: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  } as PriceItemProps,
  delivery: {
    style: 'Light',
    item: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    price: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  } as PriceItemProps,
  tax: {
    style: 'Light',
    item: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    price: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  } as PriceItemProps,
  button: {
    type: 'IconText',
    style: 'Text',
    size: 'ExtraSmall',
    icon: {
      asset: 'Plus',
      style: 'ActionBase',
    },
    text: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'ActionBase',
      align: 'Left',
    },
  } as ButtonProps,
};

export type CostBreakdownProps = {
  ordarTotal?: PriceItemProps;
  tickets?: PriceItemProps;
  serviceFee?: PriceItemProps;
  delivery?: PriceItemProps;
  tax?: PriceItemProps;
  promoLabel?: PriceItemProps;
  addedDiscount?: PriceItemProps;
  button?: ButtonProps;
  applyButton?: ButtonProps;
  promoInput?: TextInputProps;
  promoError?: TextProps;
  showPromoFeature?: boolean;
  showPromoButton?: boolean;
  showPromoInput?: boolean;
  promoCodeError?: boolean;
  addedPromoLabel?: PriceItemProps;
  className?: string;
};

const CostBreakdown: React.FC<CostBreakdownProps> = ({
  ordarTotal,
  tickets,
  serviceFee,
  delivery,
  tax,
  addedDiscount,
  button,
  applyButton,
  promoInput,
  promoError,
  showPromoFeature,
  showPromoButton,
  showPromoInput,
  promoLabel,
  promoCodeError,
  addedPromoLabel,
  className,
}) => {
  let promoFeature, discountSection;
  if (showPromoFeature) {
    promoFeature = <React.Fragment>
      {showPromoButton ? <div className={styles.promoCodeContainer}>
        <Button
          className={styles.button}
          {...button} />
      </div> : ''}
      {showPromoInput ? 
        <React.Fragment>
          <PriceItem
          className={styles.tax}
          {...promoLabel} />
          <div className={promoCodeError ? styles.promoInputError : styles.promoInputContainer}>
            <TextInput
              className={styles.promoInput}
              {...promoInput} />
            <Button
              className={styles.applyButton}
              {...applyButton} /> 
          </div> 
        </React.Fragment>
        : ''}
      {promoCodeError ? <Text
        className={styles.promoError}
        {...promoError} /> : '' }
      {addedPromoLabel?.item?.value ? <PriceItem
        className={styles.serviceFee}
        {...addedPromoLabel} /> : ''}
    </React.Fragment>;
  }
  if (addedDiscount?.price?.value) {
    discountSection =  <PriceItem
      className={styles.serviceFee}
      {...addedDiscount} />;
  }
  return (
    <div className={cx(styles.costBreakdown, className)}>
      <PriceItem
        className={styles.ordarTotal}
        {...ordarTotal} />
      <PriceItem
        className={styles.tickets}
        {...tickets} />
      <PriceItem
        className={styles.serviceFee}
        {...serviceFee} />
      <PriceItem
        className={styles.delivery}
        {...delivery} />
      <PriceItem
        className={styles.tax}
        {...tax} />
      {discountSection}
      {promoFeature}
    </div>
  );
};

CostBreakdown.defaultProps = defaultProps;

export default CostBreakdown;
