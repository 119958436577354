import React from 'react';
import cx from 'classnames';

import styles from './TextFields.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextInput, { TextInputProps } from '../../atoms/TextInput';
import { AriaLiveRegions } from '../../../lib/types';

export type TextFieldsStateType = 'Default' | 'Error';

export const defaultProps = {
  state: 'Default' as TextFieldsStateType,
  label: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'Negative',
    align: 'Left',
  } as TextProps,
  textInput: {
    state: 'Filled',
    style: 'Default',
    size: 'Regular',
  } as TextInputProps,
  error: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'Negative',
    align: 'Left',
  } as TextProps,
};

export type TextFieldsProps = {
  state?: TextFieldsStateType;
  label?: TextProps;
  textInput?: TextInputProps;
  className?: string;
  error?: TextProps;
  ariaLive?: AriaLiveRegions;
};

const TextFields: React.FC<TextFieldsProps> = ({
  state,
  label,
  textInput,
  className,
  error,
  ariaLive,
}) => {

  const currentStyle = styles[`textFields${state}`];

  const textInputView = (
    <TextInput
      className={styles.textInput}
      {...textInput} />
  );
  
  let labelView;
  let errorView;
  
  switch (state) {
    case 'Default':
      labelView = (
        <Text
          className={styles.label}
          {...label} />
      );
      break;
    case 'Error':
      labelView = (
        <Text
          className={styles.label}
          {...label} />
      );
      errorView = (
        <Text
          className={styles.error}
          {...error} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)} aria-live={ariaLive}>
      {labelView}
      {textInputView}
      {errorView}
    </div>
  );
};

TextFields.defaultProps = defaultProps;

export default TextFields;
