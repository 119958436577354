import { TFunction } from 'i18next';
import { round } from '../components/molecules/Slider/util';
import { Event, EventsQueryParams, ListingDetails, ListingsQueryParams, UserOrderTos, Venue } from '../modules/partnership/types';
import { ALWAYS_SUPPRESS_VSINVENTORY_TAG, CASH_ONLY_TAG, CP1_EXCLUSIVE_TAG, CP1_NOT_EXCLUSIVE_TAG, GUEST_LIST_EVENT, SOLD_OUT_TAG, SPLIT_PAY_TAG } from './constants';
import { UnitDisplaySettings } from './types';

export interface UrlAndQueryType {
  path: string;
  queryParams: string;
}

export interface TicketListingMapping {
  ticket_listing: string;
  ticket_details: string;
  delivery_method: string;
  confimation_page: string[];
  requiresShippingInfo?: string;
}

export interface ImageViewType {
  desktop: string;
  mobile: string;
}
export interface PerformerImage {
  hero: ImageViewType;
  modal: ImageViewType;
  widget: ImageViewType;
}
export type ImageType = 'hero' | 'modal' | 'widget';
export type ViewPoint = 'desktop' | 'mobile';

export const getTwoDigitsCountryCode = (code: string): string => {
  if (code.toUpperCase() === 'USA')
    return 'US';
  if (code.toUpperCase() === 'CAN')
    return 'CA';
  return code;
};

export const handleCountryCodeUS = (countryCode: string): boolean => {
  if (countryCode.toUpperCase() === 'USA' || countryCode.toUpperCase() === 'US') {
    return true;
  }
  return false;
};

export const isCanadaSelected = (countryCode: string): boolean => {
  if (countryCode.toUpperCase() === 'CA') {
    return true;
  }
  return false;
};

export const getFiveDigitsZipCode = (countryCode: string, postalCode: string): string => {
  if (handleCountryCodeUS(countryCode)) {
    return postalCode.substring(0, 5);
  }
  return postalCode;
};

export const getQueryParams = (url: string): Record<string, string> => {
  const results: Record<string, string> = {};
  const queryParams = url.split('?');
  if (queryParams.length > 1) {
    const params = queryParams[1].split('&');

    for (let i = 0, len = params.length; i < len; i += 1) {
      const parts = params[i].split('=');
      if (parts.length > 1) {
        const [key, value] = parts;
        results[key] = value;
      }
    }
  }
  return results;
};

export const removeIgnoredParams = (params: Record<string, string>, ignoredParams: string[]): Record<string, string> => {
  ignoredParams.forEach(param => {
    params[param] = '';
  });

  return params;
};

export const mapQueryString = (payload: Record<string, unknown>): string =>
  Object.entries(payload)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export const addQueryParam = (
  url: string,
  payload: Record<string, string>,
): string => {
  let queryParams = getQueryParams(url);
  queryParams = {
    ...queryParams,
    ...payload,
  };
  const queryString = mapQueryString(queryParams);
  const baseUrl = url.split('?')[0];
  return `${baseUrl}?${queryString}`;
};

export type DatePartsType = {
  [key in Intl.DateTimeFormatPartTypes]?: string;
};

export const formatDateToMonthDay = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.month} ${dateParts.day}`;
};

export const formatDateToApiDate = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
};

export const getLocalDate = (time: string): string => {
  const localDate = new Date(time).toLocaleDateString('en-us', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  return localDate;
};

export const getLocalTime = (time: string): string => {
  const localTime = new Date(time).toLocaleTimeString('en-us', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  });
  return localTime;
};

export enum DateFormats {
  // example: Fri, Jul 19 at 7:30pm
  TICKETINFO = 'ticketInfoFormat',
  // example: Jul 18, 2020
  CONFIRMATION = 'confirmationFormat',
}

/**
 * Formats a date to one of the following formats:
 * TICKETINFO:
 * Fri, Jul 19 at 7:30pm
 * CONFIRMATION:
 * Jul 18, 2020
 */
export function formatDate(
  dateAndTime: Date,
  t: TFunction,
  format: DateFormats,
): string {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit',
  })
    .formatToParts(dateAndTime)
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return t(`ticketInfo.dateTemplate.${format}`, {
    day_week: dateParts.weekday,
    month: dateParts.month,
    day: dateParts.day,
    hour: dateParts.hour,
    minute: dateParts.minute,
    period: dateParts.dayPeriod?.toLocaleLowerCase(),
    year: dateParts.year,
  });
}

export const isValidUrl = (input: string): boolean => {
  let url;
  try {
    url = new URL(input);
  } catch (e) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const handleDateFormat = (date: Date): string => {
  const dateParts: DatePartsType = new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  })
    .formatToParts(new Date(date))
    .reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});
  return `${dateParts.weekday}, ${dateParts.month} ${dateParts.day}`;
};

export const handleTimeFormat = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(date));
};

export const setEventDescription = (event: Event, mobile = false): string => {
  const eventDate = handleDateFormat(event.local_date);
  const eventTime = handleTimeFormat(event.local_date);
  
  if (mobile) {
    return ` ${eventDate} at ${eventTime} \n ${event.venue.name}\n ${event.venue.city} ${event.venue.state_code}`;
  }
  return ` ${eventDate} at ${eventTime} - ${event.venue.name}, ${event.venue.city} ${event.venue.state_code}`;
};

export const getWeekday = (date: Date): string => {
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'short',
  }).format(new Date(date));
};

export const getYear = (date: Date): string => {
  return String(new Date(date).getFullYear());
};

export enum CheckoutSteps {
  CUSTOMERINFO = 'customerInfo',
  BILLINGINFO = 'billingInfo',
  SHIPPINGINFO = 'shippingInfo',
  PAYMENTINFO = 'paymentInfo',
  CONFIRMATION = 'confirmation',
}

export const handleLoyaltyUnitTags = (loyaltyUnitName: string): string => {
  let loyaltyTag = '';
  switch (loyaltyUnitName.toLowerCase().replace(/ /g, '_')) {
    case 'miles':
      loyaltyTag = 'C1_MILES';
      break;
    case 'cash':
    case 'cash_rewards':
    case 'rewards_cash':
      loyaltyTag = 'C1_CASH_REWARDS';
      break;

    default:
      break;
  }
  return loyaltyTag;
};

export const transformProgramType = (programType = ''): string => {
  return programType.trim()
    .toUpperCase()
    .replace(/\s/g, '_');
};


export const capitalize = (title: string): string => {
  return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
};

export enum RewardUnits {
  MILES = 'Miles',
  CASH_REWARDS = 'Rewards Cash',
  CASH = 'Cash',
}

export const isValidPhoneNumber = (value: string): boolean => {
  return /^\d{10}$/.test(value);
};

export const isValidEmail = (value: string): boolean => {
  return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
};


export const removeWhiteSpacesFromString = (str: string): string => {
  return str.replace(/\s/g, '');
};

export const seprateUrlAndQuery = (url: string): UrlAndQueryType => {
  const urlString = url.split('?');
  return {
    queryParams: urlString.length > 1 ? urlString[1] : '',
    path: urlString[0],
  };
};

export const handleVariableImage = (event: Event, type: ImageType, viewPoint: ViewPoint): string => {
  if (
    event.supplemental_data &&
    event.supplemental_data.performer_images &&
    event.supplemental_data.performer_images.length && event.supplemental_data.performer_images[0].url
  ) {
    try {
      const images: PerformerImage = JSON.parse(event.supplemental_data.performer_images[0].url);
      if (images && viewPoint) {
        const imageType: ImageViewType = images[`${type}`];
        return imageType[`${viewPoint}`];
      }
    } catch (e) {
      return event.supplemental_data.performer_images[0].url;
    }
    return '';
  }
  return event.image;
};

export const handleDecimalValuesForDisplay = (value: string, useDecimals: boolean): string => {
  if (useDecimals && !value.includes('.')) {
    return value + '.00';
  } else if (useDecimals) {
    const splitedValue = value.split('.');
    return splitedValue[1].length === 1 ? value + '0' : value;
  }
  return value;
};

export const handleAmountFormatingString = (value: number | string | undefined): string => {
  return Number(value).toLocaleString();
};

export const isEventGuestList = (tags: string[], stockType: string, deliveryId: number) => {
  return (tags.includes(GUEST_LIST_EVENT)
    && stockType.toUpperCase() === 'HARD'
    && deliveryId === 8);
};

export const getTicketListingTranslationKey = (listing: ListingDetails, fieldName: string): string => {
  if (isEventGuestList(listing?.event?.tags || [], listing.listing.stock_type.value, listing.pricing.delivery.id)) {
    return 'precheckoutBlock.guestList';
  }
  let deliveryMethod = '';
  for (let i = 0; i < listing?.delivery_options.length; i++) {
    if (listing?.delivery_options[i].id === listing.pricing.delivery.id) {
      deliveryMethod = listing?.delivery_options[i].id.toString();
    }
  }
  return `deliveryInformation.${(listing.listing.stock_type.value).toUpperCase()}.${deliveryMethod}.${fieldName}`;
};

export const getOrderDetailsTranslationKey = (stockType: string, deliveryMethod: string, fieldName: string): string => {
  return `deliveryInformation.${stockType && stockType.toUpperCase()}.${deliveryMethod}.${fieldName}`;
};

export const getUnitDisplaySettings = (loyaltyUnitName): UnitDisplaySettings => {
  const rewardSign = loyaltyUnitName !== RewardUnits.MILES.toLowerCase() ? '$' : '';
  const useDecimals = loyaltyUnitName !== RewardUnits.MILES.toLowerCase();
  return { rewardSign, useDecimals };
};
export const setUpdatedPhoneNumber = (phoneNumber: string): void => {
  window.sessionStorage.setItem(
    'updatedPhoneNumber',
    phoneNumber,
  );
};

export const getUpdatedPhoneNumber = (): string => {
  return window.sessionStorage.getItem(
    'updatedPhoneNumber') || '';
};

export const handleOrderTotalMiles = (amount: number, loyaltyUnitName?: string): number => {
  if (loyaltyUnitName) {
    return loyaltyUnitName.toLowerCase() === RewardUnits.MILES.toLowerCase() ? round(amount) : amount;
  }
  return amount;
};

export const isValidDate = (date: Date = new Date()): boolean => {
  return isNaN(date.getTime()) ? false : true;
};

export const handleLocation = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.name) {
    location.push(venue.name);
  }
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};
export const handleLocationMobile = (venue: Venue): string => {
  const location: string[] = [];
  if (venue.city) {
    location.push(venue.city);
  }
  if (venue.state_code) {
    location.push(venue.state_code);
  }
  return location.join(', ');
};

export function shouldShowSplitPaymentPrice(event: Event): boolean {
  const isSplitPaymentEvent = event.tags?.includes(SPLIT_PAY_TAG);
  const isCreditCardPaymentEvent = event.tags?.includes(CASH_ONLY_TAG);

  return !!(isCreditCardPaymentEvent || isSplitPaymentEvent);
}

export const checkSoldOut = (event: Event, checkExclusive = false): boolean => {
  let soldOut = false;
  if (event.tags) {
    let isSoldOut = event.tags.includes(SOLD_OUT_TAG) && event.listing_count === 0;
    // in some places we need to check for exclusive events
    if (checkExclusive) {
      isSoldOut = isSoldOut && event.tags.includes(CP1_EXCLUSIVE_TAG);
    }
    const isSuppressInventorySoldOut = event.tags.includes(ALWAYS_SUPPRESS_VSINVENTORY_TAG) &&
      event.tags.includes(SOLD_OUT_TAG) && event.exclusive_listing_count === 0;
    soldOut = isSoldOut || isSuppressInventorySoldOut;
  }

  return soldOut;
};