import React from 'react';
import cx from 'classnames';

import styles from './StepperList.module.scss';

import StepperItem, { StepperItemProps } from '../../molecules/StepperItem';

export const defaultProps = {
  stepperItems: [
  ] as StepperItemProps[],
};

export type StepperListProps = {
  stepperItems?: StepperItemProps[];
  className?: string;
  activeStep?: number;
};

const StepperList: React.FC<StepperListProps> = ({
  stepperItems,
  className,
  activeStep,
}) => {
  const stepperItemArray = stepperItems?.map((stepperItem, index) => {
    const isActive: boolean = (activeStep || 1) === index + 1;
    const isComplete: boolean = (activeStep || 1) > index + 1;
    return (
    <StepperItem
      className={styles.stepperItem}
      key={index}
      {...stepperItem}
      type={isActive ? 'Selected' : 'Unselected'}
      state={isComplete ? 'Complete' : 'Incomplete'} />
    );
  });
  
  return (
    <div className={cx(styles.stepperList, className)}>
      {stepperItemArray}
    </div>
  );
};

StepperList.defaultProps = defaultProps;

export default StepperList;
