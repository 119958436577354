import React from 'react';
import styles from './App.module.scss';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import MainLayout from './components/layout/MainLayout';

const App: React.FC<{}> = () => {
  const props = {
    className: styles.layout,
  };

  return (
    <Router>
      <Switch>
        <Route path='/'>
          <MainLayout {...props} />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
