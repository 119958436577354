import React, { useContext } from 'react';
import { TopNavProps } from './TopNav';
import { TopNavPresenterProps } from './TopNav.presenter';
import categoryEvents from '../../../modules/navigation/CategoryEvents.json';
import { LinkState, TopNavTab } from '../../../modules/navigation/types';
import { TopNavContext } from '../../../modules/topNav/TopNavContext';

export interface LocationStateTopNav {
  hash: string,
  key: string,
  pathname: string
  search: string,
  canGoBack: LinkState,
}

const withInteractor = (
  Presenter: React.FC<TopNavPresenterProps>,
): React.FC<TopNavProps> => {
  const Interactor: React.FC<TopNavProps> = (props) => {
    const data: TopNavTab[] = JSON.parse(JSON.stringify(categoryEvents));
    const { currentEvent, hasNoEventMap, hasEventDescription, hasEventGuestList, isSoldOutEvent } = useContext(TopNavContext);

    return <Presenter
      {...props}
      currentEvent={currentEvent}
      hasNoEventMap={hasNoEventMap}
      hasEventDescription={hasEventDescription}
      hasEventGuestList={hasEventGuestList}
      isSoldOutEvent={isSoldOutEvent}
    />;
  };

  return Interactor;
};
export default withInteractor;
