import React from 'react';
import { LocaleProvider } from './modules/locale';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TopNavProvider } from './modules/topNav';
import { RoktLauncherContextProvider } from './modules/rokt/RoktContext';
import { ROKT_ACCOUNT_ID } from './lib/config';
const { ENVIRONMENT } = process.env;

const queryClient = new QueryClient();
const AppProvider: React.FC<{}> = ({ children }) => (
  <RoktLauncherContextProvider accountId={ROKT_ACCOUNT_ID} sandbox={ENVIRONMENT === 'production' ? false : true}>
    <LocaleProvider>
      <QueryClientProvider client={queryClient}>
        <TopNavProvider>
          {children}
        </TopNavProvider>         
      </QueryClientProvider>
    </LocaleProvider>
  </RoktLauncherContextProvider>
);

export default AppProvider;
