import { useTranslation } from 'react-i18next';
import { addQueryParam, CheckoutSteps, DateFormats, formatDate, getFiveDigitsZipCode, getTicketListingTranslationKey, getTwoDigitsCountryCode, handleAmountFormatingString, handleDecimalValuesForDisplay, RewardUnits } from '../../../lib/util';
import { ListingDetails, Order, OrderAddress } from '../../../modules/partnership';
import {
  BillingInfoBlockProps,
  defaultProps as billingInfoDefaultProps,
} from '../../blocks/BillingInfoBlock/BillingInfoBlock';
import {
  CustomerInfoBlockProps,
  defaultProps as customerInfoDefaultProps,
} from '../../blocks/CustomerInfoBlock/CustomerInfoBlock';
import {
  LoyaltyBlockProps,
  defaultProps as loyaltyDefaultProps,
} from '../../blocks/LoyaltyBlock/LoyaltyBlock';
import {
  OrderInfoProps,
  defaultProps as orderInfoDefaultProps,
} from '../../blocks/OrderInfo/OrderInfo';
import { StepperBlockProps } from '../../blocks/StepperBlock';
import {
  defaultProps as stepperItemDefaultProps,
  StepperItemProps,
} from '../../molecules/StepperItem/StepperItem';
import {
  CustomerInfoReadOnlyBlockProps,
  defaultProps as customerInfoRODefaultProps,
} from '../../blocks/CustomerInfoReadOnlyBlock/CustomerInfoReadOnlyBlock';
import {
  PaymentInformationReadOnlyBlockProps,
  defaultProps as paymentInfoRODefaultProps,
} from '../../blocks/PaymentInformationReadOnlyBlock/PaymentInformationReadOnlyBlock';
import {
  LegalBlockProps,
  defaultProps as legalDefaultProps,
} from '../../blocks/LegalBlock/LegalBlock';
import {
  ErrorBlockProps,
  defaultProps as errorBlockDefaultProps,
} from '../../blocks/ErrorBlock/ErrorBlock';
import CardAsset from '../../../resources/icons/CreditCardBlack.svg';
import GenericErrorAsset from '../../../resources/icons/Caution.svg';
import { defaultProps } from './CheckoutManualPage';
import { CHECKOUT_BRAINTREE_FORM, CHECKOUT_NONE_BRAINTREE_FORM, CHECKOUT_SHIPPING_FORM } from '../../../lib/constants';
import { round } from '../../molecules/Slider/util';
import { CostBreakdownProps } from '../../molecules/CostBreakdown';
import { AriaLiveRegions, AriaRoles } from '../../../lib/types';
import { useState } from 'react';

export type CheckoutBasicProps = {
  customerBasicProps: CustomerInfoBlockProps;
  billingBasicProps: BillingInfoBlockProps;
  loyaltyBasicProps: LoyaltyBlockProps;
  customerInfoROBascProps: CustomerInfoReadOnlyBlockProps;
  paymentInfoROBascProps: PaymentInformationReadOnlyBlockProps;
  legalBasicProps: LegalBlockProps;
  stepperBasicProps: StepperBlockProps;
  ticketBasicProps: OrderInfoProps;
  orderErrorBlockBasicProps: ErrorBlockProps;
};

export type SliderItems = {
  currentSelection: number;
  unit?: RewardUnits | string;
  dollarWorth?: number;
  loaded?: boolean;
};
export type OrderItems = {
  quantity?: number;
  rewardsUsed?: number;
  dollarsUsed?: number;
  order?: Order;
  isRewardsOnly?: boolean;
  isCp1Exclusive?: boolean;
  listing?: ListingDetails;
  email?: string;
  billingAddress?: OrderAddress;
  cardType?: string;
  lastFour?: string;
  paypalEmail?: string;
  insurancePurchased?: boolean;
};

export type CheckoutParams = {
  ticketId: string;
};

export function calculateUsedPoints(selection: number, available: number, sliderMax: number): number {
  if (selection > sliderMax) {
    return sliderMax;
  } else if (selection > available) {
    return available;
  }
  return selection;
}

/**
 * rounds decimal numbers up for slider values
 * @param value 
 */
export function roundUp(value: number): number {
  let roundedNumber = value;
  if (value % 1 != 0) {
    roundedNumber = Math.ceil(value);
  }
  return roundedNumber;
}

/**
 * Finds the block that needs to be shown at a given page
 * @param step current step
 * @param showShipping whether this flopw has shipping info in step 3
 * @returns the enum value for the step
 */
export function getCodeFromStep(step: number, showShipping: boolean): CheckoutSteps {
  let stepCode: CheckoutSteps = CheckoutSteps.CUSTOMERINFO;
  switch (step) {
    case (1):
      stepCode = CheckoutSteps.CUSTOMERINFO;
      break;
    case (2):
      stepCode = CheckoutSteps.BILLINGINFO;
      break;
    case (3):
      stepCode = showShipping ? CheckoutSteps.SHIPPINGINFO : CheckoutSteps.PAYMENTINFO;
      break;
    case (4):
      stepCode = showShipping ? CheckoutSteps.PAYMENTINFO : CheckoutSteps.CONFIRMATION;
      break;
    case (5):
      stepCode = CheckoutSteps.CONFIRMATION;
      break;
  }
  return stepCode;
}

export function getFormFromCode(code: CheckoutSteps, isRewardsOnly: boolean | undefined): string {
  if (code === CheckoutSteps.PAYMENTINFO && !isRewardsOnly) {
    return CHECKOUT_BRAINTREE_FORM;
  } else if (code === CheckoutSteps.PAYMENTINFO && isRewardsOnly) {
    return CHECKOUT_NONE_BRAINTREE_FORM;
  } 
  if (code === CheckoutSteps.SHIPPINGINFO || code === CheckoutSteps.BILLINGINFO) {
    return CHECKOUT_SHIPPING_FORM;
  }
  return '';
}

/**
 * Determines starting value for slider based on
 * params about current checkout flow
 * @param isRewardsOnly 
 * @param isSplitPay 
 * @param sliderMaximum 
 * @param useDecimals 
 * @param unit 
 * @param valuePerPoint 
 * @returns 
 */
export function getSliderDefaultState(
  isRewardsOnly: boolean | undefined,
  isSplitPay: boolean | undefined,
  sliderMaximum: number,
  useDecimals: boolean,
  valuePerPoint: number,
): SliderItems {
  let startingValue = 0;
  const middleValue = sliderMaximum / 2;
  let isMiddle = false;

  if (isRewardsOnly) {
    startingValue = sliderMaximum;
  } else if (isSplitPay) {
    startingValue = middleValue;
    isMiddle = true;
  } else {
    startingValue = 0;
  }

  return {
    currentSelection: !useDecimals && isMiddle ? round(startingValue) : round(startingValue, 2),
    dollarWorth: startingValue * valuePerPoint,
  };
}

/**
 * @param step - the current step in the checkout flow
 * @param query - the current query present
 * @returns exactly the same query as passed, but with step added by 1
 */
export function updateStepInQuery(step: number, query: string): string {
  return addQueryParam(query, { step: step.toString() });
}

/**
 * Formats title to following format:
 * Yankee Stadium, Bronx NY
 */
export function formatTitle(venue: string, city: string, stateCode: string): string {
  return venue + ', ' + city + ' ' + stateCode;
}

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const BuildCheckoutProps = (
  listingDetails: ListingDetails | null,
  isHardTicket: boolean,
  isCp1Exclusive: boolean,
  isRewardsOnly: boolean,
  useDecimals: boolean,
  rewardSign: string,
  emailInvalid: boolean,
  isInNewYork: boolean,
  preloadInputField: boolean,
): CheckoutBasicProps => {
  const { t } = useTranslation();
  const [showPromoInput, setShowPromoInput] = useState(preloadInputField);
  
  const customerInfoProps: CustomerInfoBlockProps = {
    ...customerInfoDefaultProps,
    blockTitle: {
      ...customerInfoDefaultProps.blockTitle,
      value: t('customerInfo.title'),
    },
    emailField: {
      ...customerInfoDefaultProps.emailField,
      ariaLive: AriaLiveRegions.ASSERTIVE,
      label: {
        ...customerInfoDefaultProps.emailField.label,
        value: t('customerInfo.email'),
      },
      textInput: {
        ...customerInfoDefaultProps.emailField.textInput,
      },
      error: {
        ...customerInfoDefaultProps.emailField.error,
        value: emailInvalid ? t('customerInfo.invalidErrorMessage') : t('customerInfo.fieldErrorMessage'),
        ariaRole: AriaRoles.ALERT,
        ariaAtomic: true,
      },
    },
    message: {
      ...customerInfoDefaultProps.message,
      value: t('customerInfo.message'),
    },
  };
  const billingInfoProps: BillingInfoBlockProps = {
    ...billingInfoDefaultProps,
    blockTitle: {
      ...billingInfoDefaultProps.blockTitle,
      value: t('billingInfo.title'),
    },
    firstName: {
      ...billingInfoDefaultProps.firstName,
      label: {
        ...billingInfoDefaultProps.firstName.label,
        value: t('billingInfo.firstName'),
      },
      description: {
        ...billingInfoDefaultProps.firstName.description,
        value: 'account?.first_name',
      },
    },
    lastName: {
      ...billingInfoDefaultProps.lastName,
      label: {
        ...billingInfoDefaultProps.lastName.label,
        value: t('billingInfo.lastName'),
      },
      description: {
        ...billingInfoDefaultProps.lastName.description,
        value: 'account?.last_name',
      },
    },
    address: {
      ...billingInfoDefaultProps.address,
      label: {
        ...billingInfoDefaultProps.address.label,
        value: t('billingInfo.address'),
      },
      description: {
        ...billingInfoDefaultProps.address.description,
        value: 'account?.address.address_line1',
      },
    },
    secondaryAddress: {
      ...billingInfoDefaultProps.secondaryAddress,
      label: {
        ...billingInfoDefaultProps.secondaryAddress.label,
        value: 'account?.address.address_line2 ? t(billingInfo.secondaryAddress) : ',
      },
      description: {
        ...billingInfoDefaultProps.secondaryAddress.description,
        value: 'account?.address.address_line2',
      },
    },
    city: {
      ...billingInfoDefaultProps.city,
      label: {
        ...billingInfoDefaultProps.city.label,
        value: t('billingInfo.city'),
      },
      description: {
        ...billingInfoDefaultProps.city.description,
        value: 'account?.address.city',
      },
    },
    state: {
      ...billingInfoDefaultProps.state,
      label: {
        ...billingInfoDefaultProps.state.label,
        value: t('billingInfo.state'),
      },
      description: {
        ...billingInfoDefaultProps.state.description,
        value: 'account?.address.state_code',
      },
    },
    postalCode: {
      ...billingInfoDefaultProps.postalCode,
      label: {
        ...billingInfoDefaultProps.postalCode.label,
        value: t('billingInfo.postalCode'),
      },
      description: {
        ...billingInfoDefaultProps.postalCode.description,
        value: 'account ? getFiveDigitsZipCode(account.address.country_code, account.address.postal_code) : ',
      },
    },
    country: {
      ...billingInfoDefaultProps.country,
      label: {
        ...billingInfoDefaultProps.country.label,
        value: t('billingInfo.country'),
      },
      description: {
        ...billingInfoDefaultProps.country.description,
        value: 'getTwoDigitsCountryCode(account?.address.country_code || ',
      },
    },
    phoneNumber: {
      ...billingInfoDefaultProps.phoneNumber,
      label: {
        ...billingInfoDefaultProps.phoneNumber.label,
        value: t('billingInfo.phoneNumberMobile'),
      },
      description: {
        ...billingInfoDefaultProps.phoneNumber.description,
        value: 'account?.phone',
      },
    },
    phoneNumberField: {
      ...billingInfoDefaultProps.phoneNumberField,
      label: {
        ...billingInfoDefaultProps.phoneNumberField.label,
        value: t('billingInfo.phoneNumberMobile'),
      },
      textInput: {
        ...billingInfoDefaultProps.phoneNumberField?.textInput,
        textValue: 'account?.phone',
        maxLength: 10,
        removeChar: /[^0-9]/g,
      },
      error: {
        ...billingInfoDefaultProps.phoneNumberField.error,
        value: t('billingInfo.fieldErrorMessage', {
          field: t('billingInfo.phoneNumber'),
        }),
      },
    },
    phoneNumberToolTip: {
      text: t('billingInfo.phoneNumberToolTip'),
      placement: 'auto-end',
    },
    toolTipIcon: {
      icon: {
        asset: 'Info',
      },
      ariaLabelledBy: 'ttid',
      type: 'Icon',
    },
  };

  const loyaltyProps: LoyaltyBlockProps = {
    ...loyaltyDefaultProps,
    blockTitle: {
      ...loyaltyDefaultProps.blockTitle,
      value: t('loyalty.title'),
    },
    description: {
      ...loyaltyDefaultProps.description,
      value: t('loyalty.description'),
    },
    highlightMessage: {
      ...loyaltyDefaultProps.highlightMessage,
      message: {
        ...loyaltyDefaultProps.highlightMessage.message,
        value: t('loyalty.insufficientRewards'),
      },
    },
  };
  const customerInfoROProps: CustomerInfoReadOnlyBlockProps = {
    ...customerInfoRODefaultProps,
    blockTitle: {
      ...customerInfoRODefaultProps.blockTitle,
      value: t('customerInfo.title'),
    },
    textItem: {
      ...customerInfoRODefaultProps.textItem,
      label: {
        ...customerInfoRODefaultProps.textItem.label,
        value: t('customerInfo.email'),
      },
      description: {
        ...customerInfoRODefaultProps.textItem.description,
      },
    },
  };
  const paymentInfoROProps: PaymentInformationReadOnlyBlockProps = {
    ...paymentInfoRODefaultProps,
    edit: {
      ...paymentInfoRODefaultProps.edit,
      text: {
        ...paymentInfoRODefaultProps.edit.text,
        value: t('billingInfo.edit'),
      },
    },
    blockTitle: {
      ...paymentInfoRODefaultProps.blockTitle,
      value: t('billingInfo.readOnly'),
    },
    phoneNumber: {
      ...paymentInfoRODefaultProps.phoneNumber,
      label: {
        ...paymentInfoRODefaultProps.phoneNumber.label,
        value: t('billingInfo.phoneNumber'),
      },
      description: {
        ...paymentInfoRODefaultProps.phoneNumber.description,
        value: 'account?.phone',
      },
    },
    paymentMethod: {
      ...paymentInfoRODefaultProps.paymentMethod,
      label: {
        ...paymentInfoRODefaultProps.paymentMethod.label,
        value: t('billingInfo.paymentMethod'),
      },
      description: {
        ...paymentInfoRODefaultProps.paymentMethod.description,
      },
      showCard: false,
    },
  };
  const legalProps: LegalBlockProps = {
    ...legalDefaultProps,
    blockTitle: {
      ...legalDefaultProps.blockTitle,
      value: t('legal.title'),
    },
    checkBoxItem: {
      ...legalDefaultProps.checkBoxItem,
    },
  };
  const stepperItemProps: StepperItemProps[] = [];
  for (let i = 1; i <= (isHardTicket ? 5 : 4); i++) {
    const code: CheckoutSteps = getCodeFromStep(i, isHardTicket);
    stepperItemProps.push({
      ...stepperItemDefaultProps,
      stepNumber: {
        ...stepperItemDefaultProps.stepNumber,
        value: t('stepperItems.stepTemplate', { step: i }),
      },
      stepLabel: {
        ...stepperItemDefaultProps.stepLabel,
        value: t(`stepperItems.${code}`),
      },
    });
  }
  const stepperBlockProps: StepperBlockProps = {
    ...defaultProps.stepperBlock,
    stepperList: {
      ...defaultProps.stepperBlock.stepperList,
      stepperItems: stepperItemProps,
    },
  };
  const additionalFees: CostBreakdownProps = {
    serviceFee: {
      ...orderInfoDefaultProps.costBreakdown.serviceFee,
      item: {
        ...orderInfoDefaultProps.costBreakdown.serviceFee?.item,
        value: t('ticketInfo.orderTotal.serviceFee'),
      },
      price: {
        ...orderInfoDefaultProps.costBreakdown.serviceFee?.price,
        value: t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
          charge: handleDecimalValuesForDisplay(formatter.format(listingDetails?.pricing.fulfillment_fee || 0), useDecimals),
        }),
      },
    },
    delivery: {
      ...orderInfoDefaultProps.costBreakdown.delivery,
      item: {
        ...orderInfoDefaultProps.costBreakdown.delivery?.item,
        value: t('ticketInfo.orderTotal.delivery'),
      },
      price: {
        ...orderInfoDefaultProps.costBreakdown.delivery?.price,
        value: isInNewYork && !isHardTicket ? t('ticketInfo.orderTotal.free') : t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
          charge: handleDecimalValuesForDisplay(formatter.format(listingDetails?.pricing.delivery_fee || 0), useDecimals),
        }),
      },
    },
    tax: {
      ...orderInfoDefaultProps.costBreakdown.delivery,
      item: {
        ...orderInfoDefaultProps.costBreakdown.delivery?.item,
        value: t('ticketInfo.orderTotal.tax'),
      },
      price: {
        ...orderInfoDefaultProps.costBreakdown.delivery?.price,
        value: t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
          charge: handleDecimalValuesForDisplay(formatter.format(listingDetails?.pricing.taxes || 0), useDecimals),
        }),
      },
    },
  };

  const valuePerPoint = 1;
  const ticketInfoProps: OrderInfoProps = {
    ...orderInfoDefaultProps,
    costTitle: {
      ...orderInfoDefaultProps.costTitle,
      value: t('ticketInfo.orderTotal.totalCharge'),
    },
    costBreakdown: {
      ...orderInfoDefaultProps.costBreakdown,
      ordarTotal: {
        ...orderInfoDefaultProps.costBreakdown.ordarTotal,
        item: {
          ...orderInfoDefaultProps.costBreakdown.ordarTotal?.item,
          value: t('ticketInfo.orderTotal.orderTotal'),
        },
        price: {
          ...orderInfoDefaultProps.costBreakdown.ordarTotal?.price,
          value:
            isRewardsOnly ?
              t('ticketInfo.orderTotal.rewardsOnlyTemplate', {
                value: `${rewardSign}${handleDecimalValuesForDisplay(handleAmountFormatingString((listingDetails?.pricing.total || 1) / valuePerPoint), useDecimals)}`,
                unit: 'account ? handleDisplayUnitName(account) : ',
              })
              :
              t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
                charge: handleDecimalValuesForDisplay(formatter.format(listingDetails?.pricing.total || 0), useDecimals),
              }),
        },
      },
      tickets: {
        ...orderInfoDefaultProps.costBreakdown.tickets,
        item: {
          ...orderInfoDefaultProps.costBreakdown.tickets?.item,
          value: t('ticketInfo.orderTotal.ticketsTemplate', {
            quantity: listingDetails?.pricing.quantity,
            pricePer: isRewardsOnly ? `${rewardSign}${handleDecimalValuesForDisplay(handleAmountFormatingString(listingDetails ? listingDetails.pricing.price_per / valuePerPoint : 0), useDecimals)}`
              : handleDecimalValuesForDisplay(formatter.format(listingDetails?.pricing.price_per || 0), useDecimals),
          }),
        },
        price: {
          ...orderInfoDefaultProps.costBreakdown.tickets?.price,
          value:
            isRewardsOnly ?
              t('ticketInfo.orderTotal.rewardsOnlyTemplate', {
                value: `${rewardSign}${handleDecimalValuesForDisplay(handleAmountFormatingString(listingDetails ? listingDetails.pricing.subtotal / valuePerPoint : 0), useDecimals)}`,
                unit: 'account ? handleDisplayUnitName(account) : ',
              })
              :
              t('ticketInfo.orderTotal.chargeNoCurrencyTemplate', {
                charge: handleDecimalValuesForDisplay(formatter.format(listingDetails?.pricing.subtotal || 0), useDecimals),
              }),
        },
      },
      showPromoButton: !showPromoInput,
      showPromoInput: showPromoInput,
      promoInput: {
        ...orderInfoDefaultProps.costBreakdown.promoInput,
      },
      promoLabel: {
        ...orderInfoDefaultProps.costBreakdown.tickets,
        item: {
          ...orderInfoDefaultProps.costBreakdown.tickets?.item,
          value: t('ticketInfo.orderTotal.promoCode'),
        },
      },
      applyButton: {
        ...orderInfoDefaultProps.costBreakdown?.applyButton,
        text: {
          ...orderInfoDefaultProps.costBreakdown?.applyButton?.text,
          value: t('ticketInfo.orderTotal.promoButton'),
        },
      },
      button: {
        ...orderInfoDefaultProps.costBreakdown?.button,
        text: {
          ...orderInfoDefaultProps.costBreakdown?.button?.text,
          value: t('ticketInfo.orderTotal.promoCode'),
        },
        onButtonClicked: () => setShowPromoInput(true),
      },
      ...additionalFees,
    },
    title: {
      ...orderInfoDefaultProps.title,
      value: listingDetails?.event.name,
    },
    dateAndTime: {
      ...orderInfoDefaultProps.dateAndTime,
      value: listingDetails?.event.local_date
        ? formatDate(new Date(listingDetails?.event.local_date), t, DateFormats.TICKETINFO)
        : '',
    },
    venue: {
      ...orderInfoDefaultProps.venue,
      value: formatTitle(
        listingDetails?.event.venue.name || '',
        listingDetails?.event.venue.city || '',
        listingDetails?.event.venue.state_code || '',
      ),
    },
    seatInfo: {
      ...orderInfoDefaultProps.seatInfo,
      section: {
        ...orderInfoDefaultProps.seatInfo?.section,
        label: {
          ...orderInfoDefaultProps.seatInfo?.section?.label,
          value: t('ticketInfo.seatInfo.section'),
        },
        description: {
          ...orderInfoDefaultProps.seatInfo?.section?.description,
          value: listingDetails?.listing.verbose_section_name || listingDetails?.listing.seating.section,
        },
      },
      row: {
        ...orderInfoDefaultProps.seatInfo?.row,
        label: {
          ...orderInfoDefaultProps.seatInfo?.row?.label,
          value: t('ticketInfo.seatInfo.row'),
        },
        description: {
          ...orderInfoDefaultProps.seatInfo?.row?.description,
          value: listingDetails?.listing.seating.row,
        },
      },
      quantity: {
        ...orderInfoDefaultProps.seatInfo?.quantity,
        label: {
          ...orderInfoDefaultProps.seatInfo?.quantity?.label,
          value: t('ticketInfo.seatInfo.quantity'),
        },
        description: {
          ...orderInfoDefaultProps.seatInfo?.quantity?.description,
        },
      },
    },
    sellerNotes: {
      ...orderInfoDefaultProps.sellerNotes,
      label: {
        ...orderInfoDefaultProps.sellerNotes?.label,
        value: t('ticketInfo.sellerNotes'),
      },
      description: {
        ...orderInfoDefaultProps.sellerNotes?.description,
        value: listingDetails?.listing.notes,
      },
    },
    deliveryMethod: {
      ...orderInfoDefaultProps.sellerNotes,
      label: {
        ...orderInfoDefaultProps.sellerNotes?.label,
        value: t('ticketInfo.deliveryMethod'),
      },
      description: {
        ...orderInfoDefaultProps.deliveryMethod?.description,
        value: listingDetails ? t(getTicketListingTranslationKey(listingDetails, 'delivery_method')) : '',
      },
    },
    faceValue: isInNewYork ? {
      ...orderInfoDefaultProps.sellerNotes,
      label: {
        ...orderInfoDefaultProps.sellerNotes?.label,
        value: !listingDetails?.listing.face_value ? `${t('ticketInfo.faceValueLabel')}${t('ticketInfo.noFaceValue')}` 
          : t('ticketInfo.faceValue', { value: formatter.format(listingDetails?.listing.face_value) }),
      },
    } : {},
  };
  const orderErrorBlockProps: ErrorBlockProps = {
    ...errorBlockDefaultProps,
    image: {
      srcValue: GenericErrorAsset,
    },
    title: {
      ...errorBlockDefaultProps?.title,
      value: t('orderError.title'),
    },
    message: {
      ...errorBlockDefaultProps?.message,
      value: t('orderError.message'),
    },
  };
  return {
    customerBasicProps: customerInfoProps,
    billingBasicProps: billingInfoProps,
    loyaltyBasicProps: loyaltyProps,
    customerInfoROBascProps: customerInfoROProps,
    paymentInfoROBascProps: paymentInfoROProps,
    legalBasicProps: legalProps,
    stepperBasicProps: stepperBlockProps,
    ticketBasicProps: ticketInfoProps,
    orderErrorBlockBasicProps: orderErrorBlockProps,
  };
};
