import React from 'react';
import cx from 'classnames';

import styles from './TextItem.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export type TextItemStyleType = 'BoldedLabel' | 'SubduedLabel';

export const defaultProps = {
  style: 'SubduedLabel' as TextItemStyleType,
  label: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  description: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
};

export type TextItemProps = {
  style?: TextItemStyleType;
  label?: TextProps;
  description?: TextProps;
  className?: string;
};

const TextItem: React.FC<TextItemProps> = ({
  style,
  label,
  description,
  className,
}) => {

  const currentStyle = styles[`textItem${style}`];

  return (
    <div className={cx(currentStyle, className)}>
      <Text
        className={styles.label}
        {...label} />
      <Text
        className={styles.description}
        {...description} />
    </div>
  );
};

TextItem.defaultProps = defaultProps;

export default TextItem;
