import React from 'react';
import cx from 'classnames';

import styles from './Footer.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import Logo, { LogoProps } from '../../atoms/Logo';
import FooterMenuList, { FooterMenuListProps } from '../../molecules/FooterMenuList';

export const defaultProps = {
  divider: {
    type: 'Horizontal',
    style: 'Thin',
    colour: 'DigitalGrey15',
  } as DividerProps,
  logo: {
    asset: 'LogoC1Default',
  } as LogoProps,
  footerMenuList: {
    buttons: [
    ],
  } as FooterMenuListProps,
};

export type FooterProps = {
  divider?: DividerProps;
  logo?: LogoProps;
  footerMenuList?: FooterMenuListProps;
  className?: string;
  type?: string;
};

const Footer: React.FC<FooterProps> = ({
  divider,
  logo,
  footerMenuList,
  className,
  type,
}) => {
  return (
    <div className={cx(styles.footer, className)}>
      <Divider
        className={styles.divider}
        {...divider} />
      <div className={styles.content}>
        <Logo
          className={styles.logo}
          {...logo} />
        {type !== 'SignedOut' ? <FooterMenuList
          className={styles.footerMenuList}
          {...footerMenuList} /> : null}
      </div>
    </div>
  );
};

Footer.defaultProps = defaultProps;

export default Footer;
