// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { createContext, useContext, useEffect, useState } from 'react';

export const RoktLauncherContext = createContext<any | null>(null);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRoktLauncher() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return useContext(RoktLauncherContext);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function RoktLauncherContextProvider({ children, accountId, sandbox = false }) {
  const [launcher, setLauncher] = useState<any | null>(null);

  useEffect(() => {
    void (async () => {
      // Guards against Rokt script being still loaded into the application when the context is created     
      await new Promise<void>(resolve => window.Rokt ? resolve() 
        : (document.getElementById('rokt-launcher').onload = resolve));
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const launcherInstance = await window.Rokt.createLauncher({
          accountId: accountId,
          sandbox: sandbox,
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setLauncher(launcherInstance);
      } catch (exception) {
        // do something with rokt errors
      }
    })();

    return () => {
      if (launcher) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        launcher.terminate();
      }
    };
  }, [accountId, sandbox]);

  // Return the context provider
  return <RoktLauncherContext.Provider value={launcher}>{children}</RoktLauncherContext.Provider>;
}