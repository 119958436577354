import { MutableRefObject, RefObject } from 'react';

declare global {
  interface Window {
    Rokt?: any;
  }
}

export type RefPropType<T> = RefObject<T> | MutableRefObject<T> | ((element: T) => void);

export type TicketDeliveryInfo = {
  ticketListing: string;
  ticketDetails: string;
  deliveryMethod: string;
  confirmation_page: string;
};

export type OrderParams = {
  orderId: string;
};

export type UnitDisplaySettings = {
  rewardSign: '$' | '';
  useDecimals: boolean;
};

export enum AriaRoles {
  ALERT = 'alert',
}

export enum AriaLiveRegions {
  ASSERTIVE = 'assertive',
  OFF = 'off',
  POLITE = 'polite',
}
