import React from 'react';
import cx from 'classnames';

import styles from './LegalBlock.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';
import CheckBoxItem, { CheckBoxItemProps } from '../../atoms/CheckBoxItem';

export const defaultProps = {
  blockTitle: {
    type: 'Subheading',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  description: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  bottomDescription: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
  checkBoxItem: {
    state: 'Unselected',
    icon: {
      asset: 'CheckboxEmpty',
      style: 'DigitalGrey100',
    },
    text: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as CheckBoxItemProps,
  button: {
    type: 'Text',
    style: 'ContainedGreen',
    size: 'Large',
    text: {
      type: 'Subheading',
      size: 'Medium',
      style: 'Regular',
      colour: 'BaseLight',
      align: 'Left',
    },
  } as ButtonProps,
};

export type LegalBlockProps = {
  blockTitle?: TextProps;
  description?: TextProps;
  bottomDescription?: TextProps;
  className?: string;
  button?: ButtonProps;
  checkBoxItem?: CheckBoxItemProps;
  showCheckBox?: boolean;
};

const LegalBlock: React.FC<LegalBlockProps> = ({
  blockTitle,
  description,
  bottomDescription,
  className,
  button,
  checkBoxItem,
  showCheckBox,
}) => {
  return (
    <div className={cx(styles.legalBlock, className)}>
      <div className={styles.topContent}>
        <Text
          className={styles.blockTitle}
          {...blockTitle} />
        <div className={styles.legalTerms}>
          {showCheckBox ? <CheckBoxItem
            className={styles.checkBoxItem}
            {...checkBoxItem} /> : ''}
          <Text
            className={styles.description}
            {...description} />
        </div>
        <Text
          className={styles.bottomDescription}
          {...bottomDescription} />
      </div>
      
      <Button
        className={styles.button}
        {...button} />
    </div>
  );
};

LegalBlock.defaultProps = defaultProps;

export default LegalBlock;
