import React from 'react';
import cx from 'classnames';

import styles from './FooterMenuList.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  buttons: [
  ] as ButtonProps[],
};

export type FooterMenuListProps = {
  buttons?: ButtonProps[];
  className?: string;
};

const FooterMenuList: React.FC<FooterMenuListProps> = ({
  buttons,
  className,
}) => {
  const buttonArray = buttons?.map((button, index) => (
    <Button
      className={styles.button}
      key={index}
      {...button} />
  ));
  
  return (
    <div className={cx(styles.footerMenuList, className)}>
      {buttonArray}
    </div>
  );
};

FooterMenuList.defaultProps = defaultProps;

export default FooterMenuList;
