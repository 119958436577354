/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import braintree, { Client } from 'braintree-web';
import { BraintreeContext } from '.';

export type BraintreeElementsProps = {
  styles?: any;
  isDisabled?: boolean;
  children: (
    cardNumberRef: RefObject<HTMLDivElement>,
    expiryRef: RefObject<HTMLDivElement>,
    cvvRef: RefObject<HTMLDivElement>,
    error: Error | null,
    loading?: boolean,
  ) => JSX.Element;
};

const BraintreeElements: React.FC<BraintreeElementsProps> = (props) => {
  const { client, error, loading, instance, setInstance } = useContext(BraintreeContext);

  const cardNumberRef = useRef<HTMLDivElement>(null);
  const expiryRef = useRef<HTMLDivElement>(null);
  const cvvRef = useRef<HTMLDivElement>(null);

  const { styles, children, isDisabled } = props;


  const createHostedFields = useCallback(
    async (
      _client: Client,
      _styles: any,
      numberContainer: HTMLDivElement,
      expiryContainer: HTMLDivElement,
      cvvContainer: HTMLDivElement,
    ) => {
      try {
        const currentFields = instance?.getState().fields;
        const sameInstance = currentFields?.number.container === numberContainer &&
          currentFields?.expirationDate.container === expiryContainer &&
          currentFields?.cvv.container === cvvContainer;

        if (!sameInstance) {
          const newInstance = await braintree.hostedFields.create({
            client: _client,
            styles: _styles,
            fields: {
              number: {
                container: numberContainer,
                placeholder: '',
              },
              expirationDate: {
                container: expiryContainer,
                placeholder: 'MM/YY',
              },
              cvv: {
                container: cvvContainer,
                placeholder: 'CVV',
              },
            },
          });
          setInstance(newInstance);
        }

      } catch (e) {
        console.error(e);
      }
    },
    [instance, setInstance],
  );

  useEffect(() => {
    if (isDisabled) {
      cardNumberRef?.current?.children[0]?.setAttribute('tabIndex', '-1');
      cvvRef?.current?.children[0]?.setAttribute('tabIndex', '-1');
      expiryRef?.current?.children[0]?.setAttribute('tabIndex', '-1');
    } else {
      cardNumberRef?.current?.children[0]?.setAttribute('tabIndex', '0');
      cvvRef?.current?.children[0]?.setAttribute('tabIndex', '0');
      expiryRef?.current?.children[0]?.setAttribute('tabIndex', '0');
    }
  }, [isDisabled]);


  useEffect(() => {
    if (
      client &&
      styles &&
      cardNumberRef.current &&
      expiryRef.current &&
      cvvRef.current
    ) {
      void createHostedFields(
        client,
        styles,
        cardNumberRef.current,
        expiryRef.current,
        cvvRef.current,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, styles, cardNumberRef, expiryRef, cvvRef]);

  return children(cardNumberRef, expiryRef, cvvRef, error, loading);
};

export default BraintreeElements;
