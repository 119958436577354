import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { useContext } from 'react';
import { BraintreeContext, BraintreeProvider } from '../../../modules/braintree';
import { CardInfoBlockProps } from './CardInfoBlock';
import { CardInfoBlockPresenterProps } from './CardInfoBlock.presenter';
import { PAYPAL_CLIENT_ID } from '../../../lib/config';

const initialOptions = {
  'client-id': PAYPAL_CLIENT_ID,
  'data-user-id-token': 'sandbox_5rftsqbv_jqjyqq4jfxdxb7nt',
  currency: 'USD',
  commit: false,
  intent: 'authorize',
  components: 'buttons',
};

const withInteractor = (
  Presenter: React.FC<CardInfoBlockPresenterProps>,
): React.FC<CardInfoBlockProps> => {
  const Interactor: React.FC<CardInfoBlockProps> = (props) => {
    const { instance, authToken, loading, client } = useContext(BraintreeContext);

    return <Presenter 
      {...props}
      client={client}
      isLoading={loading}
      authToken={authToken}
      instance={instance} />;
  };
  const BraintreeWrapper: React.FC<CardInfoBlockProps> = (props) => {
    return (
    <PayPalScriptProvider options={initialOptions}>
        <BraintreeProvider>
          <Interactor {...props} />
        </BraintreeProvider>
    </PayPalScriptProvider>
    );
  };
  return BraintreeWrapper;
};
export default withInteractor;
