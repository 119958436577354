import React from 'react';
import { useQuery, useMutation } from 'react-query';
import { MyTicketsPageProps } from '.';
import { MyTicketsPagePresenterProps } from './MyTicketsPage.presenter';
import { getEvent, getFilesByName, getMyOrder } from '../../../modules/partnership/api';
import { useParams } from 'react-router-dom';
import { OrderParams } from '../../../lib/types';


const withInteractor = (
  Presenter: React.FC<MyTicketsPagePresenterProps>,
): React.FC<MyTicketsPageProps> => {
  const Interactor: React.FC<MyTicketsPageProps> = (props) => {
    const { mutateAsync: getEventById, isLoading: eventLoading } = useMutation(getEvent);
    const { orderId } = useParams<OrderParams>();
    const { error, data, isLoading: ordersLoading } = useQuery(
      ['getMyOrder', orderId],
      () => getMyOrder(orderId),
    );
    const { mutateAsync: getFile } = useMutation(getFilesByName);
    return (
      <Presenter
        {...props}
        pageLoading={ordersLoading}
        eventLoading={eventLoading}
        error={error as Error}
        data={data}
        getEvent={getEventById}
        downloadTickets={getFile}
      />
    );
  };
  return Interactor;
};
export default withInteractor;