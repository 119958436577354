import React from 'react';
import cx from 'classnames';

import styles from './Icon.module.scss';

import CloseAsset from '../../../resources/icons/Close.svg';
import CloseCircleAsset from '../../../resources/icons/CloseCircle.svg';
import CloseCircleFillAsset from '../../../resources/icons/CloseCircleFill.svg';
import CheckboxEmptyAsset from '../../../resources/icons/CheckboxEmpty.svg';
import CheckboxFilledAsset from '../../../resources/icons/CheckboxFilled.svg';
import ChevronUpAsset from '../../../resources/icons/ChevronUp.svg';
import ChevronUpFilledAsset from '../../../resources/icons/ChevronUpFilled.svg';
import ChevronDownAsset from '../../../resources/icons/ChevronDown.svg';
import ChevronDownFilledAsset from '../../../resources/icons/ChevronDownFilled.svg';
import ChevronLeftAsset from '../../../resources/icons/ChevronLeft.svg';
import ChevronLeftFilledAsset from '../../../resources/icons/ChevronLeftFilled.svg';
import ChevronRightAsset from '../../../resources/icons/ChevronRight.svg';
import ChevronRightFilledAsset from '../../../resources/icons/ChevronRightFilled.svg';
import SearchSmallAsset from '../../../resources/icons/SearchSmall.svg';
import SearchAsset from '../../../resources/icons/Search.svg';
import LocationMarkerAsset from '../../../resources/icons/LocationMarker.svg';
import LocationMarkerSmallAsset from '../../../resources/icons/LocationMarkerSmall.svg';
import CalendarAsset from '../../../resources/icons/Calendar.svg';
import VSAsset from '../../../resources/icons/VS.svg';
import CalendarLargeAsset from '../../../resources/icons/CalendarLarge.svg';
import ProfileAsset from '../../../resources/icons/Profile.svg';
import DeliveryAsset from '../../../resources/icons/Delivery.svg';
import CheckmarkInCircleAsset from '../../../resources/icons/CheckmarkInCircle.svg';
import TicketBigAsset from '../../../resources/icons/TicketBig.svg';
import FootballAsset from '../../../resources/icons/Football.svg';
import TicketAsset from '../../../resources/icons/Ticket.svg';
import TicketWithLinesAsset from '../../../resources/icons/TicketWithLines.svg';
import ClockAsset from '../../../resources/icons/Clock.svg';
import ClockLargeAsset from '../../../resources/icons/ClockLarge.svg';
import ShirtAsset from '../../../resources/icons/Shirt.svg';
import ArenaAsset from '../../../resources/icons/Arena.svg';
import CheckmarkAsset from '../../../resources/icons/Checkmark.svg';
import SettingsAsset from '../../../resources/icons/Settings.svg';
import ThumbtackAsset from '../../../resources/icons/Thumbtack.svg';
import MinusAsset from '../../../resources/icons/Minus.svg';
import PlusAsset from '../../../resources/icons/Plus.svg';
import MailAsset from '../../../resources/icons/Mail.svg';
import PrinterAsset from '../../../resources/icons/Printer.svg';
import TicketCutAsset from '../../../resources/icons/TicketCut.svg';
import MobileScanAsset from '../../../resources/icons/MobileScan.svg';
import InfoAsset from '../../../resources/icons/Info.svg';
import WarningAsset from '../../../resources/icons/Warning.svg';
import WarningOutlineAsset from '../../../resources/icons/WarningOutline.svg';
import MasterCardAsset from '../../../resources/icons/MasterCard.svg';
import VisaAsset from '../../../resources/icons/Visa.svg';
import AmericanExpressAsset from '../../../resources/icons/AmericanExpress.svg';
import DiscoverAsset from '../../../resources/icons/Discover.svg';
import PaypalAsset from '../../../resources/icons/Paypal.svg';
import StarAsset from '../../../resources/icons/Star.svg';
import PhoneAsset from '../../../resources/icons/Phone.svg';

export type IconAssetType = 'Close' | 'CloseCircle' | 'CloseCircleFill' | 'CheckboxEmpty' | 'CheckboxFilled' | 'ChevronUp' | 'ChevronUpFilled' | 'ChevronDown' | 'ChevronDownFilled' | 'ChevronLeft' | 'ChevronLeftFilled' | 'ChevronRight' | 'ChevronRightFilled' | 'SearchSmall' | 'Search' | 'LocationMarker' | 'LocationMarkerSmall' | 'Calendar' | 'VS' | 'CalendarLarge' | 'Profile' | 'Delivery' | 'CheckmarkInCircle' | 'TicketBig' | 'Football' | 'Ticket' | 'TicketWithLines' | 'Clock' | 'ClockLarge' | 'Shirt' | 'Arena' | 'Checkmark' | 'Settings' | 'Thumbtack' | 'Minus' | 'Plus' | 'Mail' | 'Printer' | 'TicketCut' | 'MobileScan' | 'Info' | 'Warning' | 'WarningOutline' | 'MasterCard' | 'Visa' | 'AmericanExpress' | 'Discover' | 'Star' | 'Phone' | 'Paypal';

export type IconStyleType = 'DigitalGrey80' | 'ActionBase' | 'White' | 'DigitalGrey100' | 'ActionHover' | 'ActionActive' | 'DigitalGrey30' | 'DigitalGrey60';

export const defaultProps = {
  asset: 'Visa' as IconAssetType,
  style: 'DigitalGrey80' as IconStyleType,
  contentAlt: '',
};

export type IconProps = {
  asset?: IconAssetType;
  style?: IconStyleType;
  contentAlt?: string;
  className?: string;
  closeAlt?: string;
  htmlTagImgAssetCheckboxEmptyStyleDigitalGrey100AssetKeyContentAlt?: string;
  htmlTagImgAssetMasterCardStyleDigitalGrey80AssetKeyContentAlt?: string;
  htmlTagImgAssetVisaStyleDigitalGrey80AssetKeyContentAlt?: string;
};

const Icon: React.FC<IconProps> = ({
  asset,
  style,
  contentAlt,
  className,
}) => {

  const currentStyle = styles[`icon${asset}${style}`];

  let content = '';
  switch (asset) {
    case 'Close':
      content = CloseAsset;
      break;
    case 'CloseCircle':
      content = CloseCircleAsset;
      break;
    case 'CloseCircleFill':
      content = CloseCircleFillAsset;
      break;
    case 'CheckboxEmpty':
      content = CheckboxEmptyAsset;
      break;
    case 'CheckboxFilled':
      content = CheckboxFilledAsset;
      break;
    case 'ChevronUp':
      content = ChevronUpAsset;
      break;
    case 'ChevronUpFilled':
      content = ChevronUpFilledAsset;
      break;
    case 'ChevronDown':
      content = ChevronDownAsset;
      break;
    case 'ChevronDownFilled':
      content = ChevronDownFilledAsset;
      break;
    case 'ChevronLeft':
      content = ChevronLeftAsset;
      break;
    case 'ChevronLeftFilled':
      content = ChevronLeftFilledAsset;
      break;
    case 'ChevronRight':
      content = ChevronRightAsset;
      break;
    case 'ChevronRightFilled':
      content = ChevronRightFilledAsset;
      break;
    case 'SearchSmall':
      content = SearchSmallAsset;
      break;
    case 'Search':
      content = SearchAsset;
      break;
    case 'LocationMarker':
      content = LocationMarkerAsset;
      break;
    case 'LocationMarkerSmall':
      content = LocationMarkerSmallAsset;
      break;
    case 'Calendar':
      content = CalendarAsset;
      break;
    case 'VS':
      content = VSAsset;
      break;
    case 'CalendarLarge':
      content = CalendarLargeAsset;
      break;
    case 'Profile':
      content = ProfileAsset;
      break;
    case 'Delivery':
      content = DeliveryAsset;
      break;
    case 'CheckmarkInCircle':
      content = CheckmarkInCircleAsset;
      break;
    case 'TicketBig':
      content = TicketBigAsset;
      break;
    case 'Football':
      content = FootballAsset;
      break;
    case 'Ticket':
      content = TicketAsset;
      break;
    case 'TicketWithLines':
      content = TicketWithLinesAsset;
      break;
    case 'Clock':
      content = ClockAsset;
      break;
    case 'ClockLarge':
      content = ClockLargeAsset;
      break;
    case 'Shirt':
      content = ShirtAsset;
      break;
    case 'Arena':
      content = ArenaAsset;
      break;
    case 'Checkmark':
      content = CheckmarkAsset;
      break;
    case 'Settings':
      content = SettingsAsset;
      break;
    case 'Thumbtack':
      content = ThumbtackAsset;
      break;
    case 'Minus':
      content = MinusAsset;
      break;
    case 'Plus':
      content = PlusAsset;
      break;
    case 'Mail':
      content = MailAsset;
      break;
    case 'Printer':
      content = PrinterAsset;
      break;
    case 'TicketCut':
      content = TicketCutAsset;
      break;
    case 'MobileScan':
      content = MobileScanAsset;
      break;
    case 'Info':
      content = InfoAsset;
      break;
    case 'Warning':
      content = WarningAsset;
      break;
    case 'WarningOutline':
      content = WarningOutlineAsset;
      break;
    case 'MasterCard':
      content = MasterCardAsset;
      break;
    case 'Visa':
      content = VisaAsset;
      break;
    case 'AmericanExpress':
      content = AmericanExpressAsset;
      break;
    case 'Discover':
      content = DiscoverAsset;
      break;
    case 'Paypal':
      content = PaypalAsset;
      break;
    case 'Star':
      content = StarAsset;
      break;
    case 'Phone':
      content = PhoneAsset;
      break;
    default:
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      <img
        className={styles.content}
        alt={contentAlt}
        src={content} />
    </div>
  );
};

Icon.defaultProps = defaultProps;

export default Icon;
