import React from 'react';
import cx from 'classnames';

import styles from './StepperBlock.module.scss';

import StepperList, { StepperListProps } from '../../organisms/StepperList';
import Divider, { DividerProps } from '../../atoms/Divider';

export const defaultProps = {
  stepperList: {
    stepperItems: [
    ],
  } as StepperListProps,
  divider: {
    type: 'Horizontal',
    style: 'Thin',
    colour: 'DigitalGrey15',
  } as DividerProps,
};

export type StepperBlockProps = {
  stepperList?: StepperListProps;
  divider?: DividerProps;
  className?: string;
  activeStep?: number;
};

const StepperBlock: React.FC<StepperBlockProps> = ({
  stepperList,
  divider,
  className,
  activeStep,
}) => {
  return (
    <div className={cx(styles.stepperBlock, className)}>
      <StepperList
        className={styles.stepperList}
        {...stepperList}
        activeStep={activeStep} />
      <Divider
        className={styles.divider}
        {...divider} />
    </div>
  );
};

StepperBlock.defaultProps = defaultProps;

export default StepperBlock;
