import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { Account } from '../../../modules/auth/types';
import { ShippingInfoBlockProps, defaultProps } from './ShippingInfoBlock';
import { CheckBoxItemStateType } from '../../atoms/CheckBoxItem/CheckBoxItem';
import { CANADA_PROVINCE_CODES, COUNTRIES, US_STATE_CODES } from '../../../lib/constants';
import { DropdownItem } from '../../atoms/DropdownSelect/utils';
import { getCountryCodeFromState, OrderAddressState, requiredFields, validateOrderAddressField } from './util';
import { OrderAddress } from '../../../modules/partnership';
import { getTwoDigitsCountryCode, removeWhiteSpacesFromString, getFiveDigitsZipCode, handleCountryCodeUS, isCanadaSelected } from '../../../lib/util';
import { AriaLiveRegions, AriaRoles } from '../../../lib/types';

export type ShippingInfoBlockPresenterProps = ShippingInfoBlockProps & {
  // account?: Account;
};

const withPresenter = (
  View: React.FC<ShippingInfoBlockProps>,
): React.FC<ShippingInfoBlockPresenterProps> => {
  const Presenter: React.FC<ShippingInfoBlockPresenterProps> = (props) => {
    const { formSubmitted, billingInfo } = props;
    const { t } = useTranslation();
    const [billingState, setBillingState] = useState<boolean>(false);

    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const [shippingDetails, setShippingDetails] = useState<OrderAddress>({
      first_name: '',
      last_name: '',
      address_line_1: '',
      address_line_2: '',
      city: '',
      postal_code: '',
      phone_number: '',
      region: '',
      country: '',
    });
    const [addressFieldsStates, setAddressFieldsStates] = useState<OrderAddressState>({} as OrderAddressState);
    const [totalErrors, setTotalErrors] = useState(0);
    let billingAddress: OrderAddress | undefined;
    // const validatedCountryCode = getTwoDigitsCountryCode(account?.address.country_code || '');
    // if (account) {
    //   billingAddress = {
    //     first_name: account?.first_name,
    //     last_name: account?.last_name,
    //     address_line_1: account?.address.address_line1,
    //     address_line_2: account?.address.address_line2,
    //     city: account?.address.city,
    //     postal_code: account ? getFiveDigitsZipCode(account.address.country_code, account.address.postal_code) : '',
    //     phone_number: props?.phoneNumber?.textInput?.textValue || removeWhiteSpacesFromString(account?.phone),
    //     region: account?.address.state_code,
    //     country: validatedCountryCode,
    //   };
    // }

    const validateAllAddressFields = () => {
      const addressErrors = {} as OrderAddressState;
      requiredFields.forEach(field => {
        if (!validateOrderAddressField(field, shippingDetails[field] as string, shippingDetails)) {
          addressErrors[field] = 'Error';
        }
      });
      const errors = Object.entries(addressErrors).length;
      setAddressFieldsStates(addressErrors);
      setTotalErrors(errors);
      return errors;
    };

    useEffect(() => {
      if (isFormSubmitted) {
        validateAllAddressFields();
      }
    }, [shippingDetails]);

    const handleChange = <T extends unknown>(key: string, value: T) => {
      setShippingDetails({
        ...shippingDetails,
        [key]: value,
      });
    };

    const handleInputChanged = (key: keyof OrderAddress) => (e) => {
      handleChange<string>(key, e.target.value);
      if (e.target.value) {
        const addressErrors = {} as OrderAddressState;
        addressErrors[key] = 'Default';
        setAddressFieldsStates({ ...addressFieldsStates, ...addressErrors });
      }
      setBillingState(false);
    };


    const canadianProvinces: DropdownItem[] = [{
      label: t('billingInfo.enterState'),
      value: '',
    }].concat(CANADA_PROVINCE_CODES
      .map(country => {
        return {
          value: country.code,
          label: country.name,
          ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
            option: country.name,
          }),
        };
      }),
    );
    const usStates: DropdownItem[] = [{
      value: '',
      label: t('billingInfo.enterState'),
    }].concat(US_STATE_CODES
      .map(country => {
        return {
          value: country.code,
          label: country.name,
          ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
            option: country.name,
          }),
        };
      }),
    );
    const allStates: DropdownItem[] = usStates.concat(
      CANADA_PROVINCE_CODES
        .map(country => {
          return {
            value: country.code,
            label: country.name,
            ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
              option: country.name,
            }),
          };
        }),
    );
    const getCorrectProvinces = (code: string | undefined) => {
      switch ((code || '').toLowerCase()) {
        case 'us':
          return usStates;
        case 'ca':
          return canadianProvinces;
        default:
          return allStates;
      }
    };
    const [stateOptions, setStateOptions] = useState<DropdownItem[]>(getCorrectProvinces(''));
    const shippingInfoProps: ShippingInfoBlockProps = {
      ...defaultProps,
      checkBoxItem: {
        ...defaultProps.checkBoxItem,
        text: {
          ...defaultProps.checkBoxItem.text,
          value: t('billingInfo.checkbox'),
        },
        icon: {
          ...defaultProps.checkBoxItem.icon,
        },
        state: billingState ? 'Selected' : 'Unselected',
        onCheckBoxItemClicked: (state?: CheckBoxItemStateType) => {
          // setStateState({ value: account?.address.state_code || '' });
          // setCountryState({ value: validatedCountryCode });
          // setStateOptions(getCorrectProvinces(getTwoDigitsCountryCode(account?.address.country_code || '')));
          setShippingDetails({
            ...shippingDetails,
            ...billingInfo,
          });
          setBillingState(state === 'Selected');
        },
      },
      blockTitle: {
        ...defaultProps.blockTitle,
        value: props.blockTitle?.value || t('billingInfo.shippingTitle'),
      },
      firstName: {
        ...defaultProps.firstName,
        state: addressFieldsStates.first_name,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.firstName.label,
          value: t('billingInfo.firstName'),
        },
        textInput: {
          ...defaultProps.firstName.textInput,
          textPlaceholder: t('billingInfo.enterFirstName'),
          state: 'Empty',
          onTextChanged: handleInputChanged('first_name'),
          textValue: shippingDetails.first_name,
        },
        error: {
          ...defaultProps.firstName.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.firstName'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      lastName: {
        ...defaultProps.lastName,
        state: addressFieldsStates.last_name,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.lastName.label,
          value: t('billingInfo.lastName'),
        },
        textInput: {
          ...defaultProps.lastName.textInput,
          textPlaceholder: t('billingInfo.enterLastName'),
          state: 'Empty',
          onTextChanged: handleInputChanged('last_name'),
          textValue: shippingDetails.last_name,
        },
        error: {
          ...defaultProps.lastName.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.lastName'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      address: {
        ...defaultProps.address,
        state: addressFieldsStates.address_line_1,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.address.label,
          value: t('billingInfo.address'),
        },
        textInput: {
          ...defaultProps.address.textInput,
          textPlaceholder: t('billingInfo.enterAddress'),
          state: 'Empty',
          onTextChanged: handleInputChanged('address_line_1'),
          textValue: shippingDetails.address_line_1,
        },
        error: {
          ...defaultProps.address.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.address'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      sccondaryAddress: {
        ...defaultProps.sccondaryAddress,
        label: {
          ...defaultProps.sccondaryAddress.label,
          value: t('billingInfo.secondaryAddress'),
        },
        textInput: {
          ...defaultProps.sccondaryAddress.textInput,
          state: 'Empty',
          onTextChanged: handleInputChanged('address_line_2'),
          textValue: shippingDetails.address_line_2 || undefined,
        },
      },
      city: {
        ...defaultProps.city,
        state: addressFieldsStates.city,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.city.label,
          value: t('billingInfo.city'),
        },
        textInput: {
          ...defaultProps.city.textInput,
          textPlaceholder: t('billingInfo.enterCity'),
          state: 'Empty',
          onTextChanged: handleInputChanged('city'),
          textValue: shippingDetails.city,
          maxLength: 35,
          removeChar: /[^a-zA-Z ]/g,
        },
        error: {
          ...defaultProps.city.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.city'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      state: {
        ...defaultProps.state,
        state: addressFieldsStates.region,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.state.label,
          value: t('billingInfo.state'),
        },
        dropdownSelect: {
          ...defaultProps.state.dropdownSelect,
          onDropdownSelectClicked: (stateCode) => {
            const addressDetails = { ...shippingDetails };
            addressDetails.region = stateCode || '';
            const addressErrors = {} as OrderAddressState;
            addressErrors.region = 'Default';
            setAddressFieldsStates({ ...addressFieldsStates, ...addressErrors });
            setShippingDetails(addressDetails);
            setBillingState(false);
          },
          selection: { value: shippingDetails.region || '' },
          dropdownItems: stateOptions,
          text: {
            ...defaultProps.state.dropdownSelect?.text,
            value: 'billingState ? account?.address.address_line1 : undefined',
          },
        },
        error: {
          ...defaultProps.state.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.state'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      postalCode: {
        ...defaultProps.postalCode,
        state: addressFieldsStates.postal_code,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.postalCode.label,
          value: t('billingInfo.postalCode'),
        },
        textInput: {
          ...defaultProps.postalCode.textInput,
          textPlaceholder: t('billingInfo.enterPostalCode'),
          state: 'Empty',
          onTextChanged: handleInputChanged('postal_code'),
          textValue: shippingDetails.postal_code,
          maxLength: handleCountryCodeUS(shippingDetails.country) ? 5 
            : isCanadaSelected(shippingDetails.country) ? 6 : 999, // max value for postal code other than US or CA
          removeChar: /[^a-zA-Z0-9]/g,
        },
        error: {
          ...defaultProps.postalCode.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.postalCode'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      country: {
        ...defaultProps.country,
        state: addressFieldsStates.country,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.country.label,
          value: t('billingInfo.country'),
        },
        dropdownSelect: {
          ...defaultProps.country.dropdownSelect,
          onDropdownSelectClicked: (countryCode) => {
            const addressDetails = { ...shippingDetails };
            addressDetails.country = countryCode || '';
            const addressErrors = {} as OrderAddressState;
            addressErrors.country = 'Default';
            setAddressFieldsStates({ ...addressFieldsStates, ...addressErrors });
            setShippingDetails(addressDetails);
            setBillingState(false);
          },
          selection: { value: shippingDetails.country || '' },
          text: {
            ...defaultProps.country.dropdownSelect?.text,
            value: 'billingState ? getTwoDigitsCountryCode(account?.address.country_code || ) : undefined',
          },
          dropdownItems: [{
            label: t('billingInfo.enterCountry'),
            value: '',
          }].concat(COUNTRIES.map(country => {
            return {
              value: country.code,
              label: country.name,
              ariaLabel: t('shopByWidget.selectedOptionAnnouncement', {
                option: country.name,
              }),
            };
          })),
        },
        error: {
          ...defaultProps.country.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.country'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      phoneNumber: {
        ...defaultProps.phoneNumber,
        state: addressFieldsStates.phone_number,
        ariaLive: AriaLiveRegions.ASSERTIVE,
        label: {
          ...defaultProps.phoneNumber.label,
          value: t('billingInfo.phoneNumber'),
        },
        textInput: {
          ...defaultProps.phoneNumber.textInput,
          textPlaceholder: t('billingInfo.enterPhoneNumber'),
          state: 'Empty',
          onTextChanged: handleInputChanged('phone_number'),
          textValue: shippingDetails.phone_number,
          maxLength: 10,
          removeChar: /[^0-9]/g,
        },
        error: {
          ...defaultProps.lastName.error,
          value: t('billingInfo.fieldErrorMessage', {
            field: t('billingInfo.phoneNumber'),
          }),
          ariaRole: AriaRoles.ALERT,
          ariaAtomic: true,
        },
      },
      button: {
        ...defaultProps.button,
        text: {
          ...defaultProps.button?.text,
          value: t('billingInfo.button'),
        },
      },
      handleSubmit: (e) => {
        e?.preventDefault();
        //setIsFormSubmitted(true);
        const errors = validateAllAddressFields();
        if (errors === 0 && formSubmitted) {
          setShippingDetails({
            first_name: '',
            last_name: '',
            address_line_1: '',
            address_line_2: ' ',
            city: '',
            postal_code: '',
            phone_number: '',
            region: '',
            country: '',
          });
          formSubmitted(shippingDetails);
        }
      },
      showError: (totalErrors > 0),
      errorHighlighMessage: {
        ...defaultProps.errorHighlighMessage,
        message: {
          ...defaultProps.errorHighlighMessage.message,
          value: t('billingInfo.addressErrorMessage'),
        },
      },
      isBillingStep: props.isBillingStep,
    };

    return <View {...props}
      {...shippingInfoProps}
      formSubmitted={formSubmitted}
    />;
  };
  return Presenter;
};

export default withPresenter;
