import React from 'react';
import cx from 'classnames';

import styles from './Text.module.scss';

export type TextTypeType =
  'Heading' |
  'Subheading' |
  'Decoration' |
  'Body' |
  'CardBody' |
  'ContactBody' |
  'BannerSubheading' |
  'BannerHeading';
export type TextSizeType =
  'Small' |
  'Large' |
  'Medium' |
  'ExtraLarge' |
  'ExtraSmall' |
  'ExtraExtraSmall';
export type TextStyleType = 'Light' | 'SemiBold' | 'Regular';
export type TextColourType =
  'BaseLight' |
  'ActionBase' |
  'SubduedDark' |
  'SubduedLight' |
  'Disabled' |
  'BaseDark' |
  'ActionHover' |
  'ActionActive' |
  'Positive' |
  'Negative' |
  'Warning' |
  'Action' |
  'Brand';
export type TextAlignType = 'Left' | 'Right' | 'Center';

export const defaultProps = {
  type: 'Body' as TextTypeType,
  size: 'Large' as TextSizeType,
  style: 'Regular' as TextStyleType,
  colour: 'ActionBase' as TextColourType,
  align: 'Right' as TextAlignType,
  value: '',
};

export type TextProps = {
  type?: TextTypeType;
  size?: TextSizeType;
  style?: TextStyleType;
  colour?: TextColourType;
  align?: TextAlignType;
  value?: string | React.ReactNode;
  className?: string;
  arialabel?: string;
  ariaRole?: string;
  ariaAtomic?: boolean;
  customHeadingClass?: string;
};

const Text: React.FC<TextProps> = ({
  type,
  size,
  style,
  colour,
  align,
  value,
  className,
  arialabel = '',
  ariaRole = '',
  ariaAtomic = false,
  customHeadingClass = '',
}) => {
  const currentStyle = styles[`text${type}${size}${style}${colour}${align}`];

  let valueView;
  switch (type) {
    case 'Heading':
      valueView = (
        <h1 aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={styles.value}>
          {value}
        </h1>
      );
      break;
    case 'Subheading':
      valueView = (
        <h2 aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={cx(styles.value, customHeadingClass)}>
          {value}
        </h2>
      );
      break;
    case 'Decoration':
      valueView = (
        <h4 aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={styles.value}>
          {value}
        </h4>
      );
      break;
    case 'Body':
      valueView = (
        <p aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={cx(styles.value, customHeadingClass)}>
          {value}
        </p>
      );
      break;
    case 'CardBody':
      valueView = (
        <p aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={styles.value}>
          {value}
        </p>
      );
      break;
    case 'ContactBody':
      valueView = (
        <p aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={styles.value}>
          {value}
        </p>
      );
      break;
    case 'BannerSubheading':
      valueView = (
        <h2 aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={styles.value}>
          {value}
        </h2>
      );
      break;
    case 'BannerHeading':
      valueView = (
        <h1 aria-label={arialabel} aria-atomic={ariaAtomic} role={ariaRole} className={styles.value}>
          {value}
        </h1>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {valueView}
    </div>
  );
};

Text.defaultProps = defaultProps;

export default Text;
