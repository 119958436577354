import React from 'react';
import cx from 'classnames';

import styles from './SeatInfo.module.scss';

import TextItem, { TextItemProps } from '../TextItem';

export const defaultProps = {
  section: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  row: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
  quantity: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Large',
      style: 'Regular',
      colour: 'BaseDark',
      align: 'Left',
    },
  } as TextItemProps,
};

export type SeatInfoProps = {
  section?: TextItemProps;
  row?: TextItemProps;
  quantity?: TextItemProps;
  className?: string;
};

const SeatInfo: React.FC<SeatInfoProps> = ({
  section,
  row,
  quantity,
  className,
}) => {
  return (
    <div className={cx(styles.seatInfo, className)}>
      <TextItem
        className={styles.section}
        {...section} />
      <TextItem
        className={styles.row}
        {...row} />
      <TextItem
        className={styles.quantity}
        {...quantity} />
    </div>
  );
};

SeatInfo.defaultProps = defaultProps;

export default SeatInfo;
