import React from 'react';
import cx from 'classnames';

import styles from './EventDateStatus.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Tag, { TagProps } from '../Tag';

export type EventDateStatusTypeType = 'Default' | 'WithTag';

export const defaultProps = {
  type: 'WithTag' as EventDateStatusTypeType,
  date: {
    type: 'CardBody',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  time: {
    type: 'CardBody',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  tag: {
    type: 'Neutral',
    size: 'Small',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'SemiBold',
      colour: 'SubduedDark',
      align: 'Left',
    },
  } as TagProps,
};

export type EventDateStatusProps = {
  type?: EventDateStatusTypeType;
  date?: TextProps;
  time?: TextProps;
  className?: string;
  tag?: TagProps;
};

const EventDateStatus: React.FC<EventDateStatusProps> = ({
  type,
  date,
  time,
  className,
  tag,
}) => {

  const currentStyle = styles[`eventDateStatus${type}`];

  const dateAndTimeView = (
    <div className={styles.dateAndTime}>
      <Text
        className={styles.date}
        {...date} />
      <Text
        className={styles.time}
        {...time} />
    </div>
  );
  
  let tagView;
  
  switch (type) {
    case 'Default':
      break;
    case 'WithTag':
      tagView = (
        <Tag
          className={styles.tag}
          {...tag} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {tagView}
      {dateAndTimeView}
    </div>
  );
};

EventDateStatus.defaultProps = defaultProps;

export default EventDateStatus;
