import React from 'react';
import cx from 'classnames';

import styles from './OrderInfo.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import CostBreakdown, { CostBreakdownProps } from '../../molecules/CostBreakdown';
import SeatInfo, { SeatInfoProps } from '../../molecules/SeatInfo';
import TextItem, { TextItemProps } from '../../molecules/TextItem';
import Logo, { LogoProps } from '../../atoms/Logo';

export const defaultProps = {
  costTitle: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  title: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  amount: {
    type: 'Body',
    size: 'ExtraLarge',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  miles: {
    type: 'Body',
    size: 'Small',
    style: 'Regular',
    colour: 'SubduedLight',
    align: 'Left',
  } as TextProps,
  costBreakdown: {
    ordarTotal: {
      style: 'Dark',
      item: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      },
      price: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedDark',
        align: 'Left',
      },
    },
    tickets: {
      style: 'Light',
      item: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      price: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
    },
    serviceFee: {
      style: 'Light',
      item: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      price: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
    },
    delivery: {
      style: 'Light',
      item: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      price: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
    },
    promoInput: {
      state: 'Empty',
      style: 'Default',
      size: 'Regular',
    },
    promoError: {
      style: 'Regular',
      size: 'Small',
      align: 'Left',
      colour: 'Negative',
      type: 'Body',
    },
    applyButton: {
      type: 'Text',
      style: 'Text',
      size: 'ExtraSmall',
      text: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'ActionBase',
        align: 'Left',
      },
    },
    button: {
      type: 'IconText',
      style: 'Text',
      size: 'ExtraSmall',
      icon: {
        asset: 'Plus',
        style: 'ActionBase',
      },
      text: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'ActionBase',
        align: 'Left',
      },
    },
  } as CostBreakdownProps,
  dateAndTime: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  venue: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  seatInfo: {
    section: {
      style: 'SubduedLabel',
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseDark',
        align: 'Left',
      },
    },
    row: {
      style: 'SubduedLabel',
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseDark',
        align: 'Left',
      },
    },
    quantity: {
      style: 'SubduedLabel',
      label: {
        type: 'Body',
        size: 'Small',
        style: 'Regular',
        colour: 'SubduedLight',
        align: 'Left',
      },
      description: {
        type: 'Body',
        size: 'Medium',
        style: 'Regular',
        colour: 'BaseDark',
        align: 'Left',
      },
    },
  } as SeatInfoProps,
  sellerNotes: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  } as TextItemProps,
  deliveryMethod: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
    description: {
      type: 'Body',
      size: 'Medium',
      style: 'Regular',
      colour: 'SubduedDark',
      align: 'Left',
    },
  } as TextItemProps,
  faceValue: {
    style: 'SubduedLabel',
    label: {
      type: 'Body',
      size: 'Small',
      style: 'Regular',
      colour: 'SubduedLight',
      align: 'Left',
    },
  } as TextItemProps,
};

export type OrderInfoProps = {
  costTitle?: TextProps;
  title?: TextProps;
  amount?: TextProps;
  costBreakdown?: CostBreakdownProps;
  dateAndTime?: TextProps;
  venue?: TextProps;
  seatInfo?: SeatInfoProps;
  sellerNotes?: TextItemProps;
  deliveryMethod?: TextItemProps;
  faceValue?: TextItemProps;
  className?: string;
  showOrderDetails?: boolean;
};

const OrderInfo: React.FC<OrderInfoProps> = ({
  costTitle,
  title,
  amount,
  costBreakdown,
  dateAndTime,
  venue,
  seatInfo,
  sellerNotes,
  deliveryMethod,
  faceValue,
  className,
  showOrderDetails,
}) => {
  let orderDetailsView;

  if (showOrderDetails) {
    orderDetailsView = (
      <React.Fragment>
        <div className={styles.titleContent}>
          <div className={styles.title}>
            <Text
              className={styles.title}
              {...costTitle} />
          </div>
          <div className={styles.price}>
            <Text
              className={styles.amount}
              {...amount} />
          </div>
        </div>
        <CostBreakdown
          className={styles.costBreakdown}
          {...costBreakdown} />
      </React.Fragment>
    );
  }
  return (
    <div className={cx(styles.orderInfo, className)}>
      {orderDetailsView}
      {faceValue?.description ? <TextItem
        className={styles.faceValue}
        {...faceValue} /> : ''}
      <div className={styles.topContent}>
        <Text
          className={styles.title}
          {...title} />
        <div className={styles.eventInfo}>
          <Text
            className={styles.dateAndTime}
            {...dateAndTime} />
          <Text
            className={styles.venue}
            {...venue} />
        </div>
      </div>
      <SeatInfo
        className={styles.seatInfo}
        {...seatInfo} />
      <TextItem
        className={styles.sellerNotes}
        {...sellerNotes} />
      <TextItem
        className={styles.deliveryMethod}
        {...deliveryMethod} />
    </div>
  );
};

OrderInfo.defaultProps = defaultProps;

export default OrderInfo;
