import React from 'react';
import cx from 'classnames';

import styles from './Logo.module.scss';

import LogoC1EntertainmentAsset from '../../../resources/icons/LogoC1Entertainment.svg';
import LogoC1DefaultAsset from '../../../resources/icons/LogoC1Default.svg';
import VividLogoAsset from '../../../resources/icons/VividLogo.svg';
import VividLogoWithColourAsset from '../../../resources/icons/VividLogoWithColour.svg';
import LogoC1DefaultAssetWithText from '../../../resources/icons/LogoC1DefaultWithText.svg';
import { Link } from 'react-router-dom';
import LogoTicketsOnSaleAsset from '../../../resources/icons/LogoTicketsOnSale.svg';
import ShopperApprovedSealAsset from '../../../resources/icons/ShopperApprovedSeal.svg';

export type LogoAssetType = 'LogoC1Entertainment' | 'LogoC1Default' | 'VividLogo' | 'VividLogoWithColour' | 'LogoC1DefaultWithText' | 'LogoTicketsOnSale' | 'ShopperApprovedSeal';

export const defaultProps = {
  asset: 'VividLogoWithColour' as LogoAssetType,
  logoAlt: '',
};

export type LogoProps = {
  asset?: LogoAssetType;
  className?: string;
  logoAlt?: string;
  linkPath?: string;
  newTab?: boolean;
};

const Logo: React.FC<LogoProps> = ({
  asset,
  className,
  logoAlt,
  linkPath,
  newTab,
}) => {

  const currentStyle = styles[`logo${asset}`];

  let logo = '';
  switch (asset) {
    case 'LogoC1Entertainment':
      logo = LogoC1EntertainmentAsset;
      break;
    case 'LogoC1Default':
      logo = LogoC1DefaultAsset;
      break;
    case 'VividLogo':
      logo = VividLogoAsset;
      break;
    case 'VividLogoWithColour':
      logo = VividLogoWithColourAsset;
      break;
    case 'LogoC1DefaultWithText':
      logo = LogoC1DefaultAssetWithText;
      break;
    case 'LogoTicketsOnSale':
      logo = LogoTicketsOnSaleAsset;
      break;
    case 'ShopperApprovedSeal':
      logo = ShopperApprovedSealAsset;
      break;
    default:
      break;
  }

  if (linkPath) {
    return (
      <a
        target={newTab ? '_blank' : '_self'}
        rel={'noreferrer'}
        href={linkPath}>
        <img
          alt={logoAlt}
          src={logo}
          className={cx(currentStyle, className)} />
      </a>
    );
  }

  return (
    <div>
      <img
      alt={logoAlt}
      src={logo}
      className={cx(currentStyle, className)} />
    </div>
  );
};

Logo.defaultProps = defaultProps;

export default Logo;
