import TagManager, { TagManagerArgs } from 'react-gtm-module';
import BingTracking from 'react-tracking-bing';
import { GTM_ID, GTM_DATA_LAYER, BING_TRACKING_ID } from './config';

export const initializeTracking = (): void => {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: GTM_ID,
    dataLayerName: GTM_DATA_LAYER,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  TagManager.initialize(tagManagerArgs);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  BingTracking.initialize(BING_TRACKING_ID);
};

