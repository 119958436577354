declare global {
  interface Window {
    env: any;
  }
}
// eslint-disable-next-line import/prefer-default-export
export const {
  REACT_APP_DOMAIN: DOMAIN = window.env?.REACT_APP_DOMAIN,
  REACT_APP_BFF_URL: BFF_URL = window.env?.REACT_APP_BFF_URL,
  REACT_APP_CLOUDFRONT_URL: CLOUDFRONT_URL = window.env
    ?.REACT_APP_CLOUDFRONT_URL,
  REACT_APP_FRONTEND_URL: FRONTEND_URL = window.env?.REACT_APP_FRONTEND_URL,
  REACT_APP_SESSION_COOKIE_NAME: SESSION_COOKIE_NAME = window.env
    ?.REACT_APP_SESSION_COOKIE_NAME,
  REACT_APP_GTM_ID: GTM_ID = window.env?.REACT_APP_GTM_ID,
  REACT_APP_GTM_DATA_LAYER: GTM_DATA_LAYER = window.env?.REACT_APP_GTM_DATA_LAYER,
  REACT_APP_BING_TRACKING_ID: BING_TRACKING_ID = window.env?.REACT_APP_BING_TRACKING_ID,
  REACT_APP_ROKT_ACCOUNT_ID: ROKT_ACCOUNT_ID = window.env?.REACT_APP_ROKT_ACCOUNT_ID,
  REACT_APP_RYADD_ID: RYADD_ID = window.env?.REACT_APP_RYADD_ID,
  REACT_APP_SIGN_OUT_URL: SIGN_OUT_URL = window.env?.REACT_APP_SIGN_OUT_URL ||
  'https://www.capitalone.com',
  REACT_APP_UNAUTHORIZED_URL: UNAUTHORIZED_URL = window.env?.REACT_APP_UNAUTHORIZED_URL ||
  'https://links.capitalone.com/partner-linkout?usecase=entertainment&path=%2F',
  REACT_APP_PRIVACY_URL: PRIVACY_URL = window.env?.REACT_APP_PRIVACY_URL ||
  'https://www.capitalone.com/privacy/online-privacy-policy',
  SENTRY_DSN: SENTRY_DSN_VALUE = window.env?.SENTRY_DSN ||
  'https://692cb96b7186476f86653a9406d1d195@o130096.ingest.sentry.io/6262364',
  REACT_APP_LIVE_CHAT_URL: LIVE_CHAT_URL = window.env?.REACT_APP_LIVE_CHAT_URL ||
  'https://home-c8.incontact.com/incontact/chatclient/chatclient.aspx?poc=2b86ff04-756e-4599-9a18-f9b4bbfd443d&bu=4593638',
  REACT_APP_PAYPAL_CLIENT_ID: PAYPAL_CLIENT_ID = window.env?.REACT_APP_PAYPAL_CLIENT_ID || 'sb',
} = process.env;

export const IS_DEV = process.env?.REACT_APP_IS_DEV === 'true' || window.env?.REACT_APP_IS_DEV === 'true';