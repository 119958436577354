import React from 'react';
import ErrorBlock, { ErrorBlockProps } from '../../components/blocks/ErrorBlock';
import Footer from '../../components/organisms/Footer';
import TopNav from '../../components/organisms/TopNav/TopNav';


import { ApiError } from './types';
import styles from './ErrorHandler.module.scss';

export type ErrorHandlerProps = {
  error?: Error;
  isAuthError?: boolean;
  errorBlockProps?: ErrorBlockProps;
  notFountErrorBlockProps?: ErrorBlockProps;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ErrorHandler: React.FC<ErrorHandlerProps> = ({
  error,
  isAuthError = false,
  errorBlockProps,
  //termsAndPrivacyModalProps,
  notFountErrorBlockProps,
}) => {

  if (ApiError.isApiError(error)) {
    if (isAuthError && (error.code === 406 || error.code === 403 || error.code === 404 || error.code === 400)) {
      let blockProps = errorBlockProps;
      if (error.code === 404 || error.code === 400) {
        blockProps = notFountErrorBlockProps;
      }
      return (
        <>
          <div className={styles.navContainer}>
            <TopNav type="SignedOut" className={styles.topNav} />
          </div>
          <ErrorBlock {...blockProps} />
          <Footer />
       
        </>
      );
    }
  }

  return (
    <div>{error?.message}</div>
  );
};

export default ErrorHandler;