import axios from 'axios';
import { RegionsQueryParams, UserOrdersResponse } from '.';
import { BFF_URL } from '../../lib/config';
// import { ApiError } from '../error/types';

import {
  EventPageResponse,
  Event,
  PerformerPageResponse,
  PerformersQueryParams,
  SearchSuggestionsResponse,
  RegionPageResponse,
  Venue,
  SearchPageResponse,
  SearchQueryParams,
  SearchSuggestionsQueryParams,
  ListingDetailsQueryParams,
  ListingsQueryParams,
  ListingsResponse,
  Order,
  CategoriesPageResponse,
  PageQueryParams,
  ListingDetails,
  PaymentTokenResponse,
  EventsQueryParams,
  PerformerDetail,
  UserOrderTos,
  MarketingOptinPayload,
  MarketingOptinResponse,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stripEmptyParams = (params: any): any => {
  if (!params) {
    return undefined;
  }
  const results = {};
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value) {
      results[key] = value;
    }
  });
  return results;
};

const handleError = (error: unknown) => {
  if (axios.isAxiosError(error) && error.response) {
    // return new ApiError({
    //   code: error.response.status,
    //   type: error.response.statusText,
    //   message: error.message,
    // });
  }
  return error;
};

export const getPerformers = async (
  params?: PerformersQueryParams,
): Promise<PerformerPageResponse> => {
  try {
    const { data } = await axios.get<PerformerPageResponse>(
      `${BFF_URL}/performers`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPerformer = async (performerId: string): Promise<PerformerDetail> => {
  try {
    const { data } = await axios.get<PerformerDetail>(
      `${BFF_URL}/performers/${performerId}`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPerformerOpponents = async (
  performerId: string,
): Promise<PerformerPageResponse> => {
  try {
    const { data } = await axios.get<PerformerPageResponse>(
      `${BFF_URL}/performers/${performerId}/opponents`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getEvents = async (params?:EventsQueryParams): Promise<EventPageResponse> => {
  try {
    const { data } = await axios.get<EventPageResponse>(
      `${BFF_URL}/events`,
      {  
        params: stripEmptyParams(params),              
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getEvent = async (eventId: string): Promise<Event> => {
  try {
    const { data } = await axios.get<Event>(
      `${BFF_URL}/events/${eventId}`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRegions = async (params?: RegionsQueryParams): Promise<RegionPageResponse> => {
  try {
    const { data } = await axios.get<RegionPageResponse>(
      `${BFF_URL}/regions`,
      {
        params: stripEmptyParams(params), 
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getRegionsByIp = async (): Promise<RegionPageResponse> => {
  try {
    const { data } = await axios.get<RegionPageResponse>(
      `${BFF_URL}/regions/by_ip`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getVenue = async (venueId: string): Promise<Venue> => {
  try {
    const { data } = await axios.get<Venue>(
      `${BFF_URL}/venues/${venueId}`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSearch = async (params: SearchQueryParams): Promise<SearchPageResponse> => {
  try {
    const { data } = await axios.get<SearchPageResponse>(
      `${BFF_URL}/search`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getSearchSuggestions = async (
  params: SearchSuggestionsQueryParams,
): Promise<SearchSuggestionsResponse> => {
  try {
    const { data } = await axios.get<SearchSuggestionsResponse>(
      `${BFF_URL}/search-suggestions`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getListings = async (params: ListingsQueryParams): Promise<ListingsResponse> => {
  try {
    const { data } = await axios.get<ListingsResponse>(
      `${BFF_URL}/listings`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getListingDetails = async (
  eventId: string, listingId: string, params: ListingDetailsQueryParams,
): Promise<ListingDetails> => {
  try {
    const { data } = await axios.get<ListingDetails>(
      `${BFF_URL}/events/${eventId}/listings/${listingId}`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getPaymentToken = async (): Promise<PaymentTokenResponse> => {
  try {
    const { data } = await axios.get<PaymentTokenResponse>(
      `${BFF_URL}/payment/client-token`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const createOrder = async (order: Order): Promise<Order> => {
  try {
    const { data } = await axios.post<Order>(
      `${BFF_URL}/orders`,
      order,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getMyOrder = async (orderId: string): Promise<UserOrderTos> => {
  try {
    const { data } = await axios.get<UserOrderTos>(
      `${BFF_URL}/orders/${orderId}`,
      {
        withCredentials: true,
      },
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const marketingOptin = async (paylaod: MarketingOptinPayload): Promise<MarketingOptinResponse> => {
  try {
    const { data } = await axios.post<MarketingOptinResponse>(
      `${BFF_URL}/marketing/optin`,
      paylaod,
      {
        withCredentials: true,
      },
    );
    //eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data;
  } catch (error) {
    throw handleError(error);
  }
};


export const getFilesByName = async (params: { orderId?: string, fileName?: string }): Promise<any> => {
  try {
    const { data } = await axios.get<UserOrdersResponse>(
      `${BFF_URL}/orders/${params.orderId}/ticket/${params.fileName}`,
      {
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};

export const getCategories = async (
  params: PageQueryParams,
): Promise<CategoriesPageResponse> => {
  try {
    const { data } = await axios.get<CategoriesPageResponse>(
      `${BFF_URL}/categories`,
      {
        params: stripEmptyParams(params),
        withCredentials: true,
      },
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};