import React from 'react';
import cx from 'classnames';

import styles from './IconTextDescription.module.scss';

import Icon, { IconProps } from '../../atoms/Icon';
import Text, { TextProps } from '../../atoms/Text';
import Logo, { LogoProps } from '../../atoms/Logo';

export type IconTextDescriptionTypeType = 'Default' | 'WithLogo';

export const defaultProps = {
  type: 'WithLogo' as IconTextDescriptionTypeType,
  icon: {
    asset: 'TicketWithLines',
    style: 'DigitalGrey80',
  } as IconProps,
  description: {
    type: 'Body',
    size: 'Medium',
    style: 'Regular',
    colour: 'SubduedDark',
    align: 'Left',
  } as TextProps,
  logo: {
    asset: 'VividLogoWithColour',
  } as LogoProps,
};

export type IconTextDescriptionProps = {
  type?: IconTextDescriptionTypeType;
  icon?: IconProps;
  description?: TextProps;
  className?: string;
  logo?: LogoProps;
};

const IconTextDescription: React.FC<IconTextDescriptionProps> = ({
  type,
  icon,
  description,
  className,
  logo,
}) => {

  const currentStyle = styles[`iconTextDescription${type}`];

  let iconView;
  if (icon) {
    iconView = (
      <Icon
        className={styles.icon}
        {...icon} />
    );
  }
  const descriptionView = (
    <Text
      className={styles.description}
      {...description} />
  );
  
  let logoContainerView;
  
  switch (type) {
    case 'Default':
      break;
    case 'WithLogo':
      logoContainerView = (
        <div className={styles.logoContainer}>
          <Logo
            className={styles.logo}
            {...logo} />
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {iconView}
      {descriptionView}
      {logoContainerView}
    </div>
  );
};

IconTextDescription.defaultProps = defaultProps;

export default IconTextDescription;
