import React from 'react';
import cx from 'classnames';

import styles from './CheckBoxItem.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export type CheckBoxItemStateType = 'Unselected' | 'Selected';
export type CheckBoxItemCheckBoxItemTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  state: 'Selected' as CheckBoxItemStateType,
  checkBoxItemType: 'button' as CheckBoxItemCheckBoxItemTypeType,
  icon: {
    asset: 'CheckboxFilled',
    style: 'DigitalGrey100',
  } as IconProps,
  text: {
    type: 'Body',
    size: 'Large',
    style: 'Regular',
    colour: 'BaseDark',
    align: 'Left',
  } as TextProps,
};

export type CheckBoxItemProps = {
  state?: CheckBoxItemStateType;
  checkBoxItemType?: CheckBoxItemCheckBoxItemTypeType;
  onCheckBoxItemClicked?: (event?: CheckBoxItemStateType) => void;
  icon?: IconProps;
  text?: TextProps;
  className?: string;
};

const CheckBoxItem: React.FC<CheckBoxItemProps> = ({
  state,
  checkBoxItemType,
  onCheckBoxItemClicked,
  icon,
  text,
  className,
}) => {

  const handleClick = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    const newState = state === 'Selected' ? 'Unselected' : 'Selected';
    state = (newState);

    if (onCheckBoxItemClicked) {
      onCheckBoxItemClicked(newState);
    }
  };
  const currentStyle = styles[`checkBoxItem${state}`];

  switch (state) {
    case 'Unselected':
      icon = { ...icon, asset: 'CheckboxEmpty' };
      break;
    case 'Selected':
      icon = { ...icon, asset: 'CheckboxFilled' };
      break;
  }

  const iconView = (
    <Icon
      {...icon}
      className={cx(styles.icon, icon?.className)} />
  );
  const textView = (
    <Text
      className={styles.text}
      {...text} />
  );

  return (
    <button
      type={checkBoxItemType}
      onClick={handleClick}
      className={cx(currentStyle, className)}>
      {iconView}
      {textView}
    </button>
  );
};

CheckBoxItem.defaultProps = defaultProps;

export default CheckBoxItem;
